import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ComponentContainer from '../../components/component-container/ComponentContainer'
import Spinner from '../../components/spinner/spinner'
import endpointConfig from '../../config/endpointConfig'
import notifyHB from '../../config/notifyHB'
import "./MyProcesses.css"

interface MyProcessesProps {
    refreshToken: Function
}

const MyProcesses: React.FC<MyProcessesProps> = ({ refreshToken }) => {

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    refreshToken()

    let tableData = [{
        "lastUpdate": "",
        "processId": "",
        "processType": "",
        "status": ""
    }]

    const [processDescription, setProcessDescription] = useState(tableData)
    const [waitingTable, setWaitingTable] = useState(<div className='centerSpinner'>
    </div>)
    const [displayData, setDisplayData] = useState(false)

    useEffect(() => {

        const getData = async () => {

            setWaitingTable(
                <div className='centerSpinner'>
                    <Spinner />
                </div>
            )

            // user-info cookies
            // const getToken = userInfoC.userInfo
            const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');
            const authToken: string = getToken['id_token'];

            axios.post(endpointConfig.process,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                })
                .then(resp => {

                    if (resp.data.processDescription.length > 0) {
                        const newData = [] as any
                        for (var processEntry of resp.data.processDescription) {
                            const rowData = {
                                "lastUpdate": processEntry.lastUpdate,
                                "processId": processEntry.processId,
                                "processType": processEntry.processType,
                                "status": processEntry.status
                            }
                            newData.push(rowData)
                        }
        
                        setProcessDescription(newData)
                        setDisplayData(true)
                    } else {
                        setWaitingTable(
                            <div className='centerSpinner'>
                                No hay procesos
                            </div>
                        )
                    }

                    
                })
                .catch(e => {
                    setWaitingTable(
                        <div className='centerSpinner'>
                            Hubo un error al cargar los procesos
                        </div>
                    )

                    // ags: 'my-processes, MyProcesses'
                    notifyHB("my-processes", "MyProcesses", e)

                });

        }

        getData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <ComponentContainer>
            <h1>Mis Procesos </h1>
            {
                displayData
                    ?
                    <table id="myTable" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th className="thBold">LastUpdate</th>
                                <th className="thBold">ProcessId</th>
                                <th className="thBold">ProcessType</th>
                                <th className="thBold">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {processDescription.map((processInfo, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{processInfo.lastUpdate}</td>
                                        <td>{processInfo.processId}</td>
                                        <td>{processInfo.processType}</td>
                                        <td>{processInfo.status}</td>
                                    </tr>
                                )


                            })}
                        </tbody>
                    </table>
                    :
                    waitingTable
            }
        </ComponentContainer>
    )
}

export default MyProcesses
