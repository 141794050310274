import React, { useState, useRef, useEffect } from 'react'
import { Button, Checkbox, Typography, Box, Alert} from '@mui/material'
import { Document, Page, pdfjs } from 'react-pdf'

// Set the worker source for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

interface ScrollableAgreementProps {
  nextStep: () => void
  agreementAccepted: boolean
  setAgreementAccepted: (value: boolean) => void
}

const ScrollableAgreement: React.FC<ScrollableAgreementProps> = ({
  nextStep,
  agreementAccepted,
  setAgreementAccepted,
}) => {
  const [numPages, setNumPages] = useState<number>(0)
  const [isBottom, setIsBottom] = useState(false)
  const [pageWidth, setPageWidth] = useState(0)
  const pdfRef = useRef<HTMLDivElement>(null)
  const scale = 1.3 // Adjust this value to change the zoom level (e.g., 1.2 for 120% zoom)

  const checkScroll = () => {
    const element = pdfRef.current
    if (element) {
      const { scrollTop, scrollHeight, clientHeight } = element
      if (scrollTop + clientHeight >= scrollHeight - 10) { // Allow a small margin of error
        setIsBottom(true)
      }
    }
  }

  useEffect(() => {
    const element = pdfRef.current
    if (element) {
      element.addEventListener('scroll', checkScroll)
      setPageWidth(element.clientWidth - 40) // Subtract padding
      return () => element.removeEventListener('scroll', checkScroll)
    }
  }, [])

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Contrato de solicitante/suscriptor
      </Typography>
      <Alert severity="info" sx={{ mb: 2 }}>
        Debe leer todo el documento para poder continuar
      </Alert>
      <Box
        ref={pdfRef}
        sx={{
          height: '500px',
          overflowY: 'scroll',
          border: '1px solid #ccc',
          padding: 2,
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Document
          file="/pdfs/agreement.pdf" // Make sure this path is correct
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page 
              key={`page_${index + 1}`} 
              pageNumber={index + 1} 
              width={pageWidth * scale}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          ))}
        </Document>
      </Box>
      <Checkbox
        checked={agreementAccepted}
        onChange={(e) => setAgreementAccepted(e.target.checked)}
        disabled={!isBottom}
      />
      <Typography component="span">Acepto</Typography>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={nextStep}
          disabled={!agreementAccepted}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  )
}

export default ScrollableAgreement