import React from 'react'

interface DocumentSignOtpProps {
    setOtpInputValue: Function
    nextStep: Function
    returnStep: Function
}

const DocumentSignatureOTP: React.FC<DocumentSignOtpProps> = ({setOtpInputValue, nextStep, returnStep}) => {
    return (
        <div>
            <div className="dialog-header">
                <div className="dialog-title">
                    <h3>Verificación OTP</h3>
                </div>
            </div>
            <div className="dialog-body">

                <div>
                    <p>Inserte el código OTP para verificar su cuenta</p>
                </div>

                <br />

                <div>
                    <input type="text"
                        onChange={(e) => {
                            setOtpInputValue(e.target.value)
                        }}
                        placeholder="otp" />
                </div>

                <br />

                <button className="cyber-button" style={{marginRight: "10px"}} onClick={ () => returnStep()}>Regresar</button>

                <button className="cyber-button" onClick={ () => nextStep()}>Firmar</button>

            </div>
        </div>
    )
}

export default DocumentSignatureOTP
