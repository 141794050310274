import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';


import endpointConfig from '../../config/endpointConfig';
import CyberInputComponent from './components/CyberInputComponent';

interface RegisterProps {
    nextStep: Function
    personName: string
    setPersonName: Function
    personLastName: string
    setPersonLastName: Function
    setNit: Function
    setDocumentNumber: Function
    address: string
    setAddress: Function
    personProfession:string
    setPersonProfession: Function
}

const PersonalInfo: React.FC<RegisterProps> = ({
    nextStep,
    personName,
    setPersonName,
    personLastName,
    setPersonLastName,
    setNit,
    setDocumentNumber,
    address,
    setAddress,
    personProfession,
    setPersonProfession,
}) => {

    const [fieldsWarning, setFieldsWarning] = useState(<p></p>)
    const [personID, setPersonID] = useState("")
    const [personDateOfBirth, setPersonDateOfBirth] = useState("")
    const [tempDateValue, setTempDateValue] = useState("")

    const confirmStep = () => {
        if (personName !== "" && personLastName !== "" && address!== "") {
            nextStep()
        } else {
            setFieldsWarning(<p style={{color: "red"}}>Por favor llenar todos los campos</p>)
        }
    }

    const getPersonInfo = async () => {
        if (personID == "" || personDateOfBirth == "") {
            alert('Error, información invalida');
            return
        }

        const getToken = JSON.parse(localStorage.getItem("user-info") || '{}');

        try {
            const response: any = await axios.get(endpointConfig.personInformation, {
                params: {
                    id: personID,
                    date_of_birth: personDateOfBirth,
                },
                headers: {
                    Authorization: `Bearer ${getToken['id_token']}`
                }
            })

            if (response.data.name) {
                setPersonName(response.data.name)
                setPersonLastName(response.data.last_name)
                setNit(response.data.nit)
                setDocumentNumber(response.data.cui)
            }
        } catch (e) {
            alert("Error, datos no correctos")
        }

    }

    useEffect(() => {
        // Set a timer to update
        const timer = setTimeout(() => {
            setPersonDateOfBirth(tempDateValue);
        }, 6000);

        // Cleanup function to cancel previous timer when tempDateValue changes
        return () => {
            clearTimeout(timer);
        };
    }, [tempDateValue]);

    useEffect(() => {
        if (personID != "") {
            getPersonInfo()
        }
    }, [personDateOfBirth])

    return (
        <div>
            <h4>Información personal</h4>
            <div className="cyber-spacer small"></div>
            <CyberInputComponent inputLabel={"DPI o NIT"} placeholder="" value={personID} setValue={setPersonID} validInput={true} />
            <div>
                <p>Fecha de Nacimiento</p>
                <input
                    type="date"
                    value={tempDateValue}
                    onChange={(e) => setTempDateValue(e.target.value)}
                    placeholder="Fecha de Nacimiento"
                />
                <br/>
                <br/>
                {/* <Link to="#" className="cyber-button full" onClick={() => getPersonInfo()}>Verificar</Link> */}
            </div>
            <br/>
            <br/>
            <CyberInputComponent inputLabel={"Nombres"} placeholder="" value={personName} setValue={setPersonName} validInput={true} disabled />
            <CyberInputComponent inputLabel={"Apellidos"} placeholder="" value={personLastName} setValue={setPersonLastName} validInput={true} disabled />
            <CyberInputComponent inputLabel={"Dirección"} placeholder={""} value={address} setValue={setAddress} validInput={true} />
            {/* <CyberSelectProfession inputLabel={"Profesión"} id={"profession"} value={personProfession} setValue={setPersonProfession} /> */}
            {fieldsWarning}
            <Link to="#" className="cyber-button full" onClick={() => {confirmStep()}}>Continuar</Link>
        </div>
    )
}

export default PersonalInfo
