import React, { useContext, useEffect } from "react";
import queryString from 'query-string';
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import cognitoConfig from "../../config/cognito";
import qs from "qs";
import { useCookies } from 'react-cookie';

// routes
import routeConfig from "../../config/routeConfig";
import { AuthContext } from "../auth-context/auth-context";
import apiConfig from "../../config/apiConfig";


export interface CognitoToken {
    access_token: string;
    refresh_token: string;
    id_token: string;
    token_type: string;
    expires_in: number;
}

const CodeExchange = () => {

    const { search } = useLocation();
    const { code } = queryString.parse(search);
    const history = useHistory();
    const authCtx = useContext(AuthContext);

    // cookies
    const [, setLoginDataC] = useCookies(["loginData"])

    useEffect(() => {
        const obtainToken = async (oauthCode: string) => {
            const resp = await axios.post(`${apiConfig.baseUrl}/oauth2/token`,
                qs.stringify({
                    grant_type: 'authorization_code',
                    client_id: cognitoConfig.cognitoClient,
                    code: oauthCode,
                    redirect_uri: cognitoConfig.redirectUrl,
                    scope: 'openid'
                }), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

            // Save all tokens sent back by cognito
            authCtx.saveToken(resp.data);

            const getToken = JSON.parse(localStorage.getItem("user-info") || '{}');

            // Save user info sent back from cognito endpoint
            let resp2 = await axios.get(`${cognitoConfig.cognitoUrl}/oauth2/userInfo`, {
                headers: {
                    Authorization: `Bearer ${getToken['access_token']}`
                }
            });
            localStorage.setItem('login-data', JSON.stringify(resp2.data));
            setLoginDataC("loginData", resp2.data,
                {
                    domain: process.env.REACT_APP_DOMAIN,
                    path: "/",
                    secure: true,
                    sameSite: "strict",
                }
            )
        }
        if (code) {
            obtainToken(code as string);
        }

        history.push(routeConfig.redirectLogin);
        // The empty array means that this effect will only be executed once
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>wait a sec...</div>
    );
}


/**
 * Clears the current cognito token in localstorage
 */
export const clearCognitoToken = () => {
    localStorage.removeItem("user-info");
    localStorage.removeItem("signatures");
}

export default CodeExchange;