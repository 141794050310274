import { motion } from 'framer-motion';
import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import ComponentContainer from '../../components/component-container/ComponentContainer';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ProductSign from './ProductSign';
import ProductValidate from './ProductValidate';
import ProductConsult from './ProductConsult';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'fixed',
            inset: 0,
            display: 'grid',
            placeItems: 'center',
            overflow: 'auto',
            padding: '24px',
            zIndex: 100
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: "8px",
            width: "330px"
        },
    }),
);

const ProductsAndServices = () => {

    const classes = useStyles();

    // determinar si el modal puede verse o no
    const [open, setOpen] = React.useState(false);

    // useState que se edita al modal que se necesita (basado en la seleccion de usuario)
    const [selectedModal, setSelectedModal] = useState(<div></div>)

    const handleClose = () => {
        setOpen(false);
    };

    // se edita selected Modal para mostrar los requisitos de la opcion elegida
    const selectModal = (requirementOption: JSX.Element) => {
        setSelectedModal(requirementOption)
        setOpen(true)
    }
    
    return (
        <ComponentContainer>
            <h1>Productos y Servicios</h1>
            <div className="cyber-spacer medium"></div>
            <p>Aquí te mostramos algunos de los productos y servicios que CyberSign te ofrece.</p>
            <div className="cyber-grid three-columns">

                <motion.div
                    className="cyber-card bordered flex direction-column justify-between align-start"
                    whileHover={{
                        scale: 1.1,
                        boxShadow: "0px 0px 8px rgb(33, 149, 217)"
                    }}
                    onClick = {() => selectModal(<ProductSign handleClose = {handleClose}/>)}
                >
                    <h5>Firma Electrónica</h5>
                    <div className="cyber-spacer medium"></div>
                    <p>Haz click aqui para tener más información</p>
                </motion.div>

                <motion.div
                    className="cyber-card bordered flex direction-column justify-between align-start"
                    whileHover={{
                        scale: 1.1,
                        boxShadow: "0px 0px 8px rgb(33, 149, 217)"
                    }}

                    onClick = {() => selectModal(<ProductValidate handleClose = {handleClose}/>)}
                >
                    <h5>Validación de documentos</h5>
                    <div className="cyber-spacer medium"></div>
                    <p>Haz click aqui para tener más información</p>
                </motion.div>

                <motion.div
                    className="cyber-card bordered flex direction-column justify-between align-start"
                    whileHover={{
                        scale: 1.1,
                        boxShadow: "0px 0px 8px rgb(33, 149, 217)"
                    }}
                    onClick = {() => selectModal(<ProductConsult handleClose = {handleClose}/>)}
                >
                    <h5>Consulta de certificados</h5>
                    <div className="cyber-spacer medium"></div>
                    <p>Haz click aqui para tener más información</p>
                </motion.div>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        {selectedModal}
                    </div>
                </Fade>
            </Modal>

        </ComponentContainer>
    )
}

export default ProductsAndServices