import React from 'react'
import { Link } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

interface RevokeCProps {
    setConfirmStateName: Function
    setConfirmStateNumber: Function
    setComponentState: Function
}

const RevokeComponent: React.FC<RevokeCProps> = ({ setConfirmStateName, setConfirmStateNumber, setComponentState }) => {
    return (
        <div>
            <Link to="#" className="cyber-list__item error go-to-step2" onClick={() => {
                setConfirmStateName("revocar")
                setConfirmStateNumber(3)
                setComponentState(7)
            }}>
                <div className="cyber-list__item-icon-left">
                    <DeleteIcon />
                </div>
                <div className="cyber-list__item-content">
                    <b>Revocar</b>
                    <span>Elimina esta firma permanentemente.</span>
                </div>
                <ChevronRightIcon className="cyber-list__item-icon-right material-icons" />
            </Link>
        </div>
    )
}

export default RevokeComponent
