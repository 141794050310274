import React, { useContext, Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import img from '../../images/logo_cybersign.svg'

import { AuthContext } from '../auth-context/auth-context';
// routes
import routeConfig from '../../config/routeConfig'

interface CyberSidenavProp {
    hasPersonalInfo: boolean
}

const CyberSidenav: React.FC<CyberSidenavProp> = ({ hasPersonalInfo }) => {

    const location = useLocation();

    const authCtx = useContext(AuthContext);

    const closeSidenav = () => {
        document.querySelector('.cyber-sidenav')!.classList.remove('visible');
    }

    if (location.pathname.match(routeConfig.requestSignature)) {
        return null;
    }

    return (
        <div className="cyber-sidenav" onClick={closeSidenav}>

            <Link to="/">
                <img src={img} className="cyber-sidenav__logo" alt="" />
            </Link>
            <hr className="cyber-nav__divider" />
            <div className="cyber-spacer--40"></div>
            <div className="cyber-nav">
                {
                    authCtx.isAuth ? (
                        <Fragment>
                            <Link className="cyber-nav__link" to={routeConfig.dashboard}>Dashboard</Link>
                            { Object.values(JSON.parse(localStorage.getItem("signatures") || "{}")).length == 0 ? <Link className="cyber-nav__link" to={routeConfig.requestSignature}>Solicitar Firma</Link> : null }
                            { Object.values(JSON.parse(localStorage.getItem("signatures") || "{}")).includes("emitting") ? <Link className="cyber-nav__link" to={routeConfig.myProcesses}>Mis Procesos</Link> : null}
                            {
                                Object.values(JSON.parse(localStorage.getItem("signatures") || "{}")).includes("active") ||
                                Object.values(JSON.parse(localStorage.getItem("signatures") || "{}")).includes("not active") ? (
                                    <div>
                                        <Link className="cyber-nav__link" to={routeConfig.otpVerification}>Verificar OTP</Link>
                                        <Link className="cyber-nav__link" to={routeConfig.resetOTP}>Restablecer OTP</Link>
                                        <Link className="cyber-nav__link" to={routeConfig.mySignatures}>Mis firmas</Link>
                                        <Link className="cyber-nav__link" to={routeConfig.customManuscript}>Configurar manuscrito</Link>
                                        <Link className="cyber-nav__link" to={routeConfig.uploadDocument}>Subir Documento</Link>
                                    </div>
                                ) : null
                            }

                        </Fragment>
                    ) : null
                }
                <Link className="cyber-nav__link" to={routeConfig.verifyDocument}>Validar Documento</Link>
                <Link className="cyber-nav__link" to={routeConfig.consultCertificate}>Consultar Certificado</Link>
                <hr className="cyber-nav__divider" />
                <Link className="cyber-nav__link" to={routeConfig.products}>Productos y Servicios</Link>
                <Link className="cyber-nav__link" to={routeConfig.requirements}>Requisitos</Link>
                <Link className="cyber-nav__link" to={routeConfig.legal}>Marco Legal</Link>
                <Link className="cyber-nav__link" to={routeConfig.policies}>Políticas y procedimientos</Link>
                <Link className="cyber-nav__link" to={routeConfig.certificateAndCrl}>Certificados y CRL</Link>
                <Link className="cyber-nav__link" to={routeConfig.contact}>Contacto</Link>
            </div>

        </div>
    )
}

export default CyberSidenav
