import React from 'react'

// images
import imgPreloader from "../../images/img_preloader.svg"
import imgSignature from "../../images/img_signature.svg"
import imgDocs from "../../images/img_docs.svg"

//icons
import AddIcon from '@material-ui/icons/Add';
import ComponentContainer from '../../components/component-container/ComponentContainer';
import { Link, useHistory } from 'react-router-dom';
import routeConfig from '../../config/routeConfig';

interface DashboardProps {
    refreshToken: Function
}

const Dashboard: React.FC<DashboardProps> = ({ refreshToken }) => {

    const history = useHistory()

    refreshToken()

    return (
        <ComponentContainer>

            {
                localStorage.getItem("user-name") === undefined
                ?
                <h1>Hola</h1>
                :
                <h1>Hola, {localStorage.getItem("user-name")}</h1>
            }

            <div className="cyber-spacer medium"></div>

            {
                // At least, one signature in "emitting" status
                Object.values(JSON.parse(localStorage.getItem("signatures") || "{}")).includes("emitting") ? (
                    <div>
                        <div className="cyber-alert success">
                            <div className="cyber-alert__image-container">
                                <img className="cyber-preloader" src={imgPreloader} alt="" />
                            </div>
                            <div className="cyber-alert__content">
                                <h4 className="cyber-alert__title">Generando una firma</h4>
                                <span>Este proceso puede toma solo unos minutos pero en ocasiones puede tomar más. Te notificaremos por correo cuando este listo.</span>
                            </div>
                        </div>
                        <div className="cyber-spacer"></div>
                    </div>
                ) : null
            }


            {
                // At least one signature
                Object.values(JSON.parse(localStorage.getItem("signatures") || "{}")).length > 0 ? (
                    <div className="cyber-alert">
                        <div className="cyber-alert__image-container">
                            <img src={imgSignature} alt="" />
                        </div>
                        <div className="cyber-alert__content">
                            <h4 className="cyber-alert__title">Configura tu manuscrito</h4>
                            <span>Te recomendamos hacerlo desde tu celular para poder hacer mejor tu manuscrito.</span>
                        </div>
                        <div className="cyber-alert__actions">
                            <span className="cyber-button bordered" onClick={() => history.push(routeConfig.customManuscript)}>Configurar manuscrito</span>
                        </div>
                    </div>
                ) : (
                    <div className="cyber-alert">
                        <div className="cyber-alert__image-container">
                            <img src={imgSignature} alt="" />
                        </div>
                        <div className="cyber-alert__content">
                            <h4 className="cyber-alert__title">Solicita tu firma</h4>
                            <span>Debes iniciar la solicitud de firma para poder configurar tu manuscrito y comenzar a firmar.</span>
                        </div>
                        <div className="cyber-alert__actions">
                            <button className="cyber-button bordered" onClick={() => history.push(routeConfig.requestSignature)}>Solicita tu firma</button>
                        </div>
                    </div>
                )
            }

            <div className="cyber-spacer large"></div>

            {
                // At least, one signature in "active" status
                Object.values(JSON.parse(localStorage.getItem("signatures") || "{}")).includes("active") ? (
                    <div className="cyber-placeholder">
                        <img className="cyber-placeholder__image" src={imgDocs} alt="" />
                        <Link to="#" className="cyber-button" onClick={() => history.push(routeConfig.uploadDocument)}>
                            <span className="material-icons"><AddIcon className="icon-buttonDown" /></span>
                            <span>Firmar nuevo documento</span>
                        </Link>
                    </div>
                ) : null
            }

            {
                // At least, one signature in "not active" status
                Object.values(JSON.parse(localStorage.getItem("signatures") || "{}")).includes("not active") ? (
                    <div className="cyber-placeholder">
                        <img className="cyber-placeholder__image" src={imgDocs} alt="" />
                        <Link to="#" className="cyber-button" onClick={() => history.push(routeConfig.mySignatures)}>
                            <span className="material-icons"><AddIcon className="icon-buttonDown" /></span>
                            <span>Mis firmas</span>
                        </Link>
                    </div>
                ) : null
            }

            {
                // No signatures
                Object.values(JSON.parse(localStorage.getItem("signatures") || "{}")).length == 0 ? (
                    <div className="cyber-placeholder" style={{ padding: "5px" }}>
                        <Link to="#" className="cyber-button" onClick={() => history.push(routeConfig.requestSignature)}>
                            <span className="material-icons"><AddIcon className="icon-buttonDown" /></span>
                            <span>Solicitar firma</span>
                        </Link>
                    </div>
                ) : null
            }

        </ComponentContainer>
    )
}

export default Dashboard
