import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import routeConfig from '../../config/routeConfig';
import digsignature from '../../images/dig_signature.jpeg';

interface ProductSignProps {
    handleClose: Function
}

const ProductSign: React.FC<ProductSignProps> = ({ handleClose })  => {

    const history = useHistory();

    return (
        <div >
            <div className="moveRight moveDown">
                <button onClick={() => handleClose()}>
                    <CloseIcon />
                </button>
            </div>

            <div>
                <h3>Firma Electrónica</h3>
                <br />
                <p>
                    ¿Te gustaría firmar tus documentos sin necesidad de un lápiz?
                    <br />
                    ¿Sientes el riesgo de que tu firma sea falsificada en documentos importantes?
                    <br />
                    ¿Sabes lo que es una firma electrónica?

                    <br />
                    Pues aquí te lo explicamos.
                    <br />
                    <br />
                    Una firma electrónica es un conjunto de autenticaciones digitales asociadas a un documento específico.
                    Esto permite identificar al firmante de manera irrefutable, se mantiene la integridad del documento firmado y asegurda el no repudio del documento firmado
                    <br />
                    <br />
                    Nuestro servicio de firma electrónica se caracteriza por ofrecer un servicio rápido y completamente automatizado a menor precio.
                </p>
                <img src={digsignature} alt="" style={{ width: "100%" }} />
                <p>Inicia sesión y sube un documento para verlo por tí mismo.</p>
                <button style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                }} className="cyber-button" onClick={() => history.push(routeConfig.uploadDocument)}>Continuar</button>
            </div>
        </div>
    )
}

export default ProductSign