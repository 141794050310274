import React, { useState } from 'react'
import SignDocumentSteps from './components/SignDocumentSteps'
import DocumentSignature from './DocumentSignature'
import DocumentSignatureOTP from './DocumentSignatureOTP'
import DocumentUpload from './DocumentUpload'
import DownloadingDocument from './DownloadingDocument'
import ErrorSignDocument from './ErrorSignDocument'
import SigningDocument from './SigningDocument'

import "./SignDocument.css"

interface SignDocumentProps {
    refreshToken: Function
}

const SignDocument: React.FC<SignDocumentProps> = ({ refreshToken }) => {

    refreshToken()

    const [componentState, setComponentState] = useState(0)
    const [userSub, setUserSub] = useState("")
    const [fileULID, setFileULID] = useState("")
    const [pdfName, setPdfName] = useState("")
    const [otpInputValue, setOtpInputValue] = useState("")
    const [certSerial, setCertSerial] = useState("0")
    const [downloadLink, setDownloadLink] = useState("")
    const [signaturePage, setSignaturePage] = useState(0)
    const [documentPages, setDocumentPages] = useState(0)
    const [customName, setCustomName] = useState("Selecciona una opción")
    const [pdfRelativePositionWidth, setPdfRelativePositionWidth] = useState(0.0)
    const [pdfRelativePositionHeight, setPdfRelativePositionHeight] = useState(0.0)
    const [getFileToUpload, setGetFileToUpload] = useState({
        lastModified: 1626280100000,
        name: "",
        size: 299062,
        type: "application/pdf",
        webkitRelativePath: ""
    })

    // opciones de visualizacion de firma
    const [hasDate, setHasDate] = useState(false)
    const [hasInitials, setHasInitials] = useState(false)
    const [hasMotive, setHasMotive] = useState(false)
    const [hasSignature, setHasSignature] = useState(false)
    const [motiveOfSignature, setMotiveOfSignature] = useState("")

    const nextStep = () => {
        setComponentState(componentState + 1)
    }

    const returnOptStep = () => {
        setComponentState(2)
    }

    const errorStep = () => {
        setComponentState(5)
    }

    const setStep = () => {
        setComponentState(0)
    }

    const returnStep = () => {
        setComponentState(componentState - 1)
    }

    let step

    switch (componentState) {
        case 0:
            step = <DocumentUpload
                nextStep={nextStep}
                setUserSub={setUserSub}
                setFileULID={setFileULID}
                setPdfName={setPdfName}
                setDocumentPages={setDocumentPages}
                setGetFileToUpload={setGetFileToUpload}
            />
            break // step 0
        case 1:
            step = <DocumentSignature
                nextStep={nextStep}
                returnStep={returnStep}
                pdfName={pdfName}
                certSerial={certSerial}
                setCertSerial={setCertSerial}
                customName={customName}
                setCustomName={setCustomName}
                documentPages={documentPages}
                signaturePage={signaturePage}
                setSignaturePage={setSignaturePage}
                hasDate={hasDate}
                setHasDate={setHasDate}
                hasInitials={hasInitials}
                setHasInitials={setHasInitials}
                hasMotive={hasMotive}
                setHasMotive={setHasMotive}
                hasSignature={hasSignature}
                setHasSignature={setHasSignature}
                motiveOfSignature={motiveOfSignature}
                setMotiveOfSignature={setMotiveOfSignature}
                getFileToUpload={getFileToUpload}
                setPdfRelativePositionWidth={setPdfRelativePositionWidth}
                setPdfRelativePositionHeight={setPdfRelativePositionHeight}
            />
            break // step 1
        case 2:
            step = <DocumentSignatureOTP
                setOtpInputValue={setOtpInputValue}
                nextStep={nextStep}
                returnStep={returnStep}
            />
            break // step 2
        case 3:
            step = <SigningDocument
                userSub={userSub}
                pdfName={pdfName}
                fileULID={fileULID}
                otpInputValue={otpInputValue}
                certSerial={certSerial}
                setDownloadLink={setDownloadLink}
                nextStep={nextStep}
                errorStep={errorStep}
                signaturePage={signaturePage}
                hasDate={hasDate}
                hasInitials={hasInitials}
                hasMotive={hasMotive}
                hasSignature={hasSignature}
                motiveOfSignature={motiveOfSignature}
                setHasDate={setHasDate}
                setHasInitials={setHasInitials}
                setHasMotive={setHasMotive}
                setHasSignature={setHasSignature}
                setMotiveOfSignature={setMotiveOfSignature}
                pdfRelativePositionWidth={pdfRelativePositionWidth}
                pdfRelativePositionHeight={pdfRelativePositionHeight}
                setPdfRelativePositionWidth={setPdfRelativePositionWidth}
                setPdfRelativePositionHeight={setPdfRelativePositionHeight}
            />
            break // step 3
        case 4:
            step = <DownloadingDocument
                downloadLink={downloadLink}
                setStep={setStep}
            />
            break // step 4
        case 5:
            step = <ErrorSignDocument
                returnOptStep={returnOptStep}
            />
            break // step 5 / error
    }

    return (

        <div className="cyber-main with-sidenav">
            <div className="cyber-spacer section"></div>
            <div className="cyber-container max-width-1000">
                <SignDocumentSteps
                    componentState={componentState}
                    returnStep={returnStep}
                />
                {step}
            </div>
        </div>


    )
}

export default SignDocument
