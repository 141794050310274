import React from 'react'
import Spinner from '../../../components/spinner/spinner'

function WaitingStep() {
    return (
        <div className="modalContainer" >
            <div className="alignWaitingStep">
                <Spinner/>
            </div>
            <h5>Enviando Solicitud</h5>
        </div>
    )
}

export default WaitingStep
