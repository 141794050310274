import React from 'react'
import { Link } from 'react-router-dom'

// images
import img_signed_document from "../../images/img_signed_document.svg"

interface DownloadingDocumentProps {
    downloadLink: string
    setStep: Function
}

const DownloadingDocument: React.FC<DownloadingDocumentProps> = ({ downloadLink, setStep }) => {

    // display document for download
    const displayDocument = (resp_url: string) => () => {
        window.open(resp_url, '_blank')
    }

    return (
        <div className="cyber-card bordered align-center direction-column">
            <div className="cyber-spacer small"></div>
            <img src={img_signed_document} width="160px" alt="" />
            <div className="cyber-spacer small"></div>
            <h3>Documento firmado correctamente</h3>
            <div className="cyber-spacer small"></div>
            <button className="cyber-button" onClick={displayDocument(downloadLink)}>Descargar</button>
            <div className="cyber-spacer small"></div>
            <Link to="#" onClick={() => {
                setStep()
            }}>Firmar un nuevo documento</Link>
            <div className="cyber-spacer small"></div>
        </div>
    )
}

export default DownloadingDocument
