import React, { useState } from 'react'
import { Link } from 'react-router-dom'

interface SidebarProps {
    setComponentState: Function
}

const ProfileSidebar: React.FC<SidebarProps> = ({ setComponentState }) => {

    const [active0, setActive0] = useState("cyber-profile__nav-link active")
    const [active1, setActive1] = useState("cyber-profile__nav-link")
    const [active2, setActive2] = useState("cyber-profile__nav-link")
    const [active3, setActive3] = useState("cyber-profile__nav-link")

    const resetHighlight = () => {
        setActive0("cyber-profile__nav-link")
        setActive1("cyber-profile__nav-link")
        setActive2("cyber-profile__nav-link")
        setActive3("cyber-profile__nav-link")
    }



    return (
        <div className="cyber-profile__sidebar-mobile">
            <div className="cyber-profile__nav">
                <Link to="#" className={active0} onClick={() => {
                    resetHighlight()
                    setActive0("cyber-profile__nav-link active")
                    setComponentState(0)
                }}>Información personal
                </Link>

                <Link to="#" className={active1} onClick={() => {
                    resetHighlight()
                    setActive1("cyber-profile__nav-link active")
                    setComponentState(1)
                }}>Documento de identificación
                </Link>

                <Link to="#" className={active2} onClick={() => {
                    resetHighlight()
                    setActive2("cyber-profile__nav-link active")
                    setComponentState(2)
                }}>Información de contacto
                </Link>

                <Link to="#" className={active3} onClick={() => {
                    resetHighlight()
                    setActive3("cyber-profile__nav-link active")
                    setComponentState(3)
                }}>Configuración a 2 pasos (2FA)
                </Link>
            </div>
        </div>
    )
}

export default ProfileSidebar
