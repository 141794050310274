import axios from 'axios'
import React, { useState } from 'react'
import UploadBox from '../../components/upload-box/UploadBox'
import { PDFDocument } from 'pdf-lib';
import endpointConfig from '../../config/endpointConfig';
import notifyHB from '../../config/notifyHB';

interface DocumentUploadProps {
    nextStep: Function
    setUserSub: Function
    setFileULID: Function
    setPdfName: Function
    setDocumentPages: Function
    setGetFileToUpload: Function
}

const DocumentUpload: React.FC<DocumentUploadProps> = ({
    nextStep,
    setUserSub,
    setFileULID,
    setPdfName,
    setDocumentPages,
    setGetFileToUpload
}) => {

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    type LoginData = {
        sub: string;
        email_verified: string;
        email: string;
        username: string;
    }

    // useStates
    const [allowUpload, setAllowUpload] = useState(false)
    const [fileToUpload, setFileToUpload] = useState({
        lastModified: 1626280100000,
        name: "",
        size: 299062,
        type: "application/pdf",
        webkitRelativePath: ""
    });

    const readFile = (file: any) => {

        return new Promise((resolve, reject) => {

            const reader = new FileReader();

            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);

            reader.readAsArrayBuffer(file);
        });
    }

    const getNumPages = async (file: any) => {

        const arrayBuffer = await readFile(file);

        // @ts-ignore
        const pdf = await PDFDocument.load(arrayBuffer)

        setDocumentPages(pdf.getPages().length)
    }



    const handleFileChange = (event: any) => {
        let selectedFile = event.target.files[0]

        if (selectedFile) {
            getNumPages(selectedFile)
            setFileToUpload(selectedFile)
            setGetFileToUpload(selectedFile)
            setAllowUpload(true)
        }

    }

    const setTierSize = (fileSize: number) => {

        let tier: string = 'unknown';

        if (fileSize > 0 && fileSize < 20)
            tier = 'tier-1'
        else if (fileSize < 40)
            tier = 'tier-2'
        else if (fileSize < 80)
            tier = 'tier-3'
        else if (fileSize < 100)
            tier = 'tier-4'
        else if (fileSize < 120)
            tier = 'tier-5'

        return tier;

    }

    const handleS3Form = (postInfo: any) => {
        let newForm: FormData = new FormData()
        newForm.append('key', postInfo['fields']['key'])
        newForm.append('x-amz-credential', postInfo['fields']['x-amz-credential'])
        newForm.append('x-amz-algorithm', postInfo['fields']['x-amz-algorithm'])
        newForm.append('policy', postInfo['fields']['policy'])
        newForm.append('x-amz-signature', postInfo['fields']['x-amz-signature'])
        newForm.append('x-amz-date', postInfo['fields']['x-amz-date'])
        // @ts-ignore: Object is possibly 'null'.
        newForm.append('file', fileToUpload)

        return newForm;
    }

    const getFromLocalStorage = (itemName: string) => {
        return JSON.parse(localStorage.getItem(itemName)!);
    }

    const handleFileUpload = async () => {
        if (fileToUpload.type === "application/pdf" && allowUpload) {

            if (fileToUpload.name.substr(fileToUpload.name.length - 3) !== "PDF") {
                const fileSize = Math.ceil(fileToUpload.size / 1000000)
                let tier: string = setTierSize(fileSize)

                let authToken: UserInfo = getFromLocalStorage("user-info")

                let loginData: LoginData = getFromLocalStorage("login-data")

                const resp: any = await axios.post(endpointConfig.docsUpload, {
                    file_size: tier,
                    new_object: loginData['sub'] + "/" + fileToUpload.name
                }, {
                    headers: {
                        Authorization: `Bearer ${authToken["id_token"]}`,
                    }
                }).catch(error => {
                    alert('Error al subir el documento');
                    notifyHB("upload-document", "DocumentUpload", error)
                })

                let fileULIDResponse = JSON.parse(resp['data']['body']);

                if (fileULIDResponse !== undefined) {
                    let getFileULID: string = fileULIDResponse.fields.key.split("/");
                    setUserSub(getFileULID[0]);
                    setFileULID(getFileULID[1]);
                    setPdfName(getFileULID[2]);
                }

                const postInfo = JSON.parse(resp['data']['body']);
                const s3Form: FormData = handleS3Form(postInfo);

                const headerscustom = {
                    'Content-Disposition': postInfo['fields']['Content-Disposition'],
                    'x-amz-meta-share': postInfo['fields']['x-amz-meta-share']
                }

                try {
                    axios.post(`${postInfo['url']}`, s3Form, { headers: headerscustom })
                    nextStep()
                } catch (e) {
                    alert("Hubo un error al subir el documento")
                    notifyHB("upload-document", "DocumentUpload", e)
                }
            } else {
                alert("Hay un error con el documento, asegurarse que el documento sea '.pdf' y no '.PDF' ")
            }

        } else {
            alert("No hay documento o no es un pdf")
        }
    }

    return (
        <UploadBox>
            <h3>Sube el documento a firmar</h3>
            <div className="cyber-spacer medium"></div>
            <div className="cyber-upload-box__input">
                <input
                    type="file"
                    className="form-group"
                    required
                    accept="application/pdf"
                    onChange={handleFileChange}
                />
            </div>
            <button className="cyber-button" onClick={handleFileUpload} >Subir documento</button>
        </UploadBox>
    )
}

export default DocumentUpload
