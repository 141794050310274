import React, { useEffect, useState } from 'react';
import './App.css';

// routing
import {
  // eslint-disable-next-line
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

// pages & components
import PoliciesAndProcedures from './pages/policies/PoliciesAndProcedures';
import CertificatesAndCrl from './pages/certificates-crl/CertificatesAndCrl';
import Legal from './pages/legal/Legal';
import VerifyDocument from './pages/verify-document/VerifyDocument';
import HeaderSidenav from './components/sidenav/HeaderSidenav';
import CyberSidenav from './components/sidenav/CyberSidenav';
import CheckCertificates from './pages/check-certificates/CheckCertificates';
import LoginCognito from './components/login/LoginCognito';
import CodeExchange from './components/code-exchange/CodeExchange';
import MySignatures from './pages/my-signatures/MySignatures';
import ResetOtp from './pages/reset-otp/ResetOtp';
import ResetOtp2 from './pages/reset-otp/ResetOtp2';
import RedirectLogin from './components/login/RedirectLogin';
import OtpVerification from './pages/otp-verification/OtpVerification';
import Landing from './pages/landing/Landing';
import Dashboard from './pages/dashboard/Dashboard';

// routes
import routeConfig from './config/routeConfig';
import AuthContextProvider from './components/auth-context/auth-context';
import Profile from './pages/profile/Profile';
import Register from './pages/request-signature/Register';
import Test from './pages/Test';
import SignDocument from './pages/upload-document/SignDocument';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import refreshToken from './components/refresh-token/refreshToken';
import CustomSignature from './pages/custom-signature/CustomSignature';
import CyberFooter from './components/footer/CyberFooter';
import MyProcesses from './pages/my-processes/MyProcesses';
import { ProtectedRoute } from './config/ProtectedRoute';
import endpointConfig from './config/endpointConfig';
import Contact from './pages/contact/Contact';
import notifyHB from './config/notifyHB';
import ProductsAndServices from './pages/products-and-services/ProductsAndServices';
import Requirements from './pages/requirements/Requirements';

function App() {

  // nombre de usuario
  const [userName, setUserName] = useState("")

  // email de usuario
  const [userEmail, setUserEmail] = useState("")

  // boolean para determinar si tiene informacion personal (para determinar si es un nuevo usuario)
  const [hasPersonalInfo, setHasPersonalInfo] = useState(false)

  // cookies
  const [, setUserNameC] = useCookies(["userName"])
  const [,] = useCookies(["loginData"])
  const [, setSignaturePC] = useCookies(["signatureP"])

  const personalInformation = async () => {
    const getToken = JSON.parse(localStorage.getItem("user-info")!)

    if (getToken === null) { return }

    const authToken: string = getToken['id_token'];

    let manageResponse;

    try {
      manageResponse = await axios.post(
        endpointConfig.user,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      )
    } catch {
      manageResponse = {}
      localStorage.setItem("personalInfo", "false")
    }

    localStorage.setItem("signatures", (manageResponse?.data?.clientInformation?.signatures || "{}"))

    if (manageResponse.status === 200) {
      // si la respuesta de status es OK, se muestran las otras opciones en el sidenav
      // hasPersonalInfo va a CyberSidenav como prop
      setHasPersonalInfo(true)
    }

    let signatureP = manageResponse?.data?.clientInformation?.signaturePath

    // guardar signature path en cookies (en el caso que lo tenga)
    setSignaturePC("signatureP", signatureP,
      {
        domain: process.env.REACT_APP_DOMAIN,
        path: "/",
        secure: true,
        sameSite: "strict"
      }
    )

    let firstName = manageResponse?.data?.clientInformation?.first_name
    let lastName = manageResponse?.data?.clientInformation?.last_name
    let name = ""

    if (firstName != undefined && lastName != undefined) {
      name = firstName + " " + lastName
    }

    // guardan nombre se usuario en cookies
    setUserNameC("userName", name,
      {
        domain: process.env.REACT_APP_DOMAIN,
        path: "/",
        secure: true,
        sameSite: "strict"
      }
    )

    localStorage.setItem("user-name", name)

    setUserName(name)

    let loginData = JSON.parse(localStorage.getItem("login-data")!)

    // si existe un garabato del usuario, guardarlo para poder hacer display en mis firmas
    if (signatureP !== "no signature") {
      localStorage.removeItem("signatureImg")
      let req_object: string = `${loginData['sub']}/signature.jpg`

      try {
        const downloadLink = await axios.post(
          endpointConfig.docsDownload,
          { "req_obj": req_object },
          {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }
        )

        if (downloadLink.status === 200) {
          localStorage.setItem("signatureImg", downloadLink['data']['body'])
        }
      } catch (error) {
        localStorage.setItem("personalInfo", "false")
        notifyHB("App", "signature", error)
      }
    }

  }

  useEffect(() => {

    let loginData = JSON.parse(localStorage.getItem("login-data")!)

    if (loginData === null) { return }

    setUserEmail(loginData["email"])

    let personalInfo = localStorage.getItem("personalInfo");

    if (personalInfo === null) {
      localStorage.setItem("personalInfo", "true")
    }

    personalInformation()

  }, [localStorage.getItem("login-data")]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContextProvider>

      <Router>

        <HeaderSidenav userName={userName} userEmail={userEmail} />
        <CyberSidenav hasPersonalInfo={hasPersonalInfo} />

        <Switch>
          <Route path={routeConfig.home} exact component={Landing} />
          <ProtectedRoute path={routeConfig.dashboard} userNameC={localStorage.getItem("user-name") || undefined} exact component={() => <Dashboard refreshToken={refreshToken.RefreshTokenFunction} />} />
          <Route path={routeConfig.codeExchange} exact component={CodeExchange} />
          <Route path={routeConfig.resetOTP2} component={() => <ResetOtp2 refreshToken={refreshToken.RefreshTokenFunction} />} />
          <Route path={routeConfig.legal} component={() => <Legal refreshToken={refreshToken.RefreshTokenFunction} />} />
          <Route path={routeConfig.verifyDocument} component={() => <VerifyDocument refreshToken={refreshToken.RefreshTokenFunction} />} />
          <Route path={routeConfig.policies} component={() => <PoliciesAndProcedures refreshToken={refreshToken.RefreshTokenFunction} />} />
          <Route path={routeConfig.certificateAndCrl} component={() => <CertificatesAndCrl refreshToken={refreshToken.RefreshTokenFunction} />} />
          <Route path={routeConfig.consultCertificate} component={() => <CheckCertificates refreshToken={refreshToken.RefreshTokenFunction} />} />
          <ProtectedRoute path={routeConfig.requestSignature} userNameC={localStorage.getItem("user-name") || undefined} component={() => <Register refreshToken={refreshToken.RefreshTokenFunction} />} />
          <ProtectedRoute path={routeConfig.mySignatures} userNameC={localStorage.getItem("user-name") || undefined} component={() => <MySignatures refreshToken={refreshToken.RefreshTokenFunction} />} />
          <ProtectedRoute path={routeConfig.uploadDocument} userNameC={localStorage.getItem("user-name") || undefined} component={() => <SignDocument refreshToken={refreshToken.RefreshTokenFunction} />} />
          <ProtectedRoute path={routeConfig.otpVerification} userNameC={localStorage.getItem("user-name") || undefined} component={() => <OtpVerification refreshToken={refreshToken.RefreshTokenFunction} />} />
          <Route path={routeConfig.redirectLogin} component={RedirectLogin} />
          <ProtectedRoute path={routeConfig.resetOTP} userNameC={localStorage.getItem("user-name") || undefined} component={() => (<ResetOtp refreshToken={refreshToken.RefreshTokenFunction} />)} />
          <Route path={routeConfig.loginCognito} component={LoginCognito} />
          <ProtectedRoute path={routeConfig.profile} userNameC={localStorage.getItem("user-name") || undefined} component={() => <Profile refreshToken={refreshToken.RefreshTokenFunction} />} />
          <ProtectedRoute path={routeConfig.customManuscript} userNameC={localStorage.getItem("user-name") || undefined} component={() => <CustomSignature refreshToken={refreshToken.RefreshTokenFunction} />} />
          <ProtectedRoute path={routeConfig.myProcesses} userNameC={localStorage.getItem("user-name") || undefined} component={() => <MyProcesses refreshToken={refreshToken.RefreshTokenFunction} />} />
          <Route path={routeConfig.contact} component={Contact} />
          <Route path={routeConfig.requirements} component={Requirements} />
          <Route path={routeConfig.products} component={ProductsAndServices} />
          <Route path={routeConfig.test} component={Test} />
        </Switch>

        <CyberFooter />

      </Router>

    </AuthContextProvider>
  );
}

export default App;
