import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import routeConfig from '../../config/routeConfig';
import consultImg from '../../images/consultimg.jpeg'

interface ProductConsultProps {
    handleClose: Function
}

const ProductConsult: React.FC<ProductConsultProps> = ({ handleClose }) => {

    const history = useHistory();

    return (
        <div>
            <div className="moveRight moveDown">
                <button onClick={() => handleClose()}>
                    <CloseIcon />
                </button>
            </div>

            <div>
                <h3>Consulta de certificados</h3>
                <br />
                <p>¿No tienes tu certificado a mano?</p>
                <p>
                    Puedes consultar si un documento fue firmado por nuestros servicios a través del número de serie del certificado o el CA Hash.
                </p>
                <img src={consultImg} alt="" style={{ width: "100%" }} />
                <p>Consulta la información de tus documentos aquí mismo</p>
                <button style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                }} className="cyber-button" onClick={() => history.push(routeConfig.consultCertificate)}>Continuar</button>
            </div>
        </div>
    )
}

export default ProductConsult