import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../../components/spinner/spinner'
import endpointConfig from '../../config/endpointConfig'
import DisabledInput from './components/DisabledInput'
import CyberInputComponent from '../request-signature/components/CyberInputComponent'
import ProfileInformationContent from './components/ProfileInformationContent'

interface DisabledInputProps {
    email: string
    phoneCountryCode: string
    setPhoneCountryCode: Function
    phoneNumber: string
    setPhone: Function
}

const ProfileCI: React.FC<DisabledInputProps> = ({ email, phoneCountryCode, phoneNumber, setPhoneCountryCode, setPhone }) => {

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');
    const authToken: string = getToken['id_token'];

    // useState
    const [loadingState, setLoadingState] = useState(<p></p>)
    const [fieldsWarning, setFieldsWarning] = useState(<p></p>)
    const [editPhone, setEditPhone] = useState(false)

    useEffect(() => {
        setFieldsWarning(<p></p>)
    }, [editPhone])

    const isCountryCode = (possibleCountryCode: string) => {
        return /^\d{1,3}$/.test(possibleCountryCode)
    }

    const isTelephoneNumber = (possibleNumber: string) => {
        return /^\(?\d{1,4}\)?\s?\d{1,4}\s?\d{1,4}$/.test(possibleNumber)
    }

    const updatePhone = async () => {

        if (isCountryCode(phoneCountryCode) && isTelephoneNumber(phoneNumber)) {

            setEditPhone(false)

            setFieldsWarning(<p></p>)

            setLoadingState(<div style={{ textAlign: "center" }}><Spinner /></div>)

            try {
                const manageResponse = await axios.post(
                    endpointConfig.userUpdate,
                    {
                        "phone_number": phoneNumber.replaceAll(' ', ''),
                        "phone_country_code": phoneCountryCode
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    }
                )

                if (manageResponse.status === 200) {
                    setLoadingState(<label style={{ color: "green" }}>Teléfono actualizado</label>)
                }
            } catch (error: any) {
                setFieldsWarning(<p style={{ color: "red" }}>Hubo un error, por favor intentar nuevamente o contactar a soporte.</p>)
            }

        } else {
            setFieldsWarning(<p style={{ color: "red" }}>El número de telefono no es válido</p>)
        }

        setLoadingState(<p></p>)

    }

    return (
        <div>
            <ProfileInformationContent>
                <h4>Información de contacto</h4>
                <div className="cyber-spacer small"></div>
                <DisabledInput inputLabel="Correo electrónico" inputValue={email} />

                {
                    editPhone ? (

                        <div>
                            <div className="cyber-input">
                                <span className="flex">
                                    <CyberInputComponent
                                        inputLabel="Código"
                                        placeholder={ phoneCountryCode || "XXX" }
                                        value={phoneCountryCode}
                                        setValue={setPhoneCountryCode}
                                        validInput={isCountryCode(phoneCountryCode)}
                                        maxWidth="100px"
                                    />
                                    <CyberInputComponent
                                        inputLabel="Número de teléfono"
                                        placeholder={ phoneNumber || "XXXX XXXX" }
                                        value={phoneNumber}
                                        setValue={setPhone}
                                        validInput={isTelephoneNumber(phoneNumber)}
                                    />
                                </span>
                                <div>
                                    {loadingState}
                                    {fieldsWarning}
                                </div>
                            </div>


                            <Link
                                to="#"
                                className="cyber-button inputLayout"
                                style={{ width: "100%", left: "-10px" }}
                                onClick={updatePhone}
                            >
                                Actualizar
                            </Link>
                        </div>
                    ) : (
                        <div>
                            <span className="flex">
                                <DisabledInput inputLabel="Código" inputValue={phoneCountryCode} maxWidth='100px' />
                                <DisabledInput inputLabel="Número de teléfono" inputValue={phoneNumber} />
                            </span>
                            <div>
                                {loadingState}
                            </div>
                            <Link
                                to="#"
                                className="cyber-button inputLayout"
                                style={{ width: "100%", left: "-10px" }}
                                onClick={() => {
                                    setEditPhone(true)
                                    setLoadingState(<p></p>)
                                }}
                            >
                                Editar número de teléfono
                            </Link>
                        </div>
                    )
                }

            </ProfileInformationContent>
        </div>
    )
}

export default ProfileCI
