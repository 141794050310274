import React, { useEffect, useState } from 'react'
import axios from 'axios';
import LoadingOtpComponent from './components/LoadingOtpComponent'
import queryString from 'query-string'
import DisplayQR from './components/DisplayQR';
// icons
import LockIcon from '@material-ui/icons/Lock';
import { Link } from 'react-router-dom';

import imgPreloader from "../../images/img_preloader.svg"
import endpointConfig from '../../config/endpointConfig';
import notifyHB from '../../config/notifyHB';

interface RegisterProps {
    nextStep: Function
}

const OtpComponent: React.FC<RegisterProps> = ({ nextStep }) => {

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }


    const [qrOtp, setQrOtp] = useState("");
    const [userInput, setUserInput] = useState("");
    const [qrCodeComponent, setqrCodeComponent] = useState(<LoadingOtpComponent />)
    const [buttonDisplay, setButtonDisplay] = useState(
        <p className="cyber-button full">Continuar</p>
    )

    const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');

    const sendOtp = async () => {

        try {

            setButtonDisplay(
                <div className="centerSpinner">
                    <img className="cyber-preloader" src={imgPreloader} alt="" />
                </div>
            )

            const body = {
                totp: userInput,
                OTP: qrOtp
            }

            const headers = {
                Authorization: `Bearer ${getToken['id_token']}`
            }

            const verifyResponse = await axios.post(endpointConfig.otpVerify, body, { headers })

            if (verifyResponse.status === 200) {
                nextStep()
            } else {
                setButtonDisplay(<p className="cyber-button full">Continuar</p>)
            }
        } catch (error: any) {
            setButtonDisplay(<p className="cyber-button full">Volver a intentar</p>)
            notifyHB("request-signature", "OtpComponent", error)
        }

    }

    const getOtpResponse = async () => {
        try {
            const otpResponse: any = await axios({
                method: 'post',
                url: endpointConfig.otp,
                headers: {
                    Authorization: `Bearer ${getToken['id_token']}`
                }
            })

            setQrOtp(otpResponse["data"]["OTP"]);

            const code = queryString.parse(otpResponse["data"]["message"]);

            let secret: string = String(code["otpauth://totp/CyberSign:Firma?secret"]);

            if (secret == "undefined") {
                secret = String(code["otpauth://totp/Celera Test:Cyber Sign?secret"]);
                if (secret == "undefined") { throw new Error("No secret on totp code") }
            }

            setqrCodeComponent(
                <DisplayQR otpDataMessage={otpResponse["data"]["message"]} secret={secret} />
            );

        } catch (error: any) {
            if (error.response && error.response.data.error === "User already has a primary OTP") {
                nextStep()
            } else {
                setqrCodeComponent(<p>Error, contactar a soporte</p>)
                notifyHB("request-signature", "OtpComponent", error)
            }
        }

    }

    useEffect(() => {
        getOtpResponse();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>

            <div>

                {qrCodeComponent}

                <div className="cyber-spacer medium"></div>
                <div className="cyber-form">
                    <div className="cyber-input icon-left">
                        <label className="cyber-input__label">Ingresa el código OTP para verificar tu cuenta</label>
                        <input type="text" className="cyber-input__textfield" placeholder="XXX XXX" onChange={event => setUserInput(event.target.value)} />
                        <span className="cyber-input__icon-left material-icons"><LockIcon /></span>
                    </div>
                    <Link to="#" onClick={sendOtp}>
                        {buttonDisplay}
                    </Link>
                </div>

            </div>

        </div>
    )
}

export default OtpComponent
