import React from 'react'
import DisabledInput from './components/DisabledInput'
import DisabledSelect from './components/DisabledSelect'
import ProfileInformationContent from './components/ProfileInformationContent'

interface DisabledInputProps {
    documentType: string
    documentNumber: string
    countryEmission: string
    expirationDate: string
}

const ProfileDI: React.FC<DisabledInputProps> = ({ documentType, documentNumber, countryEmission, expirationDate }) =>  {
    return (
        <div>
            <ProfileInformationContent>
            <h4>Documento de identificación</h4>
            <div className="cyber-spacer small"></div>
            <DisabledSelect selectLabel="Tipo de documento" selectValue={documentType} />
            <DisabledInput inputLabel="Número de documento" inputValue={documentNumber} />
            <DisabledInput inputLabel="País de emisión" inputValue={countryEmission} />
            <DisabledInput inputLabel="Fecha de expiración" inputValue={expirationDate} />
            </ProfileInformationContent>
        </div>
    )
}

export default ProfileDI
