import React from 'react'

const CyberViewContainer: React.FC = (props) => {
    return (
        <div className="cyber-view cyber-view--register">
            {props.children}
        </div>
    )
}

export default CyberViewContainer
