import React from 'react'

const ProfileInformationContent: React.FC = (props) => {
    /*
    <div class="cyber-profile__content">
        <div class="cyber-card show-on-large-up">
          <form class="cyber-form">
          */
    return (
        <div className="cyber-profile__content" style={{ width: "100%" }}>
            <div className="cyber-form">
                {props.children}
            </div>
        </div>
    )
}

export default ProfileInformationContent
