import React from 'react'

interface ListItem{
    itemNumber: number,
    itemHeader: string,
    itemMessage: object
}

const ListItemComponent: React.FC<ListItem> = ({itemNumber, itemHeader, itemMessage}) => {
    return (
        <div className="cyber-register-list__item">
            <h4 className="cyber-register-list__item-number">{itemNumber}</h4>
            <div className="cyber-register-list__item-content">
                <h5>{itemHeader}</h5>
                <span>{itemMessage}</span>
            </div>
        </div>
    )
}

export default ListItemComponent
