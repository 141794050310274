import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ulid } from 'ulid';
import { Link } from 'react-router-dom';
import Spinner from '../../components/spinner/spinner';
import endpointConfig from '../../config/endpointConfig';
import notifyHB from '../../config/notifyHB';

interface RegisterProps {
    nextStep: Function
    personName: string
    personLastName: string
    address: string
    userPhoneCountryCode: string
    userPhone: string
    nit: string
    profession: string
    userEmail: string
    documentNumber: number
    onboardingLS: Function
}
declare global {
    namespace JSX {
        interface IntrinsicElements {
            "mati-button": {
                style: Object
                id: string
                name: string
                clientid: string
                flowId: string
                metadata: string
            }
        }
    }
}

const CheckIdentity: React.FC<RegisterProps> = (
    {
        nextStep,
        personName,
        personLastName,
        address,
        userPhoneCountryCode,
        userPhone,
        nit,
        profession,
        userEmail,
        documentNumber,
        onboardingLS
    }) => {

    const [user, setUser]: any = useState("")
    const [authToken, setAuthToken]: any = useState("");
    const [loadingSpinner, setLoadingSpinner] = useState(false)
    const [userNewWarning, setUserNewWarning] = useState(<p></p>)

    useEffect(() => {
        onboardingLS()
        const getToken = async () => {

            setTimeout(function () {

                setAuthToken(JSON.parse(localStorage.getItem("user-info") || '{}'))
                setUser(JSON.parse(localStorage.getItem("login-data") || '{}'))

            }, 1000);

        }
        getToken();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const callMati = () => {
        document.getElementById("boton_mati")!.click();
        const button = document.getElementById("boton_mati");
        // setup callbacks
        // Message when a flow starts
        button!.addEventListener('mati:loaded', () => {
            console.log("flow starts")
        })
        // Call to receive Identity after finishing the upload oin the SDK
        button!.addEventListener('mati:userFinishedSdk', () => {
            nextStep()
        })
        // Call to receive message of flow not finished
        button!.addEventListener('mati:exitedSdk', () => {
            console.log("flow finished")
            setLoadingSpinner(false)
        })
    }

    const kycFlow = () => {

        setLoadingSpinner(true)

        axios.post(endpointConfig.userNew, {
            "first_name": personName,
            "last_name": personLastName,
            "address": address,
            "phone_country_code": userPhoneCountryCode,
            "phone_number": userPhone.replaceAll(' ', ''),
            "nit": nit,
            "profession": profession,
            "email": userEmail,
            "document_number": documentNumber,
        }, {
            headers: {
                Authorization: `Bearer ${authToken["id_token"]}`,
            }
        }).then(response => {

            if (response.status === 200) {
                callMati()
                localStorage.removeItem("onboard")
            }

            else {
                setUserNewWarning(
                    <p style={{ color: "red" }}>
                        Hubo un error con la solicitud, por favor verificar que la información personal sea la correcta.
                    </p>)
            }

        }).catch(err => {

            if (err.response && err.response.data.error === "We already have this users data") {
                callMati()
                localStorage.removeItem("onboard")

            } else {
                setUserNewWarning(
                    <p style={{ color: "red" }}>
                        Hubo un error con la solicitud, por favor verificar que la información personal sea la correcta.
                    </p>)

                notifyHB("request-signature", "CheckIdentity", err)

            }

        }).finally(() => {
            setLoadingSpinner(false)
        })

    }

    return (
        <div>
            <h4>Comprueba tu identidad</h4>
            <div className="cyber-spacer small"></div>
            <p>Para comprobar tu identidad, necesitaremos tu <b>celular</b> para grabar unas selfies y compartir fotos de tu <b>DPI</b>.</p>
            {userNewWarning}
            {loadingSpinner
                ? <div className="centerSpinner"><Spinner /></div>
                : <Link to="#" className="cyber-button full" onClick={kycFlow}>Continuar</Link>
            }

            {loadingSpinner}
            <mati-button
                style={{ 'display': 'none' }}
                id="boton_mati"
                name="mati-btn"
                clientid={process.env.REACT_APP_CLIENT_ID || ""}
                flowId={process.env.REACT_APP_FLOW_ID || ""}
                metadata={`{"StepFunction":"${ulid()}","Cognito_User":"${user["username"]}","CertificateType":"AGUILA1"}`}
            />
        </div>
    )
}

export default CheckIdentity
