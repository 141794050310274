import React from 'react'
import SuspendComponent from './SuspendComponent'
import RevokeComponent from './RevokeComponent'
import RenewComponent from './RenewComponent'

interface ModalOptionsProps {
    modalHasBeenRenewed: boolean
    setComponentState: Function
    setConfirmStateNumber: Function
    setConfirmStateName: Function
}

const ModalOptions: React.FC<ModalOptionsProps> = ({ modalHasBeenRenewed, setComponentState, setConfirmStateNumber, setConfirmStateName }) => {
    return (
        <div>
            {modalHasBeenRenewed
                ?
                null
                :
                <RenewComponent
                    setConfirmStateName={setConfirmStateName}
                    setConfirmStateNumber={setConfirmStateNumber}
                    setComponentState={setComponentState}
                />
            }

            <SuspendComponent
                setConfirmStateName={setConfirmStateName}
                setConfirmStateNumber={setConfirmStateNumber}
                setComponentState={setComponentState}
            />

            <RevokeComponent
                setConfirmStateName={setConfirmStateName}
                setConfirmStateNumber={setConfirmStateNumber}
                setComponentState={setComponentState}
            />

        </div>
    )
}

export default ModalOptions
