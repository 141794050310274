import React from 'react'
import ComponentContainer from '../../components/component-container/ComponentContainer'

import "./ResetOtp.css"
import VerifyEmail from './VerifyEmail'

interface ResetOtpProps {
    refreshToken: Function
}

const ResetOtp: React.FC<ResetOtpProps> = ({refreshToken}) => {

    refreshToken()

    return (
        <ComponentContainer>
            <h1>Restablecer OTP</h1>
            <div>
                <div className="accordion">

                    <div className="item">
                        <div className="title">
                            <h4>Paso 1: Verificar de correo electrónico</h4>
                        </div>
                        <div className="content show">
                            <VerifyEmail />
                        </div>
                    </div>

                    <div className="item">
                        <div className="title">
                            <h4>Paso 2: Verificar número de teléfono</h4>
                        </div>
                        <div className="content">

                        </div>
                    </div>

                </div>
            </div>
        </ComponentContainer>
    )
}

export default ResetOtp
