import React from 'react'

const ProfileContentView: React.FC = (props) => {
    return (
        <div className="cyber-main light-background with-sidenav">
            <div className="cyber-spacer section"></div>
            <div className="cyber-container">
                <h1 className="cyber-profile__title-mobile">Perfil</h1>
                <div className="cyber-spacer small"></div>
                <div className="cyber-profile">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default ProfileContentView
