import React, { useEffect } from 'react'

// icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import cssConfig from '../../../config/cssConfig';
import { Link, useHistory } from 'react-router-dom';
import routeConfig from '../../../config/routeConfig';

interface RegisterProps {
    componentState: number
    setComponentState: Function
}

const RegisterHeaderComponent: React.FC<RegisterProps> = ({ componentState, setComponentState }) => {

    useEffect(() => {
        cssConfig.setHeaderTranspartent()
        window.scrollTo(0, 0)
    }, [componentState])

    let history = useHistory();

    const returnStep = () => {
        if (componentState === 8) {
            history.push(routeConfig.dashboard)
        } else {
            setComponentState(componentState - 1)
        }
        
    }

    window!.onscroll = function () { cssConfig.handleHeaderState() };

    let linkClassName
    let lastClassName

    if (componentState <= 3){
        linkClassName = "cyber-steps__item"
    } else if (componentState === 4) {
        linkClassName = "cyber-steps__item active"
    } else {
        linkClassName = "cyber-steps__item done"
    }

    if (componentState === 8) {
        lastClassName = "cyber-steps__item done"
    } else if (componentState < 5) {
        lastClassName = "cyber-steps__item"
    } else {
        lastClassName = "cyber-steps__item active"
    }

    return (
        <div id="header" className="cyber-header">
            <div className="cyber-container flex">
                <div className="cyber-header__column-left">
                    <Link to="#" onClick={returnStep}>
                        <ArrowBackIcon />
                    </Link>
                </div>
                <div className="cyber-header__column-center">
                    <div className="cyber-steps">
                        <Link to="#" className={componentState <= 3 ? "cyber-steps__item active" : "cyber-steps__item done"} >
                            <div className="cyber-steps__item-icon">
                                <DescriptionIcon style={{ fill: "white" }} />
                            </div>
                            <span className="cyber-steps__item-label">Información personal</span>
                        </Link>
                        <Link to="#" className={linkClassName} >
                            <div className="cyber-steps__item-icon">
                                <PersonIcon style={componentState <= 3 ? { fill: "#91939d" } : { fill: "white" }} />
                            </div>
                            <span className="cyber-steps__item-label">Comprueba tu identidad</span>
                        </Link>
                        <Link to="#" className={lastClassName} >
                            <div className="cyber-steps__item-icon">
                                <LockIcon style={componentState < 5 ? { fill: "#91939d" } : { fill: "white" }} />
                            </div>
                            <span className="cyber-steps__item-label">Autenticación a 2 pasos</span>
                        </Link>
                        <Link to="#" className={componentState < 8 ? "cyber-steps__item" : "cyber-steps__item active"} >
                            <div className="cyber-steps__item-icon">
                                <DoneAllIcon style={componentState < 5 ? { fill: "#91939d" } : { fill: "white" }} />
                            </div>
                            <span className="cyber-steps__item-label">Confirmar firma</span>
                        </Link>
                    </div>
                </div>
                <div className="cyber-header__column-right"></div>
            </div>
        </div>
    )
}

export default RegisterHeaderComponent
