import apiConfig from "./apiConfig"

const endpointConfig = {
    process: `${apiConfig.baseUrl}/process`,
    certificate: `${apiConfig.baseUrl}/certificate`,
    user: `${apiConfig.baseUrl}/user`,
    docsDownload: `${apiConfig.baseUrl}/docs/download`,
    certcheck: `${apiConfig.baseUrl}/certcheck`,
    docsUpload: `${apiConfig.baseUrl}/docs/upload`,
    onboard: `${apiConfig.baseUrl}/onboard`,
    customName: `${apiConfig.baseUrl}/customname`,
    otp: `${apiConfig.baseUrl}/otp`,
    otpVerify: `${apiConfig.baseUrl}/otp/verify`,
    userUpdate: `${apiConfig.baseUrl}/user/update`,
    userNew: `${apiConfig.baseUrl}/user/new`,
    otpReset: `${apiConfig.baseUrl}/otp/reset`,
    signPdf: `${apiConfig.baseUrl}/signpdf`,
    docsVerify: `${apiConfig.baseUrl}/docs/verify`,
    userSupport: `${apiConfig.baseUrl}/user/support`,
    personInformation: `${apiConfig.baseUrl}/user/information`,
}

export default endpointConfig