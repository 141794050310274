import React from 'react'

import imgPreloader from "../../images/img_preloader.svg"


function SmallSpinner() {
    return (
        <div className="cyber-alert__image-container">
            <img style={{width: "40px"}} className="cyber-preloader" src={imgPreloader} alt="" />
        </div>
    );
}

export default SmallSpinner
