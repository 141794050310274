import axios from 'axios'
import React, { useState } from 'react'
import SmallSpinner from '../../components/spinner/SmallSpinner';

import endpointConfig from '../../config/endpointConfig';

function VerifyEmail() {

    const [emailSent, setemailSent] = useState("")
    const [displayButton, setDisplayButton] = useState(true)
    const [displaySpinner, setDisplaySpinner] = useState(false)

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    // reset otp
    const resetOtp = async () => {

        setDisplayButton(false)
        setDisplaySpinner(true)

        const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');
        const authToken: string = getToken['id_token'];
        const resetResponse = await axios.post(
            endpointConfig.otpReset, {}, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
        );


        if (resetResponse.status === 200){
            setemailSent("Correo enviado")
            setDisplaySpinner(false)
        }

        else {
            setDisplayButton(true)
            setDisplaySpinner(false)
            alert("Hubo un error al enviar el correo")
        }


    }

    return (
        <div>
            <br/>
            Enviar correo para recuperación de OTP
            <br/>
            <br/>
                {displaySpinner ? <SmallSpinner/> : null}
                {displayButton ? <button className="cyber-button" onClick={resetOtp}>Enviar</button>: null}
            <p>{emailSent}</p>
        </div>
    )
}

export default VerifyEmail
