import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useCookies } from 'react-cookie';


function LogoutButton() {

    // cookies
    const [, , removeLoginData] = useCookies(["loginData"]);
    const [, , removeExpDate] = useCookies(["tokenExpirDate"]);
    const [, , removeUserInfo] = useCookies(["userInfo"]);
    const [, , removeUserName] = useCookies(["userName"]);
    const [, , removeSignatureP] = useCookies(["signatureP"])

    let history = useHistory();

    const handleLogout = () => {
        localStorage.clear()
        removeLoginData("loginData", {path: "/"})
        removeExpDate("tokenExpirDate", {path: "/"})
        removeUserInfo("userInfo", {path: "/"})
        removeUserName("userName", {path: "/"})
        removeSignatureP("signatureP", {path: "/"})
        history.push("/")
        history.go(0)
    }

    return (
        <Link className="cyber-user-dropdown__item" to="#" onClick={handleLogout} >
            <div className="cyber-user-dropdown__item-icon">
                <ExitToAppIcon />
            </div>
            Cerrar sesión
        </Link>
    )
}

export default LogoutButton
