import React, { useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

// icons
import CloseIcon from '@material-ui/icons/Close';
import CyberCheckbox from './components/CyberCheckbox';
import axios from 'axios';
import { Link } from 'react-router-dom';
import endpointConfig from '../../config/endpointConfig';
import notifyHB from '../../config/notifyHB';
import imgSignLogo from "../../images/logo_cybersign--black.svg"

interface DocumentSignatureProps {
    nextStep: Function
    returnStep: Function
    pdfName: string
    certSerial: string
    setCertSerial: Function
    customName: string
    setCustomName: Function
    documentPages: number
    signaturePage: number
    setSignaturePage: Function
    hasDate: boolean
    setHasDate: Function
    hasInitials: boolean
    setHasInitials: Function
    hasMotive: boolean
    setHasMotive: Function
    hasSignature: boolean
    setHasSignature: Function
    motiveOfSignature: string
    setMotiveOfSignature: Function
    getFileToUpload: any
    setPdfRelativePositionWidth: Function
    setPdfRelativePositionHeight: Function
}

const DocumentSignature: React.FC<DocumentSignatureProps> = ({
    nextStep,
    returnStep,
    pdfName,
    certSerial,
    setCertSerial,
    customName,
    setCustomName,
    documentPages,
    signaturePage,
    setSignaturePage,
    hasDate,
    setHasDate,
    hasInitials,
    setHasInitials,
    hasMotive,
    setHasMotive,
    hasSignature,
    setHasSignature,
    motiveOfSignature,
    setMotiveOfSignature,
    getFileToUpload,
    setPdfRelativePositionWidth,
    setPdfRelativePositionHeight
}) => {

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    // useState
    const [certsTable, setCertsTable] = useState<any>([]);
    const [signatureError, setSignatureError] = useState(false)
    const [motiveLength, setMotiveLength] = useState(0)
    const [motiveLengthError, setMotiveLengthError] = useState(false)
    const [previousCanvasPage, setPreviousCanvasPage] = useState<any>(null)
    const [originalCanvas, setOriginalCanvas] = useState<any>({})
    const [previousContext, setPreviousContext] = useState<any>(null)
    const [signLocationSetted, setSignLocationSetted] = useState(false)
    const [signatureLocationError, setSignatureLocationError] = useState(false)

    // obtener informacion de localStorage
    const getFromLocalStorage = (itemName: string) => {
        return JSON.parse(localStorage.getItem(itemName)!)
    }

    const allowNextStep = () => {
        setSignatureError(false)
        setSignatureLocationError(false)
        setMotiveLengthError(false)
        let error = false

        if (certSerial === "" || certSerial === "0") {
            setSignatureError(true)
            error = true
        }

        if (!signLocationSetted) {
            setSignatureLocationError(true)
            error = true
        }

        if (hasMotive && motiveLength > 100) {
            setMotiveLengthError(true)
            error = true
        }

        if (!error) {
            nextStep()
        }

    }

    useEffect(() => {

        // obtener certificados para el select
        const handleCertsTable = async () => {

            const getTokenUserInfo: UserInfo = getFromLocalStorage("user-info");
            const authTokenId: string = getTokenUserInfo['id_token']!;

            try {
                const certsResponse = await axios.post(
                    endpointConfig.certificate, {}, {
                    headers: {
                        Authorization: `Bearer ${authTokenId}`
                    }
                }
                )

                const tableData: object[] = []

                certsResponse.data.credentialsDescription.forEach((element: any) => {
                    if (element.certificateStatus === "Active") {
                        tableData.push(element)
                    }
                });

                setCertsTable(tableData)

            } catch (e) {
                alert('Error al obtener certificados')
                notifyHB("upload-document", "Documentsignature", e)
            }

        }

        handleCertsTable()
    }, [])

    const getTextAreaValue = (event: any) => {
        setMotiveLength(event.length)
        setMotiveOfSignature(event)
    }

    // This function set the relative dummy sign coordinates and paint it on the canvas
    // reseting the canvas each time to the original state to paint the sign again
    const onPress = (e: any, pageIndex: number) => {
        if (e.target.nodeName === "CANVAS") {
            setPdfRelativePositionWidth(e.nativeEvent.layerX / e.nativeEvent.target.clientWidth)
            setPdfRelativePositionHeight(e.nativeEvent.layerY / e.nativeEvent.target.clientHeight)

            let canvas = document.getElementsByTagName('canvas');
            const context = canvas[pageIndex].getContext('2d');

            let canvasCopy = context?.getImageData(0, 0, e.nativeEvent.target.width, e.nativeEvent.target.height)

            if (pageIndex == previousCanvasPage) {
                context?.clearRect(0, 0, e.nativeEvent.target.width, e.nativeEvent.target.height)

                // @ts-ignore
                context?.putImageData(originalCanvas, 0, 0)

            } else {
                // Clear previous page
                if (previousContext) {
                    previousContext?.clearRect(0, 0, e.nativeEvent.target.width, e.nativeEvent.target.height)

                    // @ts-ignore
                    previousContext?.putImageData(originalCanvas, 0, 0)
                }
                // Set new page
                setPreviousContext(context)
                setOriginalCanvas(canvasCopy)
                setPreviousCanvasPage(pageIndex)
                setSignaturePage(Number(pageIndex))
            }

            const image = new Image();
            image.src = imgSignLogo;
            image.onload = () => {
                context!.drawImage(
                    image,
                    e.nativeEvent.layerX / e.nativeEvent.target.clientWidth * e.nativeEvent.target.width,
                    e.nativeEvent.layerY / e.nativeEvent.target.clientHeight * e.nativeEvent.target.height,
                    e.nativeEvent.target.width / 6.25,
                    e.nativeEvent.target.height / 6.25 * 0.24581
                );
            };

            setSignLocationSetted(true)
        }
    }

    return (
        <div>
            <div className="cyber-signature-options">
                <div className="cyber-signature-options__col">
                    <div className="cyber-card default">
                        <span>Posición de la firma dentro de la hoja</span>
                        <div className="cyber-spacer"></div>
                        <Document className="PDFDocument" file={ getFileToUpload }>
                            {
                                Array(documentPages).fill(0).map((_, i) => {
                                    return <Page className="PDFPage PDFPageOne" pageNumber={ i + 1 } onClick={(event) => onPress(event, i)} />
                                })
                            }
                        </Document>
                    </div>
                    <div className="cyber-spacer small"></div>
                </div>

                <div className="cyber-signature-options__col">
                    <div>
                        <div className="cyber-document-to-sign cyber-card">
                            <div className="cyber-document-to-sign__info">
                                <span>Documento a firmar:</span>
                                <span className="cyber-document-to-sign__filename">{pdfName}</span>
                            </div>
                            <Link to="#" className="cyber-fab" onClick={() => returnStep()}>
                                <i className="material-icons"> <CloseIcon /> </i>
                            </Link>
                        </div>
                        <div className="cyber-spacer small"></div>
                        <div className="cyber-input">
                            {signatureError ? <label style={{ color: "red" }}>Por favor seleccionar una firma</label> : null}
                            <label className="cyber-input__label">Seleccionar firma:</label>
                            <select className="cyber-input__textfield" id="certsId" onChange={(event) => setCertSerial(event.target.value)}>
                                <option value={certSerial}>{customName}</option>
                                {certsTable.map((certInfo: any, index: number) => {
                                    if (certInfo.serial === certSerial) {
                                        setCustomName(certInfo.customName)
                                        return null
                                    } else {
                                        return (
                                            <option key={index} value={certInfo.serial}>{certInfo.customName}</option>
                                        )
                                    }

                                })}
                            </select>
                        </div>

                        <div className="cyber-spacer small"></div>

                        <div className="cyber-input">
                            <label className="cyber-input__label">Componentes de la firma:</label>
                            <div className="cyber-spacer"></div>
                            <CyberCheckbox
                                checkboxLabel="Firma manuscrita"
                                currentValue={hasSignature}
                                setCheckedValue={setHasSignature}
                            />
                            <div className="cyber-spacer"></div>
                            <CyberCheckbox
                                checkboxLabel="Nombre completo"
                                currentValue={hasInitials}
                                setCheckedValue={setHasInitials}
                            />
                            <div className="cyber-spacer"></div>
                            <CyberCheckbox
                                checkboxLabel="Fecha"
                                currentValue={hasDate}
                                setCheckedValue={setHasDate}
                            />
                            <div className="cyber-spacer"></div>
                            <CyberCheckbox
                                checkboxLabel="Razón"
                                currentValue={hasMotive}
                                setCheckedValue={setHasMotive}
                            />
                            <div className="cyber-spacer small"></div>
                            <div className="cyber-input">
                                <label className="cyber-input__label">Razón {motiveLength}/100</label> {motiveLengthError ? <label style={{ color: "red" }}>La razón excede el número de caracteres</label> : null}
                                <textarea className="cyber-input__textfield" onChange={event => getTextAreaValue(event.target.value)}>{motiveOfSignature}</textarea>
                            </div>
                        </div>
                        <div className="cyber-spacer small"></div>
                        <div className="justify-end">
                            <button className="cyber-button" onClick={() => allowNextStep()} >Firmar documento</button>
                        </div>
                        <div className="cyber-spacer small"></div>
                    </div>
                    <div className="cyber-spacer small"></div>
                </div>

            </div>

        </div>
    )
}

export default DocumentSignature
