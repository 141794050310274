import React, { useState } from 'react'
import QRCode from 'qrcode.react'
import "./DisplayQR.css"
import { Link } from 'react-router-dom'


interface OtpComponentProps {
    otpDataMessage: string
    secret: string
}

const DisplayQR: React.FC<OtpComponentProps> = ({ otpDataMessage, secret }) => {

    const [displayNotification, setDisplayNotification] = useState(false)

    return (
        <div className="centerLoading">

            <h4>Escanea el código QR con Google o Microsoft Authenticator</h4>
            <div className="cyber-spacer small"></div>

            <QRCode value={otpDataMessage} size={200} />
            <Link to="#" onClick={() => { 
                navigator.clipboard.writeText(secret) 
                setDisplayNotification(true)
            }}>o continuar de forma manual copiando este código</Link>
            {displayNotification ? <p>Se ha copiado en el portapapeles</p> : null}

        </div>
    )
}

export default DisplayQR
