import React from 'react'
import { Link } from 'react-router-dom'
import WarningIcon from '@material-ui/icons/Warning'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

interface SuspendCProps {
    setConfirmStateName: Function
    setConfirmStateNumber: Function
    setComponentState: Function
}

const SuspendComponent: React.FC<SuspendCProps> = ({ setConfirmStateName, setConfirmStateNumber, setComponentState }) => {
    return (
        <div>
            <Link to="#" className="cyber-list__item error" onClick={() => {
                setConfirmStateName("suspender")
                setConfirmStateNumber(2)
                setComponentState(7)
            }}>
                <div className="cyber-list__item-icon-left">
                    <WarningIcon />
                </div>
                <div className="cyber-list__item-content">
                    <b>Suspender</b>
                    <span>Suspende esta firma temporalmente.</span>
                </div>
                <ChevronRightIcon className="cyber-list__item-icon-right material-icons" />
            </Link>
        </div>
    )
}

export default SuspendComponent
