import React from 'react'
import { Link } from 'react-router-dom'

// icons
import { RiUpload2Fill } from 'react-icons/ri';
import { MdOutlineDraw  } from 'react-icons/md'
import DoneAllIcon from '@material-ui/icons/DoneAll';

interface StepsProps {
    componentState: number
    setComponentState: Function
}

const CustomSignatureSteps: React.FC<StepsProps> = ({ componentState, setComponentState }) => {

    let uploadIconColor
    let uploadClassName

    let signIconColor
    let signClassName

    let downloadClassName

    if (componentState === 0){
        uploadIconColor = "white"
        uploadClassName = "cyber-steps__item active"

        signIconColor = "gray"
        signClassName = "cyber-steps__item"

        downloadClassName = "cyber-steps__item"
    }

    if (componentState > 0 && componentState < 2){
        uploadIconColor = "white"
        uploadClassName = "cyber-steps__item done"

        signIconColor = "white"
        signClassName = "cyber-steps__item active"

        downloadClassName = "cyber-steps__item"
    }

    if (componentState === 2){
        uploadIconColor = "white"
        uploadClassName = "cyber-steps__item done"

        signIconColor = "white"
        signClassName = "cyber-steps__item done"

        downloadClassName = "cyber-steps__item active"
    }

    return (
        <div>
            <div className="cyber-steps">
                <Link className={uploadClassName} to="#">
                    <div className="cyber-steps__item-icon">
                        <MdOutlineDraw color={uploadIconColor} size={20} />
                    </div>
                    <span className="cyber-steps__item-label">Dibujar</span>
                </Link>
                <Link className={signClassName} to="#">
                    <div className="cyber-steps__item-icon">
                        <RiUpload2Fill color={signIconColor} size={20} />
                    </div>
                    <span className="cyber-steps__item-label">Subir</span>
                </Link>
                <Link className={downloadClassName} to="#">
                    <div className="cyber-steps__item-icon">
                        <DoneAllIcon style={componentState < 2 ? { fill: "#91939d" } : { fill: "white" }} />
                    </div>
                    <span className="cyber-steps__item-label">Confirmar Firma</span>
                </Link>
            </div>
            <div className="cyber-spacer medium"></div>
        </div>
    )
}

export default CustomSignatureSteps
