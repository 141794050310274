import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


// css
import "./MySignatures.css"

// icons
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import SignatureRow from './SignatureRow';
import EditSignature from './EditSignature';
import { useCookies } from 'react-cookie';
import routeConfig from '../../config/routeConfig';
import { useHistory } from 'react-router-dom';
import Spinner from '../../components/spinner/spinner';
import endpointConfig from '../../config/endpointConfig';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'fixed',
            inset: 0,
            display: 'grid',
            placeItems: 'center',
            overflow: 'auto',
            padding: '24px',
            zIndex: 100
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: "8px"
        },
    }),
);

interface MySignaturesProps {
    refreshToken: Function
}

const MySignatures: React.FC<MySignaturesProps> = ({ refreshToken }) => {

    refreshToken()

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const closeSidenav = () => {
        document.querySelector('.cyber-sidenav')!.classList.remove('visible');
    }

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    type LoginData = {
        sub: string;
        email_verified: string;
        email: string;
        username: string;
    }

    type SignatureStatus = {
        [userCertV1: string]: string;
        legalRepV1: string;
    }

    // useStates
    const [getCerts, setGetCerts] = useState(false);

    // cookies
    const [userInfoC,] = useCookies(["userInfo"])

    // useHistory
    const history = useHistory();

    let tableData = [{
        "num": "",
        "CertName": "",
        "CertType": "",
        "CertificateStatus": "",
        "ExpirationDate": "",
        "Serial": "",
        "HasBeenRenewed": false
    }]

    useEffect(() => {
        if (getCerts === false) {

            if (userInfoC.userInfo === null) {
                console.log("reload please");
            } else {
                getTokenAndData();
            }

            setGetCerts(true);
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // useStates
    const [userCerts, setUserCerts] = useState(tableData)
    const [modalName, setModalName] = useState("")
    const [modalType, setModalType] = useState("")
    const [modalSerial, setModalSerial] = useState("")
    const [modalExpDate, setModalExpDate] = useState("")
    const [modalHasBeenRenewed, setModalHasBeenRenewed] = useState(false)
    const [modalCertificateStatus, setModalCertificateStatus] = useState("")
    const [searchCert, setSearchCert] = useState("")
    const [waitingTable, setWaitingTable] = useState(<div className='centerSpinner'>
        <Spinner />
    </div>)
    const [displayData, setDisplayData] = useState(false)

    const getTokenAndData = async () => {
        const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');
        const authToken: string = getToken['id_token'];

        let resp = await axios.post(
            endpointConfig.certificate, {}, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
        );

        const newData = [] as any

        for (var certEntry of resp.data.credentialsDescription) {
            const rowData = {
                "num": resp.data.credentialsDescription.indexOf(certEntry) + 1,
                "CertName": certEntry.customName,
                "CertType": certEntry.certType,
                "CertificateStatus": certEntry.certificateStatus,
                "ExpirationDate": certEntry.expirationDate,
                "Serial": certEntry.serial,
                "HasBeenRenewed": certEntry.hasBeenRenewed
            }
            newData.push(rowData)
        }

        if (newData[0] === undefined) {
            userCerts[0] = {
                "num": "",
                "CertName": "",
                "CertType": "",
                "CertificateStatus": "",
                "ExpirationDate": "",
                "Serial": "",
                "HasBeenRenewed": false
            }
            setWaitingTable(<div></div>)

        } else {
            setUserCerts(newData)
            setDisplayData(true)
        }


    }

    const handleDownload = (certType: string, certSerial: string) => async () => {
        let loginData: LoginData = JSON.parse(localStorage.getItem("login-data") || '{}');
        const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');
        let req_object: string = loginData['sub'] + "/" + certType + "/" + certSerial + ".crt";

        const downloadLink = await axios.post(
            endpointConfig.docsDownload,
            {
                "certificate": true,
                "req_obj": req_object
            },
            {
                headers: {
                    Authorization: `Bearer ${getToken['id_token']}`
                }
            }
        )

        window.open(downloadLink['data']['body'], '_blank')
    }

    const getSignatureType = (certStatus: string) => {

        const statuses: SignatureStatus = {
            "userCertV1": "Personal",
            "legalRepV1": "Rep. Legal",
        }

        if (statuses.hasOwnProperty(certStatus)) {
            return statuses[certStatus];
        } else {
            return certStatus;
        }

    }


    return (

        <div>

            <div className="cyber-backdrop"></div>

            <div className="cyber-main with-sidenav" onClick={closeSidenav}>
                <div className="cyber-spacer section"></div>
                <div className="cyber-container">
                    <h1>Mis Firmas</h1>
                    <div className="cyber-spacer small"></div>
                    <div className="cyber-spacer small"></div>
                    <div className="cyber-flex align-center">
                        <div className="cyber-block col7">
                            <h3>Firmas activas</h3>
                        </div>
                        <div className="cyber-block col5 justify-end">
                            <button
                                className="cyber-button icon-left hide-label-on-mobile"
                                onClick={() => history.push(routeConfig.requestSignature)}
                            >
                                <AddIcon className="icon-button" />
                                <span className="cyber-button__label">Registrar nueva firma</span>
                            </button>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className={classes.modal}
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={open}>
                                    <div className={classes.paper}>
                                        <EditSignature
                                            modalName={modalName}
                                            modalType={modalType}
                                            modalSerial={modalSerial}
                                            modalExpDate={modalExpDate}
                                            modalHasBeenRenewed={modalHasBeenRenewed}
                                            modalCertificateStatus={modalCertificateStatus}
                                            handleClose={handleClose}
                                        />
                                    </div>
                                </Fade>
                            </Modal>
                        </div>
                    </div>

                    <div className="cyber-spacer medium"></div>

                    <div className="cyber-input">
                        <input
                            type="text"
                            className="cyber-input__textfield"
                            placeholder="Buscar"
                            onChange={event => { setSearchCert(event.target.value) }}
                        />
                        <SearchIcon className="cyber-input__icon-right " />
                    </div>

                    <div className="cyber-spacer small"></div>

                    {
                        displayData
                            ?
                            <div className="cyber-table">
                                <div className="cyber-table__th">
                                    <div className="cyber-table__td col2">
                                        <span>Tipo</span>
                                    </div>
                                    <div className="cyber-table__td col6">
                                        <span>Nombre</span>
                                    </div>
                                    <div className="cyber-table__td col2">
                                        <span>Fecha de expiración</span>
                                    </div>
                                    <div className="cyber-table__td col2 text-right">
                                        <span>Acciones</span>
                                    </div>
                                </div>



                                {
                                    userCerts.filter((certInfo) => {
                                        if (searchCert === "" || certInfo.CertName.toLocaleLowerCase().includes(searchCert.toLocaleLowerCase())) {
                                            return certInfo
                                        } else {
                                            return null
                                        }
                                    }).map((certInfo) => {

                                        return (
                                            <SignatureRow
                                                key={certInfo.Serial}
                                                CertName={certInfo.CertName}
                                                CertType={getSignatureType(certInfo.CertType)}
                                                CertificateStatus={certInfo.CertificateStatus}
                                                ExpirationDate={certInfo.ExpirationDate}
                                                Serial={certInfo.Serial}
                                                HasBeenRenewed={certInfo.HasBeenRenewed}
                                                download={() => handleDownload(certInfo.CertType, certInfo.Serial)}
                                                handleOpen={handleOpen}
                                                setModalName={setModalName}
                                                setModalType={setModalType}
                                                setModalSerial={setModalSerial}
                                                setModalExpDate={setModalExpDate}
                                                setModalHasBeenRenewed={setModalHasBeenRenewed}
                                                setModalCertificateStatus={setModalCertificateStatus}
                                            />
                                        )

                                    })
                                }

                            </div>
                            :
                            waitingTable
                    }



                </div>
                <div className="cyber-spacer section"></div>
            </div>

        </div>

    )
}

export default MySignatures
