import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import CheckboxInput from './components/CheckboxInput'
import EditIcon from '@material-ui/icons/Edit';

interface FormSummaryProps {
    nextStep: Function
    personName: string
    personLastName: string
    address: string
    userPhoneCountryCode: string
    userPhone: string
    nit: string
    profession: string
    userEmail: string
    documentNumber: number
    setComponentState: Function
}

const FormSummary: React.FC<FormSummaryProps> = ({
    nextStep,
    personName,
    personLastName,
    address,
    userPhoneCountryCode,
    userPhone,
    nit,
    profession,
    userEmail,
    documentNumber,
    setComponentState
}) => {

    const [checkBoxValue, setCheckBoxValue] = useState(false)
    const [fieldsWarning, setFieldsWarning] = useState(<p></p>)

    const confirmStep = () => {
        
        if (checkBoxValue){
            nextStep()
        } else {
            setFieldsWarning(<p style={{color: "red"}}>Por favor declare que los datos son correctos</p>)
        }  
        
    }
    
    return (
        <div>
            <h3>Resumen del formulario</h3>

            <table id="myTable" style={{ width: "auto" }}>
                <tbody>
                    <tr>
                        <th scope='row'>Nombre</th>
                        <td>{personName}</td>
                        <td><EditIcon className='centerEditIcon' onClick={() => setComponentState(1)} /></td>
                    </tr>

                    <tr>
                        <th scope='row'>Apellidos</th>
                        <td>{personLastName}</td>
                        <td><EditIcon className='centerEditIcon' onClick={() => setComponentState(1)} /></td>
                    </tr>

                    <tr>
                        <th scope='row'>Dirección</th>
                        <td>{address}</td>
                        <td><EditIcon className='centerEditIcon' onClick={() => setComponentState(1)} /></td>
                    </tr>

                    {/* <tr>
                        <th scope='row'>Profesión</th>
                        <td>{profession}</td>
                        <td><EditIcon onClick={() => setComponentState(1)} /></td>
                    </tr> */}

                    <tr>
                        <th scope='row'>Número de Documento de Identificación Personal (DPI)</th>
                        <td>{documentNumber}</td>
                        <td><EditIcon className='centerEditIcon' onClick={() => setComponentState(2)} /></td>
                    </tr>

                    <tr>
                        <th scope='row'>Nit</th>
                        <td>{nit}</td>
                        <td><EditIcon className='centerEditIcon' onClick={() => setComponentState(2)} /></td>
                    </tr>

                    <tr>
                        <th scope='row'>Teléfono</th>
                        <td>+{userPhoneCountryCode} {userPhone}</td>
                        <td><EditIcon className='centerEditIcon' onClick={() => setComponentState(3)} /></td>
                    </tr>

                    <tr>
                        <th scope='row'>Correo</th>
                        <td>{userEmail}</td>
                    </tr>

                </tbody>
            </table>

            <CheckboxInput setCheckBoxValue={setCheckBoxValue} />
            {fieldsWarning}
            <Link to="#" className="cyber-button full" onClick={() => {confirmStep()}}>Continuar</Link>

        </div>
    )
}

export default FormSummary
