import React from 'react'
import { Link } from 'react-router-dom'
import RevokeComponent from './RevokeComponent'
import UnsuspendComponent from './UnsuspendComponent'

interface ModalUnsuspendProps {
    setComponentState: Function
    setConfirmStateNumber: Function
    setConfirmStateName: Function
}

const ModalUnsuspendOption: React.FC<ModalUnsuspendProps> = ({ setComponentState, setConfirmStateNumber, setConfirmStateName }) => {
    return (
        <div>

            <Link to="#" className="cyber-list__item" style={{ cursor: "default" }} >
                <div className="cyber-list__item-content">
                    <b>Firma Suspendida</b>
                </div>
            </Link>


            <UnsuspendComponent
                setConfirmStateName={setConfirmStateName}
                setConfirmStateNumber={setConfirmStateNumber}
                setComponentState={setComponentState}
            />

            <RevokeComponent
                setConfirmStateName={setConfirmStateName}
                setConfirmStateNumber={setConfirmStateNumber}
                setComponentState={setComponentState}
            />

        </div>
    )
}

export default ModalUnsuspendOption
