import axios from "axios";
import { useCookies } from "react-cookie";
import qs from "qs";
import cognitoConfig from "../../config/cognito";
import { useHistory } from "react-router-dom";
import apiConfig from "../../config/apiConfig";



const refreshToken = {

    RefreshTokenFunction: () => {
        // cookies
        const [, setUserInfoC, removeUserInfo] = useCookies(["userInfo"])
        const [tokenExpirDateC, , removeExpDate] = useCookies(["tokenExpirDate"])
        const [, , removeLoginData] = useCookies(["loginData"]);
        const [, , removeUserName] = useCookies(["userName"]);
        const [, , removeSignatureP] = useCookies(["signatureP"])

        let history = useHistory();

        // 20 minutos antes de la fecha de expiracion
        let beforeExp = Number(tokenExpirDateC.tokenExpirDate) - 1000

        if (beforeExp < Date.now() / 1000) {

            try {
                const getToken = JSON.parse(localStorage.getItem("user-info")!)
                axios.post(`${apiConfig.baseUrl}/oauth2/token`,
                    qs.stringify({
                        grant_type: 'refresh_token',
                        client_id: cognitoConfig.cognitoClient,
                        refresh_token: getToken['refresh_token'],
                        scope: 'openid'
                    }), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(resp => {

                    setUserInfoC("userInfo", resp.data,
                        {
                            domain: process.env.REACT_APP_DOMAIN,
                            path: "/",
                            secure: true,
                            sameSite: "strict"
                        }
                    )
                    localStorage.setItem("user-info", JSON.stringify(resp.data))

                })
            }

            catch {
                localStorage.clear()
                removeLoginData("loginData", { path: "/" })
                removeExpDate("tokenExpirDate", { path: "/" })
                removeUserInfo("userInfo", { path: "/" })
                removeUserName("userName", { path: "/" })
                removeSignatureP("signatureP", { path: "/" })
                history.push("/")
                history.go(0)
            }

        }


    }
}

export default refreshToken;