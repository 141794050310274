import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';


interface SignatureRequirementsProps {
    handleClose: Function
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const SignatureRequirements: React.FC<SignatureRequirementsProps> = ({handleClose}) => {
    const [expandedDPI, setExpandedDPI] = useState(false)
    const [expandedGA, setExpandedGA] = useState(false)
    const [expanded3, setExpanded3] = useState(false)
    const [expanded4, setExpanded4] = useState(false)

    const handleExpandClickDPI = () => {
        setExpandedDPI(!expandedDPI);
    };

    const handleExpandClickGA = () => {
        setExpandedGA(!expandedGA);
    };

    const handleExpand3 = () => {
        setExpanded3(!expanded3);
    }
    
    const handleExpand4 = () => {
        setExpanded4(!expanded4);
    }

    return (
        <div >
            <div className="moveRight moveDown">
                <button onClick={() => handleClose()}>
                    <CloseIcon />
                </button>
            </div>

            <div >
                <h3>Firma electrónica</h3>
                <br />
                <Card sx={{ maxWidth: 345 }}>

                    {/* ACTIVAR FIRMA */}
                    <CardActions disableSpacing
                    >
                        <IconButton 
                            aria-label="add to favorites"
                            onClick={handleExpandClickDPI}
                        >
                            Solicitar Firma
                        </IconButton>

                        <ExpandMore
                            expand={expandedDPI}
                            onClick={handleExpandClickDPI}
                            aria-expanded={expandedDPI}
                            aria-label="show more"
                        >
                            <KeyboardArrowDownIcon />
                        </ExpandMore>

                    </CardActions>
                    <Collapse in={expandedDPI} timeout="auto" unmountOnExit>

                        <CardContent style={{ maxHeight: 500, overflow: 'auto' }}>
                            <Typography paragraph>
                                El proceso de solicitar firma requiere un documento de identificación válido y un teléfono con cámara y la aplicación Google o Microsoft Authenticator.
                                El app servirá para verificar tu identidad al momento de firmar documentos y realizar otras acciones que requieren de seguridad adicional.
                            </Typography>

                        </CardContent>
                    </Collapse>


                    {/* SUSPENDER FIRMA */}
                    <CardActions disableSpacing>
                        <IconButton 
                            aria-label="add to favorites"
                            onClick={handleExpandClickGA}
                        >
                            Suspender Firma
                        </IconButton>

                        <ExpandMore
                            expand={expandedGA}
                            onClick={handleExpandClickGA}
                            aria-expanded={expandedGA}
                            aria-label="show more"
                        >
                            <KeyboardArrowDownIcon />
                        </ExpandMore>

                    </CardActions>
                    <Collapse in={expandedGA} timeout="auto" unmountOnExit>

                        <CardContent style={{ maxHeight: 500, overflow: 'auto' }}>
                            <Typography paragraph>
                                Para el proceso de suspender firma, se requiere el codigo de Google o Microsoft Authenticator para verificar la identidad del usuario.
                                El proceso de suspensión de firma puede aplicarse y revertirse en cualquier momento.
                            </Typography>

                        </CardContent>
                    </Collapse>

                    
                    {/* RENOVAR FIRMA */}
                    <CardActions disableSpacing>
                        <IconButton 
                            aria-label="add to favorites"
                            onClick={handleExpand3}
                        >
                            Renovar Firma
                        </IconButton>

                        <ExpandMore
                            expand={expanded3}
                            onClick={handleExpand3}
                            aria-expanded={expanded3}
                            aria-label="show more"
                        >
                            <KeyboardArrowDownIcon />
                        </ExpandMore>

                    </CardActions>
                    <Collapse in={expanded3} timeout="auto" unmountOnExit>

                        <CardContent style={{ maxHeight: 500, overflow: 'auto' }}>
                            <Typography paragraph>
                                Para el proceso de renovar firma, se requiere el codigo de Google o Microsoft Authenticator para verificar la identidad del usuario.
                            </Typography>

                        </CardContent>
                    </Collapse>


                    {/* REVOCAR FIRMA */}
                    <CardActions disableSpacing>
                        <IconButton 
                            aria-label="add to favorites"
                            onClick={handleExpand4}
                        >
                            Revocar Firma
                        </IconButton>

                        <ExpandMore
                            expand={expanded4}
                            onClick={handleExpand4}
                            aria-expanded={expanded4}
                            aria-label="show more"
                        >
                            <KeyboardArrowDownIcon />
                        </ExpandMore>

                    </CardActions>
                    <Collapse in={expanded4} timeout="auto" unmountOnExit>

                        <CardContent style={{ maxHeight: 500, overflow: 'auto' }}>
                            <Typography paragraph>
                                Para el proceso de revocar firma, se requiere el codigo de Google o Microsoft Authenticator para verificar la identidad del usuario.
                                Una vez revocada una firma, esta ya no puede activarse de nuevo.
                            </Typography>

                        </CardContent>
                    </Collapse>


                </Card>
            </div>
        </div>
    );
}

export default SignatureRequirements