import React, { useState } from 'react'
import axios from 'axios';
import CertificateInformation from './CertificateInformation';

import "./CheckCertificates.css";
import ComponentContainer from '../../components/component-container/ComponentContainer';
import Spinner from '../../components/spinner/spinner';
import endpointConfig from '../../config/endpointConfig';
import notifyHB from '../../config/notifyHB';

interface CheckCertificatesProps {
    refreshToken: Function
}

const CheckCertificates: React.FC<CheckCertificatesProps> = ({ refreshToken }) => {

    type CheckResponse = {
        caSerial: string,
        certType: string,
        certificateStatus: string,
        createdAt: string,
        expirationDate: string,
        emailDeclared: string,
        serial: string,
        revocationTimeStamp: string,
        suscriberName: string
    }

    // useStates
    const [certificateSerial, setCertificateSerial] = useState("");
    const [serialError, setSerialError] = useState("")
    const [certResult, setCertResult] = useState(<div></div>)

    const isHex = (str: string) => /^[0-9A-Fa-f]+$/.test(str);
    const isDecimal = (str: string) => /^\d+$/.test(str);

    const hexToDecimal = (hex: string) => parseInt(hex, 16).toString();

    const validateAndFormatSerial = (serial: string): string | null => {
        const trimmedSerial = serial.replace(/\s/g, '');
        
        if (isDecimal(trimmedSerial)) {
            return trimmedSerial;
        } else if (isHex(trimmedSerial)) {
            return hexToDecimal(trimmedSerial);
        } else {
            return null;
        }
    }

    const certCheckRequest = async () => {
        const formattedSerial = validateAndFormatSerial(certificateSerial);

        if (formattedSerial) {
            setSerialError("");
            setCertResult(<Spinner/>);

            try {
                const checkResponse = await axios.post(
                    endpointConfig.certcheck,
                    {
                        "publicKeyHashHex": "21f9f2e15db6587d3b044ad9c0cc3415f25b6d16",
                        "certificate_serial": formattedSerial
                    }
                );
    
                let certificateInformation: CheckResponse = JSON.parse(checkResponse.data.certificateInformation)
                setCertResult(
                    <CertificateInformation
                        caSerial={certificateInformation["caSerial"]}
                        certType={certificateInformation["certType"]}
                        certificateStatus={returnCertificateStatus(certificateInformation["certificateStatus"])}
                        createdAt={certificateInformation["createdAt"]}
                        expirationDate={certificateInformation["expirationDate"]}
                        emailDeclared={certificateInformation["emailDeclared"]}
                        serial={certificateInformation["serial"]}
                        revocationTimeStamp={certificateInformation["revocationTimeStamp"]}
                        suscriberName={certificateInformation["suscriberName"]}
                    />
                )
            } catch (error: any) {
                setCertResult(<div></div>);
                alert("Hubo un error en la validación del documento");
                notifyHB("check-certificates", "CheckCertificates", error);
            }
        } else {
            setSerialError("Por favor, ingrese un número de serie válido (decimal o hexadecimal)");
        }
    }

    const returnCertificateStatus = (certStatus: string) => {
        const statuses: any = {
            "0": "unspecified",
            "1": "Key Compromise",
            "2": "cA Compromise",
            "3": "Affiliation Changed",
            "4": "Superseded",
            "5": "Cessation Of Operation",
            "6": "Suspended",
            "8": "Remove From CRL",
            "9": "privilegeWithdrawn",
            "10": "aACompromise"
        }

        if (statuses.hasOwnProperty(certStatus)) {
            return statuses[certStatus];
        } else {
            return certStatus;
        }
    }

    return (
        <ComponentContainer>
            <h1>Consultar Certificados</h1>

            <br />

            <div className="certSerial">
                <label style={{
                    position: "absolute",
                    color: "red",
                    margin: "-5px 0px 0px 12px"
                }} > {serialError} </label>
                <input
                    className="inputLayout"
                    type="text"
                    placeholder="Ingresar número de serie del certificado (decimal o hexadecimal)"
                    onChange={event => setCertificateSerial(event.target.value)}
                    style={{ width: "100%", maxWidth: "400px" }}
                />
            </div>

            <br />

            <button
                className="cyber-button inputLayout"
                onClick={certCheckRequest}
            > Buscar </button>

            {certResult}
        </ComponentContainer>
    )
}

export default CheckCertificates