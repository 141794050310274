import React from 'react'

interface CheckboxInputProp{
  setCheckBoxValue: Function
}

const CheckboxInput: React.FC<CheckboxInputProp> = ({setCheckBoxValue}) => {

    const handleClick = (checked: boolean) => {
      setCheckBoxValue(checked)
    }

    return (
        <div className="cyber-input">
          <label className="cyber-input__label">
            <input type="checkbox" onChange={event => handleClick(event.target.checked)}/> 
            Declaro que los datos que ingrese son correctos y que solicito se genere un certificado con los mismos.
          </label>
        </div>
    )
}

export default CheckboxInput
