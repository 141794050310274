import React from 'react'

import imgUploadIcon from "../../images/img_upload_icon.svg"


const UploadBox: React.FC = (props) => {
    return (
        <div className="cyber-upload-box">
            <img className="cyber-upload-box__icon" src={imgUploadIcon} alt="" />
            <div className="cyber-spacer medium"></div>
            {props.children}
            <div className="cyber-spacer medium"></div>
            <span>Se permiten archivos en formato PDF no mayores a 10mb</span>
        </div>
    )
}

export default UploadBox
