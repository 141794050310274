import React from 'react'
import { useHistory } from 'react-router-dom';

import headerLogo from "../../images/logo_cybersign--black.svg"
import ListItemComponent from './components/ListItemComponent'

interface RegisterProps{
    nextStep: Function
}

const RegisterStart: React.FC<RegisterProps> = ({nextStep}) => {

    const history = useHistory();

    return (
        <div className="cyber-main waves">
            <div className="cyber-spacer medium"></div>
            <div className="cyber-container max-width-600">
                <div className="text-center">
                    <h5>Bienvenido a</h5>
                    <div className="cyber-spacer"></div>
                    <img className="center" src={headerLogo} alt="" />
                    <div className="cyber-spacer small"></div>
                    <h5>El registro toma 5 minutos y consiste en</h5>
                </div>
                <div className="cyber-spacer medium"></div>
                <div className="cyber-register-list">

                    <ListItemComponent
                        itemNumber={1}
                        itemHeader={"Información personal"}
                        itemMessage={
                            <p>Llena un formulario con tus datos personales.</p>
                        }
                    />

                    <ListItemComponent
                        itemNumber={2}
                        itemHeader={"Comprueba tu identidad"}
                        itemMessage={
                            <p>Para comprobar tu identidad, necesitaremos tu <b>celular</b> para grabar unas selfies y compartir fotos de tu <b>DPI</b>.</p>
                        }
                    />

                    <ListItemComponent
                        itemNumber={3}
                        itemHeader={"Configuración de autenticación en 2 pasos"}
                        itemMessage={
                            <p>Descarga la aplicación Google Authenticator o Microsoft Authenticator en tu celular Una vez la tengas instalada, haz click en continuar.</p>
                        }
                    />

                    <ListItemComponent
                        itemNumber={4}
                        itemHeader={"Define tu primera firma"}
                        itemMessage={
                            <p>Has el manuscrito de tu firma personal.</p>
                        }
                    />
                </div>
                <div >
                    <button className="cyber-button edit" onClick={() => history.push("/dashboard")}>Regresar</button>
                    <button className="cyber-button pull-right" onClick={() => nextStep()}>Empecemos</button>
                </div>
            </div>
            <div className="cyber-spacer section" />
        </div>
    )
}

export default RegisterStart
