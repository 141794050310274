import React from 'react';

// icons
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Link } from 'react-router-dom';
import imgPreloader from "../../images/img_preloader.svg"

interface SignatureRowProps {
    CertName: string,
    CertType: string,
    CertificateStatus: string,
    ExpirationDate: string,
    Serial: string,
    HasBeenRenewed: boolean,
    download: Function,
    handleOpen: Function,
    setModalName: Function,
    setModalType: Function,
    setModalSerial: Function,
    setModalExpDate: Function,
    setModalHasBeenRenewed: Function,
    setModalCertificateStatus: Function,
}

const SignatureRow: React.FC<SignatureRowProps> = ({
    CertName,
    CertType,
    CertificateStatus,
    ExpirationDate,
    Serial,
    HasBeenRenewed,
    download,
    handleOpen,
    setModalName,
    setModalType,
    setModalSerial,
    setModalExpDate,
    setModalHasBeenRenewed,
    setModalCertificateStatus,
}) => {

    const convertExpDate = (expDate: string) => {
        let fullDate = new Date(expDate)
        return fullDate.toUTCString()
    }

    const openModal = () => {

        // set valur for modal
        setModalName(CertName)
        setModalType(CertType)
        setModalSerial(Serial)
        setModalExpDate(ExpirationDate)
        setModalHasBeenRenewed(HasBeenRenewed)
        setModalCertificateStatus(CertificateStatus)

        handleOpen()

    }

    return (
        <div className="cyber-table__tr align-center with-fab">
            <div className="cyber-table__td col2">
                <span className="cyber-badge">{CertType}</span>
            </div>
            <div className="cyber-table__td col6">
                <Link to="#"><h6>{CertName}</h6></Link>
                <small>No. de serie: {Serial}</small>
            </div>

            {
                ExpirationDate !== "Pending"
                    ?
                        <div className="cyber-table__td col2">{convertExpDate(ExpirationDate)}</div>
                    : 
                        <div className="cyber-table__td col2">En proceso</div>
            }

            <div className="cyber-table__td col2 justify-end">
                {
                    CertificateStatus !== "Pending"
                        ?
                            <div>
                                <Link to="#" className="cyber-fab table-action cyber-modal__trigger alignButtonMobile" onClick={openModal}><EditIcon /></Link>
                                <br />
                                <Link to="#" className="cyber-fab table-action cyber-modal__trigger alignButton" onClick={download(CertType, Serial)}><GetAppIcon /></Link>
                            </div>

                        :
                            <div>
                                <div className="cyber-alert__image-container alignSpinner">
                                    <img className="cyber-preloader" style={{ width: "65%", left: "5px" }} src={imgPreloader} alt="" />
                                </div>
                            </div>
                }
            </div>
        </div>
    )
}

export default SignatureRow
