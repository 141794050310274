import axios from 'axios';
import React from 'react'
import { useLocation } from 'react-router-dom'
import ComponentContainer from '../../components/component-container/ComponentContainer';
import VerifyNumber from './VerifyNumber'
import endpointConfig from '../../config/endpointConfig';


interface ResetOtp2Props {
    refreshToken: Function
}

// se redirije a este componente cuando se selecciona el link del correo para restablecer OTP
const ResetOtp2: React.FC<ResetOtp2Props> = ({ refreshToken }) => {

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    refreshToken()

    const location = useLocation();

    const locationArray: Array<string> = location.pathname.split("/");
    const resetId: string = locationArray[locationArray.length - 1]

    const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');
    const authToken: string = getToken['id_token'];

    let otpStep = localStorage.getItem("otpResetStep");

    if (otpStep === null) {
        localStorage.setItem("otpResetStep", "true")
        axios.get(
            `${endpointConfig.otpReset}/${resetId}`, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
        )
    }


    return (
        <ComponentContainer>
            <h1>Restablecer OTP</h1>
            <div>
                <div className="accordion">

                    <div className="item">
                        <div className="title">
                            <h4>Paso 1: Verificar de correo electrónico</h4>
                        </div>
                        <div className="content">

                        </div>
                    </div>

                    <div className="item">
                        <div className="title">
                            <h4>Paso 2: Verificar número de teléfono</h4>
                        </div>
                        <div className="content show">
                            <VerifyNumber resetId={resetId} />
                        </div>
                    </div>

                </div>
            </div>
        </ComponentContainer>
    )
}



export default ResetOtp2
