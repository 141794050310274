import React, { useState } from 'react'
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import UploadBox from '../../components/upload-box/UploadBox';
import DoneAllIcon from '@material-ui/icons/DoneAll'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import endpointConfig from '../../config/endpointConfig';
import notifyHB from '../../config/notifyHB';
import { MdPending } from 'react-icons/md';

interface VerifyDocumentProps {
    nextStep: Function
    setDocumentName: Function
    setSignatureDate: Function
    setSignerCredentials: Function
    setBase64Hash: Function
    setSignedCyberSign: Function
}

const VerifyDocumentUpload: React.FC<VerifyDocumentProps> = (
    {
        nextStep,
        setDocumentName,
        setSignatureDate,
        setSignerCredentials,
        setBase64Hash,
        setSignedCyberSign
    }) => {

    const [pdfUpload, setPdfUpload] = useState(
        {
            lastModified: 0,
            name: "",
            size: 0,
            type: "application/pdf",
            webkitRelativePath: ""
        }
    )

    const fileUpload = (event: any) => {
        let input = event.target.files[0]
        setDocumentName(input.name)
        setSignatureDate("")
        setSignerCredentials("")
        setBase64Hash("")
        setSignedCyberSign(<MdPending/>)
        setPdfUpload(input)
    }

    const sendDocument = () => {

        let input: any = pdfUpload;
        let fileReader = new FileReader()

        fileReader.onload = async function (evt) {

            // https://stackoverflow.com/questions/13981832/how-do-i-use-sha256-on-a-filebinary-file-such-as-images-in-javascript
            // este link esta aqui para explicar el CryptoJS.enc.Latin1
            let eventResult: any = evt.target?.result
            let hashed = sha256(CryptoJS.enc.Latin1.parse(eventResult));

            try {
                const verifyResponse = await axios.post(
                    endpointConfig.docsVerify,
                    {
                        "documentHash": hashed.toString()
                    }
                )

                setSignatureDate(verifyResponse.data.signatureDate)
                setSignerCredentials(verifyResponse.data.signerCredentials)
                setBase64Hash(verifyResponse.data.base64CAHash)



                if (verifyResponse.data.signedCyberSign) {
                    setSignedCyberSign(<DoneAllIcon />)
                } else {
                    setSignedCyberSign(<HighlightOffIcon />)
                }
            } catch (error: any) {

                alert("Hubo un error validando el documento")

                //tags: 'verify-document, VerifyDocumentUpload'
                notifyHB("verify-document", "VerifyDocumentUpload", error)
            }



        }

        fileReader.readAsBinaryString(input);
        nextStep()
    }

    return (
        <div>

            <UploadBox>
                <h3>Sube el documento a validar</h3>
                <div className="cyber-spacer medium"></div>
                <div className="cyber-upload-box__input">
                    <input
                        type="file"
                        className="form-group"
                        required
                        accept="application/pdf"
                        onChange={fileUpload}
                    />
                </div>
                <button className="cyber-button" onClick={sendDocument}>Subir documento</button>
            </UploadBox>
        </div>
    )
}

export default VerifyDocumentUpload
