import React from 'react'
import "../Register.css"
import imagePreloader from "../../../images/img_preloader.svg"

function LoadingOtpComponent() {
    return (
        <div className="centerLoading">
            <div className="cyber-spacer medium"></div>
            <img className="cyber-preloader" src={imagePreloader} alt="" />
            <div className="cyber-spacer small"></div>
            <h4>Generando codigo QR para conectar con Google o Microsoft Authenticator</h4>
            <div className="cyber-spacer medium"></div>
        </div>
    )
}

export default LoadingOtpComponent
