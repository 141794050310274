import React from 'react'

// icons
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import authLogo from "../../../images/google_authenticator_logo.png"
import { Link } from 'react-router-dom';

interface OtpStepProps {
    handleClose: Function
    setOtpInput: Function
    targetStatus: number | null
    operation: string
    handleForm: Function
    setTargetStatus: Function
    otpMessage: string
    process: string
}

const OtpStep: React.FC<OtpStepProps> = ({ handleClose, setOtpInput, targetStatus, operation, handleForm, setTargetStatus, otpMessage, process }) => {

    const selectReason = () => {
        let inputValue = (document.getElementById("revokeR") as HTMLInputElement).value
        setTargetStatus(inputValue)
    }

    return (
        <div className="modalContainer" >
            <div className="moveRight">
                <button onClick={() => handleClose()}>
                    <CloseIcon />
                </button>
            </div>
            <img src={authLogo} alt="" />
            <div className="cyber-spacer small"></div>
            <h4>Autenticación de dos pasos</h4>
            <div className="cyber-spacer small"></div>
            {operation === "Revocation" ? <div>
                <p className="pNoMarginB">Selecciona razón de revocación</p>
                <select className="selectMB" id="revokeR" onChange={selectReason}>
                    <option value="0">No especificado</option>
                    <option value="1">Llave comprometida</option>
                    <option value="3">Cambio afiliacion</option>
                    <option value="4">Reemplazado</option>
                    <option value="5">Cese de operaciones</option>
                </select>
                <br />
            </div> : null}
            <p>{otpMessage}</p>
            <form className="cyber-form">
                <div className="cyber-input icon-left">
                    <input type="text" className="cyber-input__textfield" placeholder="XXX XXX" onChange={event => {
                        setOtpInput(event.target.value)
                    }} />
                    <span className="material-icons cyber-input__icon-left"><LockIcon /></span>
                </div>
                <Link className="cyber-button full" to="#" onClick={() => handleForm(targetStatus, operation)} >Confirmar {process} de firma</Link>
            </form>
        </div>
    )
}

export default OtpStep
