import React from 'react'

function CertificateInformation(props: {
    caSerial: string,
    certType: string,
    certificateStatus: string,
    createdAt: string,
    expirationDate: string,
    emailDeclared: string,
    serial: string,
    revocationTimeStamp: string,
    suscriberName: string
}) {
    return (
        <table className="borderTable">
            <tbody>
                <tr>
                    <th className="borderTh">caSerial:</th>
                    <td className="borderTd">{props.caSerial}</td>
                </tr>
                <tr>
                    <th className="borderTh">Certification Type:</th>
                    <td className="borderTd">{props.certType}</td>
                </tr>
                <tr>
                    <th className="borderTh">Status:</th>
                    <td className="borderTd">{props.certificateStatus}</td>
                </tr>
                <tr>
                    <th className="borderTh">Fecha de creación:</th>
                    <td className="borderTd">{props.createdAt}</td>
                </tr>
                <tr>
                    <th className="borderTh">Fecha de expiración:</th>
                    <td className="borderTd">{props.expirationDate}</td>
                </tr>
                <tr>
                    <th className="borderTh">Correo declarado:</th>
                    <td className="borderTd">{props.emailDeclared}</td>
                </tr>
                <tr>
                    <th className="borderTh">Serial:</th>
                    <td className="borderTd">{props.serial}</td>
                </tr>
                <tr>
                    <th className="borderTh">Fecha de revocacion:</th>
                    <td className="borderTd">{props.revocationTimeStamp}</td>
                </tr>
                <tr>
                    <th className="borderTh">Nombre del suscriptor:</th>
                    <td className="borderTd">{props.suscriberName}</td>
                </tr>
            </tbody>
        </table>
    )
}

export default CertificateInformation
