import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom';

import routeConfig from '../../config/routeConfig'

function CyberFooter() {

    const location = useLocation()

    if (location.pathname.match(routeConfig.loginCognito)) {
        return null;
    }
    if (location.pathname.match(routeConfig.redirectLogin) ) {
        return null;
    } else {
        return (
            <div>
                <div className="cyber-spacer small"></div>

                <div className="cyber-container-footer">
                    <footer className="cyber-footer">
                        <div className="cyber-footer__copyright">
                            © CyberSign 2021. Todos los derechos reservados.
                        </div>
                        <div className="cyber-footer__links">
                            <Link to={routeConfig.contact}>Contacto</Link>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }

}

export default CyberFooter
