const validateDocument = (docNumber: number) => {
    let docString = docNumber.toString().replace(/\s/g, '');
    let cuiRegExp = /^\d{4}\d{5}\d{4}$/;

    if (!cuiRegExp.test(docString)) {
        return false;
    }

    let depto = parseInt(docString.substring(9, 11), 10);
    let muni = parseInt(docString.substring(11, 13));
    let numero = docString.substring(0, 8);
    let verificador = parseInt(docString.substring(8, 9));

    let munisPorDepto = [
        /* 01 - Guatemala      */ 17 /* municipios. */,
        /* 02 - El Progreso    */  8 /* municipios. */,
        /* 03 - Sacatepéquez   */ 16 /* municipios. */,
        /* 04 - Chimaltenango  */ 16 /* municipios. */,
        /* 05 - Escuintla      */ 13 /* municipios. */,
        /* 06 - Santa Rosa     */ 14 /* municipios. */,
        /* 07 - Sololá         */ 19 /* municipios. */,
        /* 08 - Totonicapán    */  8 /* municipios. */,
        /* 09 - Quetzaltenango */ 24 /* municipios. */,
        /* 10 - Suchitepéquez  */ 21 /* municipios. */,
        /* 11 - Retalhuleu     */  9 /* municipios. */,
        /* 12 - San Marcos     */ 30 /* municipios. */,
        /* 13 - Huehuetenango  */ 32 /* municipios. */,
        /* 14 - Quiché         */ 21 /* municipios. */,
        /* 15 - Baja Verapaz   */  8 /* municipios. */,
        /* 16 - Alta Verapaz   */ 17 /* municipios. */,
        /* 17 - Petén          */ 14 /* municipios. */,
        /* 18 - Izabal         */  5 /* municipios. */,
        /* 19 - Zacapa         */ 11 /* municipios. */,
        /* 20 - Chiquimula     */ 11 /* municipios. */,
        /* 21 - Jalapa         */  7 /* municipios. */,
        /* 22 - Jutiapa        */ 17 /* municipios. */
    ];

    if (depto === 0 || muni === 0) {
        return false;
    }

    if (depto > munisPorDepto.length) {
        return false;
    }

    if (muni > munisPorDepto[depto - 1]) {
        return false;
    }

    // Validate the correlativo with the modulo 11 algorithm
    let total = 0;
    for (let i = 0; i < numero.length; i++) {
        total += parseInt(numero[i], 10) * (i + 2);
    }

    let modulo = total % 11;
    return modulo === verificador;
}

export default validateDocument;

