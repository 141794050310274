import React from "react";

import imgPreloader from "../../images/img_preloader.svg"


const Spinner = () => {
    return (
        <div className="cyber-alert__image-container">
            <img className="cyber-preloader" src={imgPreloader} alt="" />
        </div>
    );
}

export default Spinner;