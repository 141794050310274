import React from 'react'

interface RequestSentProps {
    handleClose: Function
}

const RequestSent: React.FC<RequestSentProps> = ({handleClose}) => {
    return (
        <div className="modalContainer">
            <h5>Solicitud enviada correctamente</h5>
            <button style={{width: "100%"}} onClick={() => handleClose()} className="cyber-button">Continuar</button>
        </div>
    )
}

export default RequestSent
