import React from 'react'

interface ConfirmSignatureProps {
    image: string
    returnStep: Function
    nextStep: Function
}

const ConfirmSignature: React.FC<ConfirmSignatureProps> = ({
    image,
    returnStep,
    nextStep
}) => {

    return (
        <div className="centerContent">
            <p>¿Desea guardar esta firma?</p>
            <img src={image} alt="" />
            <div>
                <button onClick={() => returnStep()} className="cyber-button separateOptions buttonWidth" >
                    No
                </button>

                <button onClick={() => nextStep()} className="cyber-button buttonWidth">
                    Sí
                </button>

            </div>
        </div>
    )
}

export default ConfirmSignature
