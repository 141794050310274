import React, { useState } from 'react'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import ModalOptions from './components/ModalOptions';
import { Link } from 'react-router-dom';
import ModalUnsuspendOption from './components/ModalUnsuspendOption';
import endpointConfig from '../../config/endpointConfig';


interface SignatureModalProps {
    modalName: string
    modalType: string
    modalSerial: string
    modalExpDate: string
    modalHasBeenRenewed: boolean
    modalCertificateStatus: string
    handleClose: Function
    setComponentState: Function
    setConfirmStateNumber: Function
    setConfirmStateName: Function
}

const SignatureModal: React.FC<SignatureModalProps> = ({
    modalName,
    modalType,
    modalSerial,
    modalExpDate,
    modalHasBeenRenewed,
    modalCertificateStatus,
    handleClose,
    setComponentState,
    setConfirmStateNumber,
    setConfirmStateName
}) => {

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    type LoginData = {
        sub: string;
        email_verified: string;
        email: string;
        username: string;
    }

    // customName del input
    const [customName, setCustomName] = useState(modalName)
    const [updateName, setUpdateName] = useState(false)


    const signatureImgC = localStorage.getItem("signatureImg")!

    const updatingName = (event: string) => {
        setUpdateName(false)
        setCustomName(event)
    }

    const updateCertName = async () => {
        const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');
        const authToken: string = getToken['id_token']

        const loginData: LoginData = JSON.parse(localStorage.getItem("login-data") || '{}');
        const cognitoUser: string = loginData['sub']

        const updateResponse = await axios.post(
            endpointConfig.customName,
            {
                "cognito_user": cognitoUser,
                "serialNumber": modalSerial,
                "customName": customName
            },
            {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            }
        );

        if (updateResponse.status === 200) {
            setUpdateName(true)
        }

    }

    return (
        <div >
            <div className="moveRight moveDown">
                <button onClick={() => handleClose()}>
                    <CloseIcon />
                </button>
            </div>

            <div >
                <h3>Editar Firma</h3>
                <div className="cyber-spacer small"></div>
                <div className="cyber-flex">
                    <div className="cyber-block col6">
                        <span className="cyber-type__label">Tipo</span>
                        <span className="cyber-type__h6">{modalType}</span>
                    </div>
                    <div className="cyber-block col6">
                        <span className="cyber-type__label">No. de serie</span>
                        <span className="cyber-type__h6">{modalSerial}</span>
                    </div>
                </div>
                <div className="cyber-spacer"></div>
                <hr></hr>
                <div className="cyber-spacer"></div>
                <div className="cyber-flex align-center">
                    <div className="cyber-block col6">
                        <span className="cyber-type__label">Fecha de expiración</span>
                        <span className="cyber-type__h6">{modalExpDate}</span>
                    </div>

                </div>
                <div className="cyber-spacer"></div>
                <hr></hr>
                <div className="cyber-spacer"></div>
                <div className="cyber-input">
                    <label className="cyber-input__label">Nombre</label>
                    <input
                        type="text"
                        className="cyber-input__textfield"
                        placeholder={modalName}
                        onChange={event => updatingName(event.target.value)}
                    />
                    <br />
                    <button className="cyber-button" onClick={updateCertName}>Actualizar</button>
                    {updateName ? <CheckIcon style={{ position: "relative", top: "5px" }} /> : null}
                </div>
                <div className="cyber-spacer"></div>
                <hr></hr>
                <div className="cyber-spacer"></div>
                <div className="cyber-input">
                    <label className="cyber-input__label">Manuscrito</label>
                    <div className="cyber-input-signature">
                        <div className="cyber-input-signature__area">
                            <div className="myDiv">
                                {
                                    signatureImgC === undefined
                                        ? <p>No posee una firma en este momento</p>
                                        :
                                        <img src={signatureImgC} alt="" />
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <div className="cyber-spacer medium"></div>
                <div className="cyber-list">

                    {
                        (() => {
                            if (modalCertificateStatus !== "Active") {
                                if (modalCertificateStatus === "6") {
                                    return (
                                        <ModalUnsuspendOption 
                                            setComponentState={setComponentState}
                                            setConfirmStateNumber={setConfirmStateNumber}
                                            setConfirmStateName={setConfirmStateName}
                                        />
                                    )
                                } else {
                                    return (
                                        <Link to="#" className="cyber-list__item" style={{ cursor: "default" }} >
                                            <div className="cyber-list__item-content">
                                                <b>Firma Revocada</b>
                                            </div>
                                        </Link>
                                    )
                                }

                            }

                            else {
                                return (
                                    <ModalOptions
                                        modalHasBeenRenewed={modalHasBeenRenewed}
                                        setComponentState={setComponentState}
                                        setConfirmStateNumber={setConfirmStateNumber}
                                        setConfirmStateName={setConfirmStateName}
                                    />
                                )
                            }

                        })()
                    }


                </div>
                <div className="cyber-spacer small"></div>
                <hr></hr>
                <div className="cyber-spacer small"></div>

            </div>
        </div>
    )
}

export default SignatureModal
