import React from 'react'

const ComponentContainer: React.FC = (props) => {

    const closeSidenav = () => {
        document.querySelector('.cyber-sidenav')!.classList.remove('visible');
    }

    return (
        <div>

            <div className="cyber-backdrop"></div>

            <div className="cyber-main with-sidenav" onClick={closeSidenav}>
                <div className="cyber-spacer section"></div>
                <div className="cyber-container">
                    {props.children}
                </div>
            </div>

        </div>
    )
}

export default ComponentContainer
