import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CheckIdentity from './CheckIdentity'
import CyberViewContainer from './components/CyberViewContainer'
import FormContainer from './components/FormContainer'
import RegisterHeaderComponent from './components/RegisterHeaderComponent'
import ContactInfo from './ContactInfo'
import DocumentInfo from './DocumentInfo'
import FormSummary from './FormSummary'
import OtpComponent from './OtpComponent'
import PersonalInfo from './PersonalInfo'
import RegisterConfirmation from './RegisterConfirmation'
import endpointConfig from '../../config/endpointConfig'
import RegisterStart from './RegisterStart'
import TwoFA from './TwoFA'
import ScrollableAgreement from './ScrollableAgreement'

interface RegisterProps {
  refreshToken: Function
}

const Register: React.FC<RegisterProps> = ({ refreshToken }) => {

  refreshToken()

  const [componentState, setComponentState] = useState(0)
  const [agreementAccepted, setAgreementAccepted] = useState(false)

  // Personal info form
  const [personName, setPersonName] = useState("")
  const [personLastName, setPersonLastName] = useState("")
  const [address, setAddress] = useState("")
  const [personProfession, setPersonProfession] = useState("")

  // Document Form
  const [nit, setNit] = useState("")
  const [documentNumber, setDocumentNumber] = useState(0)

  // Contact Info
  const [userEmail, setUserEmail] = useState("")
  const [userPhoneCountryCode, setUserPhoneCountryCode] = useState("")
  const [userPhone, setUserPhone] = useState("")

  const [confirmationMessage,] = useState("La autenticación de 2 pasos (2FA) fue configurada correctamente")

  // types
  type UserInfo = {
    id_token: string;
    access_token: string;
    refresh_token: string;
    expires_in: number;
    token_type: string;
  }

  type Onboarding = {
    componentState: number
    personName: string,
    personLastName: string,
    address: string,
    personProfession: string,
    nit: string,
    documentNumber: number,
    userEmail: string,
    userPhoneCountryCode: string,
    userPhone: string
  }

  const getPersonalInformation = async (authToken: string) => {
    const manageResponse = await axios.post(
      endpointConfig.user,
      {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    ).catch(error => {
      console.log('Error al obtener información del usuario')
    })

    setPersonName(manageResponse?.data.clientInformation.first_name)
    setPersonLastName(manageResponse?.data.clientInformation.last_name)
    setNit(manageResponse?.data.clientInformation.nit)
    setAddress(manageResponse?.data.clientInformation.address)
    setUserEmail(manageResponse?.data.clientInformation.email)
    setUserPhoneCountryCode(manageResponse?.data.clientInformation.phone_country_code.replaceAll("+", ""))
    setUserPhone(manageResponse?.data.clientInformation.phone_number)
    setDocumentNumber(Number(manageResponse?.data.clientInformation.document_number.replaceAll(" ", "")))
  }

  useEffect(() => {
    // Get personal information from backend if exists
    const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") ?? '{}');
    const authToken: string = getToken['id_token'];

    if (authToken) {
      getPersonalInformation(authToken)
    }

    const onboardingData: Onboarding = JSON.parse(localStorage.getItem("onboard")!);

    if (onboardingData != null) {
      setComponentState(onboardingData.componentState)
      setPersonName(onboardingData.personName)
      setPersonLastName(onboardingData.personLastName)
      setAddress(onboardingData.address)
      setPersonProfession(onboardingData.personProfession)
      setNit(onboardingData.nit)
      setDocumentNumber(onboardingData.documentNumber)
      setUserEmail(onboardingData.userEmail)
      setUserPhoneCountryCode(onboardingData.userPhoneCountryCode)
      setUserPhone(onboardingData.userPhone)
    }

  }, [])

  const nextStep = () => {
    setComponentState(componentState + 1)
  }

  const onboardingLS = () => {
    let content = {
      componentState: componentState,
      personName: personName,
      personLastName: personLastName,
      address: address,
      personProfession: personProfession,
      nit: nit,
      documentNumber: documentNumber,
      userEmail: userEmail,
      userPhoneCountryCode: userPhoneCountryCode,
      userPhone: userPhone,
    }

    localStorage.setItem("onboard", JSON.stringify(content))
  }

  let step;

  switch (componentState) {
    case 0:
      step = <RegisterStart nextStep={nextStep} />
      break // step 0
    case 1:
      step = <ScrollableAgreement
        nextStep={nextStep}
        agreementAccepted={agreementAccepted}
        setAgreementAccepted={setAgreementAccepted}
      />
      break // new step 1
    case 2:
      step = <PersonalInfo
        nextStep={nextStep}
        personName={personName}
        setPersonName={setPersonName}
        personLastName={personLastName}
        setPersonLastName={setPersonLastName}
        setNit={setNit}
        setDocumentNumber={setDocumentNumber}
        address={address}
        setAddress={setAddress}
        personProfession={personProfession}
        setPersonProfession={setPersonProfession}
      />
      break // step 2 (previously step 1)
    case 3:
      step = <DocumentInfo
        nextStep={nextStep}
        documentNumber={documentNumber}
        setDocumentNumber={setDocumentNumber}
        nit={nit}
        setNit={setNit}
        onboardingLS={onboardingLS}
      />
      break // step 3
    case 4:
      step = <ContactInfo
        nextStep={nextStep}
        setUserEmail={setUserEmail}
        userPhoneCountryCode={userPhoneCountryCode}
        setUserPhoneCountryCode={setUserPhoneCountryCode}
        userPhone={userPhone}
        setUserPhone={setUserPhone}
        onboardingLS={onboardingLS}
      />
      break // step 4
    case 5:
      step = <FormSummary
        nextStep={nextStep}
        personName={personName}
        personLastName={personLastName}
        address={address}
        userPhoneCountryCode={userPhoneCountryCode}
        userPhone={userPhone}
        nit={nit}
        profession={personProfession}
        userEmail={userEmail}
        documentNumber={documentNumber}
        setComponentState={setComponentState}
      />
      break // step 5
    case 6:
      step = <CheckIdentity
        nextStep={nextStep}
        personName={personName}
        personLastName={personLastName}
        address={address}
        userPhoneCountryCode={userPhoneCountryCode}
        userPhone={userPhone}
        nit={nit}
        profession={personProfession}
        userEmail={userEmail}
        documentNumber={documentNumber}
        onboardingLS={onboardingLS}
      />
      break // step 6
    case 7:
      step = <TwoFA nextStep={nextStep} />
      break // step 7
    case 8:
      step = <OtpComponent nextStep={nextStep} />
      break // step 8
    case 9:
      step = <RegisterConfirmation
        confirmationMessage={confirmationMessage}
        displayButton={true}
      />
      break // step 9 (previously step 8)
    default:
      step = <RegisterStart nextStep={nextStep} />
      break
  }

  return (
    <div>
      {componentState === 0 ? step :
        <CyberViewContainer>
          <RegisterHeaderComponent componentState={componentState} setComponentState={setComponentState} />
          <FormContainer>
            {step}
          </FormContainer>
        </CyberViewContainer>
      }

    </div>
  )
}

export default Register
