import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';


interface ConsultRequirementsProps {
    handleClose: Function
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const ConsultRequirements: React.FC<ConsultRequirementsProps> = ({handleClose}) => {
    const [expandedDPI, setExpandedDPI] = useState(false)
    const [expandedGA, setExpandedGA] = useState(false)

    const handleExpandClickDPI = () => {
        setExpandedDPI(!expandedDPI);
    };

    const handleExpandClickGA = () => {
        setExpandedGA(!expandedGA);
    };

    return (
        <div >
            <div className="moveRight moveDown">
                <button onClick={() => handleClose()}>
                    <CloseIcon />
                </button>
            </div>

            <div >
                <h3>Consultar certificado</h3>
                <br />
                <p>Este servicio también provee información importante sin la necesidad de subir el documento. Basta con el ingreso del numero de serie del documento o el CA Hash</p>
                <Card sx={{ maxWidth: 345 }}>

                    {/* DPI VALIDO */}
                    <CardActions disableSpacing
                    >
                        <IconButton 
                            aria-label="add to favorites"
                            onClick={handleExpandClickDPI}
                        >
                            Número de serie
                        </IconButton>

                        <ExpandMore
                            expand={expandedDPI}
                            onClick={handleExpandClickDPI}
                            aria-expanded={expandedDPI}
                            aria-label="show more"
                        >
                            <KeyboardArrowDownIcon />
                        </ExpandMore>

                    </CardActions>
                    <Collapse in={expandedDPI} timeout="auto" unmountOnExit>

                        <CardContent style={{ maxHeight: 500, overflow: 'auto' }}>
                            <Typography paragraph>
                            El número de serie del certificado puede obtenerse utilizando el servicio de Validar documento o utilizando el lector de pdf de Adobe.
                            </Typography>

                        </CardContent>
                    </Collapse>


                    {/* AUTHENTICATOR */}
                    <CardActions disableSpacing>
                        <IconButton 
                            aria-label="add to favorites"
                            onClick={handleExpandClickGA}
                        >
                            CA Hash
                        </IconButton>

                        <ExpandMore
                            expand={expandedGA}
                            onClick={handleExpandClickGA}
                            aria-expanded={expandedGA}
                            aria-label="show more"
                        >
                            <KeyboardArrowDownIcon />
                        </ExpandMore>

                    </CardActions>
                    <Collapse in={expandedGA} timeout="auto" unmountOnExit>

                        <CardContent style={{ maxHeight: 500, overflow: 'auto' }}>
                            <Typography paragraph>
                                CA Hash es el Certificate authority hash dado al documento, este puede obtenerse utilizando el lector de pdf de Adobe
                            </Typography>

                        </CardContent>
                    </Collapse>


                </Card>
            </div>
        </div>
    );
}

export default ConsultRequirements