import React from 'react'

const FormContainer: React.FC = (props) => {
    return (
        <div className="cyber-main waves">
            <div className="cyber-spacer section"></div>
            <div className="cyber-container max-width-720">
                <div className="cyber-card">
                    <form className="cyber-form">
                        {props.children}
                    </form>
                </div>
            </div>
            <div className="cyber-spacer section"></div>
        </div>

    )
}

export default FormContainer
