import React from 'react'

const ProfileContentForm: React.FC = (props) => {
    return (
        <div className="cyber-profile__content">
            <br/>
            <div className="cyber-card ">
                <form className="cyber-form">
                    {props.children}
                </form>
            </div>
        </div>
    )
}

export default ProfileContentForm
