import React from 'react'
import { Link } from 'react-router-dom';
import ComponentContainer from '../../components/component-container/ComponentContainer';

interface PoliciesAndProceduresProps {
    refreshToken: Function
}

const PoliciesAndProcedures: React.FC<PoliciesAndProceduresProps> = ({refreshToken}) => {


    const downloadFile = (url: string) => {
        window.open(url, '_blank');
    };

    return (

        <ComponentContainer>
            <h1>Políticas y procedimientos</h1>
            <div className="cyber-spacer large"></div>
            <div className="cyber-grid two-columns">
                <div className="cyber-card bordered flex direction-column justify-between align-start">
                    <h5>Politica de certificación</h5>
                    <div className="cyber-spacer medium"></div>
                    <p>Este documento declara las Políticas de Certificación de CyberSign, los cuales dan cumplimiento a los requisitos establecidos en el Decreto 47-2008 de Ley para el Reconocimiento de las Comunicaciones y Firmas Electrónicas, su reglamento, así como las normas técnicas que establece el Registro de Prestadores de Servicios de Certificación.</p>
                    <Link to="#" className="cyber-button icon-left" onClick={() => downloadFile('https://politicas.cybersign.gt/cp.pdf')}>
                        Descargar
                    </Link>
                </div>
                <div className="cyber-card bordered flex direction-column justify-between align-start">
                    <h5>Política de privacidad</h5>
                    <div className="cyber-spacer medium"></div>
                    <p>Es el documento en complemento a la Declaración de Políticas de Certificación, asociada a la prestación de los servicios de certificación, de acuerdo con las mejores prácticas para garantizar la confidencialidad y reserva de los datos personales proporcionados por los usuarios, adoptando cuantas medidas de protección sean necesarias.</p>
                    <Link to="#" className="cyber-button icon-left" onClick={() => downloadFile('https://politicas.cybersign.gt/privacidad.pdf')}>
                        Descargar
                    </Link>
                </div>
                <div className="cyber-card bordered flex direction-column justify-between align-start">
                    <h5>Declaración de prácticas de certificación</h5>
                    <div className="cyber-spacer medium"></div>
                    <p>Este documento constituye todos los procedimientos y procesos de (EMPRESA) con relación a la prestación de sus servicios de certificación de acuerdo a la normativa legalmente aplicable.</p>
                    <Link to="#" className="cyber-button icon-left" onClick={() => downloadFile('https://politicas.cybersign.gt/cps.pdf')}>
                        Descargar
                    </Link>
                </div>
                <div className="cyber-card bordered flex direction-column justify-between align-start">
                    <h5>Política de calidad</h5>
                    <div className="cyber-spacer medium"></div>
                    <p>Este documento tiene a consideración las políticas y estrategias para conducir a EMPRESA hacia la mejora en su funcionamiento global y su aspiración de ofrecer a los clientes servicios de emisión de Firma Digital confiables, seguros e innovadores.</p>
                    <Link to="#" className="cyber-button icon-left" onClick={() => downloadFile('https://politicas.cybersign.gt/calidad.pdf')}>
                        Descargar
                    </Link>
                </div>
            </div>
        </ComponentContainer>
    )
}

export default PoliciesAndProcedures
