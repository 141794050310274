import React from 'react'
import { Link } from 'react-router-dom';
import ComponentContainer from '../../components/component-container/ComponentContainer';

interface LegalProps {
    refreshToken: Function
}

const Legal: React.FC<LegalProps> = ({refreshToken}) => {

    const downloadFile = (url:string) => {
        window.open(url, '_blank');
    }

    return (

        <ComponentContainer>
            <h1>Marco Legal</h1>
            <div className="cyber-spacer"></div>
            <p>CyberSign, como firma electrónica, se encuentra avalada por:</p>
            <div className="cyber-spacer large"></div>
            <div className="cyber-grid three-columns">
                <div className="cyber-card bordered flex direction-column justify-between align-start">
                    <h5>Ley para el reconocimiento de las comunicaciones y firmas electrónicas (Decreto no. 47 – 2008)</h5>
                    <div className="cyber-spacer medium"></div>
                    <Link to="#" className="cyber-button icon-left" onClick={() => downloadFile("https://www.minfin.gob.gt/images/archivos/leyes/tesoreria/Decretos/DECRETO%2047-2008.pdf")}>
                        Descargar
                    </Link>

                </div>
                <div className="cyber-card bordered flex direction-column justify-between align-start">
                    <h5>El Reglamento de la Ley (Acuerdo Gubernativo 135-2009 y su reforma)</h5>
                    <div className="cyber-spacer medium"></div>
                    <Link to="#" className="cyber-button icon-left" onClick={() => downloadFile("https://www.minfin.gob.gt/images/archivos/leyes/tesoreria/Acuerdos%20Gubernativos/ACUERDO%20GUBERNATIVO%20135-2009.pdf")}>
                        Descargar
                    </Link>
                </div>
                <div className="cyber-card bordered flex direction-column justify-between align-start">
                    <h5>Resolución de Registro de Prestadores de Servicios de Certificación</h5>
                    <div className="cyber-spacer medium"></div>
                    <Link to="#" className="cyber-button icon-left" >
                        Descargar
                    </Link>
                </div>
            </div>
        </ComponentContainer>
    )
}

export default Legal
