import React, { useContext, useState } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/Menu';
import { AuthContext } from '../auth-context/auth-context';
import routeConfig from '../../config/routeConfig';
import cssConfig from '../../config/cssConfig';
import { Link, useHistory, useLocation } from "react-router-dom";

// images & icons
import headerLogo from "../../images/logo_cybersign--black.svg"
import PersonIcon from '@material-ui/icons/Person';
import { useCookies } from 'react-cookie';
import LogoutButton from '../logout-button/LogoutButton';

interface HeaderSidenavProps {
    userName: string
    userEmail: string
}

const HeaderSidenav: React.FC<HeaderSidenavProps> = ({ userName, userEmail }) => {

    const location = useLocation();

    // mostrar usuario y cerrar sesion
    const [userDropDown, setUserDropDown] = useState(false)

    // cookies
    const [, , removeLoginData] = useCookies(["loginData"]);
    const [, , removeExpDate] = useCookies(["tokenExpirDate"]);
    const [, , removeUserInfo] = useCookies(["userInfo"]);
    const [, , removeUserName] = useCookies(["userName"]);


    let history = useHistory();

    window!.onscroll = function () { cssConfig.handleHeaderSidenav() };

    const sideNav = () => {
        document.querySelector('.cyber-sidenav')!.classList.add('visible')
    }

    const dropdownTrigger = () => {
        if (userDropDown === false) {
            document.querySelector('.cyber-user-dropdown__list')!.classList.add('visible');
            setUserDropDown(true)
        } else {
            document.querySelector('.cyber-user-dropdown__list')!.classList.remove('visible');
            setUserDropDown(false)
        }
    }

    const toProfile = () => {
        document.querySelector('.cyber-user-dropdown__list')!.classList.remove('visible');
        setUserDropDown(false)
        history.push(routeConfig.profile)
    }

    let expDate = JSON.parse(localStorage.getItem("token-expir-date")!);

    // arreglar let tokenED = Number(tokenExpirDateC.tokenExpirDate)

    let currentDate = new Date().getTime() / 1000

    if (expDate != null) {
        if (currentDate > expDate) {
            localStorage.clear()
            removeLoginData("loginData", { path: "/" })
            removeExpDate("tokenExpirDate", { path: "/" })
            removeUserInfo("userInfo", { path: "/" })
            removeUserName("userName", { path: "/" })
            history.push("/")
            history.go(0)
        }
    }

    const authCtx = useContext(AuthContext);
    if (location.pathname.match(routeConfig.requestSignature)) {
        return null;
    }
    else {
        return (
            <div id="header" className="cyber-header with-sidenav">
                <div className="cyber-container flex space-between">
                    <div className="cyber-header__column-left">
                        <Link to="#" className="cyber-sidenav__trigger" onClick={sideNav}>
                            <MenuIcon />
                        </Link>
                    </div>
                    <div className="cyber-header__column-center">
                        <Link to={routeConfig.home}>
                            <img className="cyber-header__logo" src={headerLogo} alt="" />
                        </Link>
                    </div>

                    {authCtx.isAuth ?
                        <div className="cyber-header__column-right">
                            <div className="cyber-user-dropdown">
                                <button className="cyber-user-dropdown__trigger" onClick={dropdownTrigger}>
                                    <div className="cyber-user-dropdown__user-info">
                                        <span className="cyber-user-dropdown__name">
                                            {userName}
                                        </span>
                                        <span className="cyber-user-dropdown__email">
                                            {userEmail}
                                        </span>
                                    </div>
                                    <div className="cyber-user-dropdown__arrow">
                                        <KeyboardArrowDownIcon />
                                    </div>
                                </button>
                                <div className="cyber-user-dropdown__list">
                                    <Link to="#" className="cyber-user-dropdown__item" onClick={toProfile}>
                                        <div className="cyber-user-dropdown__item-icon">
                                            <PersonIcon />
                                        </div>
                                        Perfil
                                    </Link>
                                    <LogoutButton />
                                </div>
                            </div>
                        </div> : null}
                </div>
            </div>
        )
    }
}

export default HeaderSidenav
