import React from 'react'

interface CyberInputProps {
    inputLabel: string
    placeholder: string
    setValue: Function
    value: string
    validInput: boolean
    disabled?: boolean
    maxWidth?: string
}

const CyberInputComponent: React.FC<CyberInputProps> = ({ inputLabel, placeholder, setValue, value, validInput, maxWidth, disabled=false }) => {
    return (
        <div className="cyber-input" style={{ maxWidth: maxWidth }}>
            <label className="cyber-input__label">{inputLabel}</label>
            <input
                disabled={ disabled }
                type="text" 
                className={`cyber-input__textfield ${disabled ? "cyber-input__disabled" : ""}`}
                style={validInput ? { borderColor: '#dbdbdf' } : { borderColor:'red' }}
                onChange={
                    event => {
                        setValue(event.target.value)
                    }
                }
                placeholder={placeholder}
                value={value}
                />
        </div>
    )
}

export default CyberInputComponent
