import axios from 'axios'
import React, { useRef, useState } from 'react'
import ComponentContainer from '../../components/component-container/ComponentContainer'
import endpointConfig from '../../config/endpointConfig'
import notifyHB from '../../config/notifyHB'
import CustomSignatureSteps from './components/CustomSignatureSteps'
import ConfirmSignature from './ConfirmSignature'

// css
import "./CustomSignature.css"
import DrawSignature from './DrawSignature'
import UploadSignature from './UploadSignature'

interface CustomSignatureProps {
    refreshToken: Function
}

const CustomSignature: React.FC<CustomSignatureProps> = ({ refreshToken }) => {

    refreshToken()

    // useState
    const [componentState, setComponentState] = useState(0)
    const [image, setImage] = useState("")
    const [blobData, setBlobData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    // nextStep
    const nextStep = () => {
        setComponentState(componentState + 1)
    }

    // returnStep
    const returnStep = () => {
        setComponentState(componentState - 1)
    }

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    type LoginData = {
        sub: string;
        email_verified: string;
        email: string;
        username: string;
    }

    // useRef
    const canvasDraw = useRef(null)


    const cleanCanvas = () => {
        // @ts-ignore
        canvasDraw.current.clear()
    }

    const handleS3Form = (postInfo: any, fileToUpload: any) => {
        let newForm: FormData = new FormData()
        newForm.append('key', postInfo['fields']['key'])
        newForm.append('x-amz-credential', postInfo['fields']['x-amz-credential'])
        newForm.append('x-amz-algorithm', postInfo['fields']['x-amz-algorithm'])
        newForm.append('policy', postInfo['fields']['policy'])
        newForm.append('x-amz-signature', postInfo['fields']['x-amz-signature'])
        newForm.append('x-amz-date', postInfo['fields']['x-amz-date'])
        // @ts-ignore: Object is possibly 'null'.
        newForm.append('file', fileToUpload)

        return newForm;
    }


    const dataURItoBlob = (dataURI: any) => {
        var binary = atob(dataURI.split(',')[1]);
        var array = [];
        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: 'image/jpg' });
    }

    const saveImg = async () => {

        let tier: string = 'tier-1'

        let authToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');

        let loginData: LoginData = JSON.parse(localStorage.getItem("login-data") || '{}');

        const resp: any = await axios.post(endpointConfig.docsUpload, {
            file_size: tier,
            new_object: `${loginData['sub']}/signature.jpg`
        }, {
            headers: {
                Authorization: `Bearer ${authToken["id_token"]}`,

            }
        })


        const postInfo = JSON.parse(resp['data']['body']);
        const s3Form: FormData = handleS3Form(postInfo, blobData);

        const headerscustom = {
            'Content-Disposition': postInfo['fields']['Content-Disposition'],
            'x-amz-meta-share': postInfo['fields']['x-amz-meta-share']
        }

        try {
            axios.post(
                `${postInfo['url']}`,
                s3Form,
                { headers: headerscustom }
            ).then(() => {
                let req_object: string = `${loginData['sub']}/signature.jpg`

                // se obtiene el link para el garabato
                axios.post(
                    endpointConfig.docsDownload,
                    { "req_obj": req_object },
                    {
                        headers: {
                            Authorization: `Bearer ${authToken["id_token"]}`
                        }
                    }
                ).then((downloadLink) => {
                    // guardar garabato en localStorage
                    localStorage.setItem("signatureImg", downloadLink['data']['body'])
                    setIsLoading(false)
                })

            }
            );
        } catch (e) {
            alert("Error al subir la firma")
            notifyHB("custom-signature", "CustomSignature", e)
        }

    }

    let step

    switch (componentState) {
        case 0:
            step = <DrawSignature
                cleanCanvas={cleanCanvas}
                canvasDraw={canvasDraw}
                setImage={setImage}
                dataURItoBlob={dataURItoBlob}
                setBlobData={setBlobData}
                nextStep={nextStep}
            />
            break // step 0

        case 1:
            step = <ConfirmSignature
                image={image}
                returnStep={returnStep}
                nextStep={nextStep}
            />
            break // step 1

        case 2:
            step = <UploadSignature
                isLoading={isLoading}
                saveImg={saveImg}
            />

    }


    return (
        <ComponentContainer>

            <CustomSignatureSteps componentState={componentState} setComponentState={setComponentState} />
            {step}

        </ComponentContainer>

    )
}

export default CustomSignature
