import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ProfileContentForm from './components/ProfileContentForm'
import ProfileContentView from './components/ProfileContentView'
import ProfileSidebar from './components/ProfileSidebar'
import ProfilePI from './ProfilePI'
import ProfileCI from './ProfileCI'
import ProfileDI from './ProfileDI'
import TwoFA from '../request-signature/TwoFA'
import OtpComponent from '../request-signature/OtpComponent'
import RegisterConfirmation from '../request-signature/RegisterConfirmation'
import endpointConfig from '../../config/endpointConfig'

interface ProfileProps{
    refreshToken: Function
}

const Profile: React.FC<ProfileProps> = ({refreshToken}) => {

    refreshToken()

    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    // switch between components (componentState & step)
    const [componentState, setComponentState] = useState(0)
    let step;

    // informacion personal
    const [name, setName] = useState("")
    const [lastName, setLastName] = useState("")
    const [countryOfBirth, setCountryOfBirth] = useState("")
    const [nit, setNit] = useState("")
    const [supportId, setSupportId] = useState("")

    // Documento de informacion
    const [documentType, setDocumentType] = useState("")
    const [documentNumber, setDocumentNumber] = useState("")
    const [countryEmission, setCountryEmission] = useState("")
    const [expirationDate, setExpirationDate] = useState("")

    //informacion de contacto
    const [email, setEmail] = useState("")
    const [phoneCountryCode, setPhoneCountryCode] = useState("")
    const [phone, setPhone] = useState("")

    const [case5Message,] = useState("La autenticación de 2 pasos (2FA) fue configurada correctamente")

    useEffect(() => {
        const personalInformation = async () => {
            const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');
            const authToken: string = getToken['id_token'];

            let manageResponse = await axios.post(
                endpointConfig.user,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }
            ).catch(err => {
                console.log('Error al obtener información del usuario')
            })

            setName(manageResponse?.data.clientInformation.first_name)
            setLastName(manageResponse?.data.clientInformation.last_name)
            setCountryOfBirth(manageResponse?.data.clientInformation.country_of_birth)
            setNit(manageResponse?.data.clientInformation.nit)
            setSupportId(manageResponse?.data.clientInformation.support_id)
            setEmail(manageResponse?.data.clientInformation.email)
            setPhoneCountryCode(manageResponse?.data.clientInformation.phone_country_code)
            setPhone(manageResponse?.data.clientInformation.phone_number)
            setExpirationDate(manageResponse?.data.clientInformation.document_date_of_expiration)
            setCountryEmission(manageResponse?.data.clientInformation.document_issuing_country)
            setDocumentNumber(manageResponse?.data.clientInformation.document_number)
            setDocumentType(manageResponse?.data.clientInformation.document_type)

        }
        personalInformation()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const nextStep = () => {
        setComponentState(componentState + 1)
    }

    switch (componentState) {
        case 0:
            step = <ProfilePI
                name={name}
                lastName={lastName}
                countryOfBirth={countryOfBirth}
                nit={nit}
                supportId={supportId}
            />
            break // step 0
        case 1:
            step = <ProfileDI
                documentType={documentType}
                documentNumber={documentNumber}
                countryEmission={countryEmission}
                expirationDate={expirationDate}
            />
            break // step 1
        case 2:
            step = <ProfileCI
                email={email}
                phoneCountryCode={phoneCountryCode}
                setPhoneCountryCode={setPhoneCountryCode}
                phoneNumber={phone}
                setPhone={setPhone}
            />
            break // step 2

        case 3:
            step = <TwoFA nextStep={nextStep} />
            break // step 3

        case 4:
            step = <OtpComponent nextStep={nextStep} />
            break // step 4

        case 5:
            step = <RegisterConfirmation 
                        confirmationMessage = {case5Message} 
                        displayButton = {false}
                    />
            break // step 5
    }

    return (
        <ProfileContentView>
            <ProfileSidebar setComponentState={setComponentState} />
            <ProfileContentForm>

                {step}

            </ProfileContentForm>
        </ProfileContentView>
    )
}

export default Profile
