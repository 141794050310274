import React from 'react'

interface DisabledInputProps {
    inputLabel: string
    inputValue: string
    maxWidth?: string
}

const DisabledInput: React.FC<DisabledInputProps> = ({ inputLabel, inputValue, maxWidth }) => {

    return (
        <div className="cyber-input" style={{ maxWidth: maxWidth }}>
            <label className="cyber-input__label">{inputLabel}</label>
            <input type="text" className="cyber-input__textfield" value={inputValue} disabled />
        </div>
    )
}

export default DisabledInput
