import axios from 'axios'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import endpointConfig from '../../config/endpointConfig'
import notifyHB from '../../config/notifyHB'

// image
import imgDocumentToSign from "../../images/img_documents_to_sign.svg"
import imgSignatureLoader from "../../images/img_signature_loader.svg"

interface SigningDocumentProps {
    userSub: string
    pdfName: string
    fileULID: string
    otpInputValue: string
    certSerial: string
    setDownloadLink: Function
    nextStep: Function
    errorStep: Function
    signaturePage: number
    hasDate: boolean
    hasInitials: boolean
    hasMotive: boolean
    hasSignature: boolean
    motiveOfSignature: string
    setHasDate: Function
    setHasInitials: Function
    setHasMotive: Function
    setHasSignature: Function
    setMotiveOfSignature: Function
    pdfRelativePositionWidth: number
    pdfRelativePositionHeight: number
    setPdfRelativePositionWidth: Function
    setPdfRelativePositionHeight: Function
}

const SigningDocument: React.FC<SigningDocumentProps> = ({
    userSub,
    pdfName,
    fileULID,
    otpInputValue,
    certSerial,
    setDownloadLink,
    nextStep,
    errorStep,
    signaturePage,
    hasDate,
    hasInitials,
    hasMotive,
    hasSignature,
    motiveOfSignature,
    setHasDate,
    setHasInitials,
    setHasMotive,
    setHasSignature,
    setMotiveOfSignature,
    pdfRelativePositionWidth,
    pdfRelativePositionHeight,
    setPdfRelativePositionWidth,
    setPdfRelativePositionHeight
}) => {

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    const getFromLocalStorage = (itemName: string) => {
        return JSON.parse(localStorage.getItem(itemName)!);
    }

    const handleSignPdf = async () => {
        const getTokenOpt: UserInfo = getFromLocalStorage("user-info");
        const authTokenOpt: any = getTokenOpt['id_token'];

        let sendingMotive = ""

        if (motiveOfSignature === ""){
            sendingMotive = "Unspecified"
        } else {
            sendingMotive = motiveOfSignature
        }

        // opciones de visualizacion
        const body = {
            "certificate_serial": certSerial,
            "fileULID": fileULID,
            "hasDate": hasDate,
            "hasInitials": hasInitials,
            "hasMotive": hasMotive,
            "hasSignature": hasSignature,
            "OTP": otpInputValue,
            "pageToSign": signaturePage,
            "positionInPage": [pdfRelativePositionWidth, pdfRelativePositionHeight],
            "typeOfSignature": "PKCS7",
            "motiveOfSignature": sendingMotive
        }

        const signPdf = await axios.post(
            endpointConfig.signPdf,
            body,
            {
                headers: {
                    Authorization: `Bearer ${authTokenOpt}`
                }
            }
        ).catch(error => {
            notifyHB("upload-document", "SigningDocument", error)
            errorStep()
        })

        try {
            if (signPdf) {
                if (signPdf["data"]["successful"] === true || signPdf["data"]["failureReasonsList"][0] === "The document state is completed") {
                    
                    let req_sub: string = userSub
                    let req_ULID: string = fileULID
                    let pdf_name: string = pdfName
                    let req_object: string = req_sub + "/" + req_ULID + "/" + pdf_name

                    const downloadLink = await axios.post(
                        endpointConfig.docsDownload,
                        { "req_obj": req_object },
                        {
                            headers: {
                                Authorization: `Bearer ${authTokenOpt}`
                            }
                        }
                    )


                    setDownloadLink(downloadLink['data']['body'])
                    setHasDate(false)
                    setHasInitials(false)
                    setHasMotive(false)
                    setHasSignature(false)
                    setMotiveOfSignature("")
                    setPdfRelativePositionHeight(0.0)
                    setPdfRelativePositionWidth(0.0)
                    nextStep()

                }

                // regresar en caso de error
                else {
                    errorStep()
                }
            }

        } catch (e) {
            alert("Error al firmar el documento")
            notifyHB("upload-document", "SigningDocument", e)
        }

    }

    useEffect(() => {
        handleSignPdf()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>

            <div className="cyber-card bordered align-center direction-column">
                <div className="cyber-spacer small"></div>
                <Link to="#">
                    <div className="cyber-signature-loader">
                        <div className="cyber-signature-loader__documents">
                            <img src={imgDocumentToSign} alt="" />
                        </div>
                        <div className="cyber-signature-loader__sign">
                            <img src={imgSignatureLoader} alt="" />
                        </div>
                    </div>
                </Link>
                <div className="cyber-spacer small"></div>
                <h3>Firmando documento...</h3>
                <div className="cyber-spacer small"></div>
            </div>

            <div className="cyber-spacer small"></div>

        </div>
    )
}

export default SigningDocument
