import React from 'react'
import ProfileInformationContent from './components/ProfileInformationContent'
import DisabledInput from './components/DisabledInput'
import { Link } from 'react-router-dom'
interface DisabledInputProps {
    name: string
    lastName: string
    countryOfBirth: string
    nit: string
    supportId: string
}

const ProfilePI: React.FC<DisabledInputProps> = ({ name, lastName, countryOfBirth, nit, supportId }) => {
    return (
        <div>
            <ProfileInformationContent>
                <h4>Información personal</h4>
                <div className="cyber-spacer small"></div>
                <DisabledInput inputLabel="Nombres" inputValue={name} />
                <DisabledInput inputLabel="Apellido" inputValue={lastName} />
                <DisabledInput inputLabel="País de nacimiento" inputValue={countryOfBirth} />
                <DisabledInput inputLabel="Nit" inputValue={nit} />
                <DisabledInput inputLabel="Support Id" inputValue={supportId} />
                <Link to="#" className="cyber-button" onClick={() => {navigator.clipboard.writeText(supportId)}}>
                    Copiar Support Id en portapapeles
                </Link>
            </ProfileInformationContent>
        </div>
    )
}

export default ProfilePI
