import React from 'react'
import DelayRedirect from './DelayRedirect';

function RedirectLogin() {

    return (
        <div className="login-loading">
            <DelayRedirect to={'/dashboard'} delay={2800} />
        </div>
    )
}

export default RedirectLogin
