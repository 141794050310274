import React from 'react'

interface CyberCheckboxProps {
    checkboxLabel: string
    currentValue: boolean
    setCheckedValue: Function
}

const CyberCheckbox: React.FC<CyberCheckboxProps> = ({
    checkboxLabel,
    currentValue,
    setCheckedValue
}) => {

    const checkboxStatus = (event: boolean) => {
        setCheckedValue(event)
    }

    return (
        <div>
            <div className="cyber-input">
                <input type="checkbox" checked={currentValue} onChange={event => {
                            checkboxStatus(event.target.checked);
                        }} />
                <label>{checkboxLabel}</label>
            </div>
        </div>
    )
}

export default CyberCheckbox
