import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ComponentContainer from '../../components/component-container/ComponentContainer'
import axios from 'axios';
import './Contact.css'
import endpointConfig from '../../config/endpointConfig';

export default function Contact() {
  // useStates
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("Enviar");

  // regex para verificar si es un correo => ejemplo@ejemplo.ejemplo
  const isEmail = (emailInput: string) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(emailInput);
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setStatus("Enviando...");

    if (name.trim().length === 0 || email.trim().length === 0 || message.trim().length === 0) {
      alert("No todos los campos estan llenos")
      setStatus("Enviar");
    } else {

      if (isEmail(email.trim())) {
        try {
          let contactResponse = await axios.post(
            endpointConfig.userSupport,
            {
              "user_name": name.trim(),
              "sender": email.trim(),
              "message": message.trim()
            }
          )

          if (contactResponse.status === 200) {
            alert("Mensaje enviado")
            setStatus("Enviar");
          }

          else {
            alert("Hubo un error al enviar el correo")
            setStatus("Enviar");
          }

        } catch (error: any) {
          alert("Hubo un error al enviar el correo");
          setStatus("Enviar");
        }

        setStatus("Enviar");
      }

      else {
        alert("Formato de correo no válido")
        setStatus("Enviar");
      }


    }
  };

  return (
    <ComponentContainer>
      <h1>Contacto</h1>
      <div className="cyber-spacer"></div>
      <div className="cyber-spacer"></div>
      <p>Si tienes algun comentario, sugerencia o queja puedes hacernosla saber llenando el siguiente formulario o contactarnos a <a href="mailto:contacto@cybersign.gt">contacto@cybersign.gt</a></p>
      <div className="cyber-spacer"></div>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            className="inputLayout_contact"
            type="text"
            onChange={event => setName(event.target.value)}
            placeholder="Ingresa tu nombre"
            required
          />
        </div>
        <div className="cyber-spacer"></div>
        <div className="cyber-spacer"></div>
        <div>
          <input
            className="inputLayout_contact"
            type="email"
            onChange={event => setEmail(event.target.value)}
            placeholder="Ingresa tu correo"
            required
          />
        </div>
        <div className="cyber-spacer"></div>
        <div className="cyber-spacer"></div>
        <div>
          <textarea
            className="cyber-input__textfield_contact"
            onChange={event => setMessage(event.target.value)}
            placeholder="Ingresa tu mensaje"
            required
          />
        </div>
        <div className="cyber-spacer"></div>
        <div className="cyber-spacer"></div>
        <Link to="#" className="cyber-button icon-left" onClick={handleSubmit}>
          {status}
        </Link>
      </form>
    </ComponentContainer>
  )
}