// validar nit con regex
// https://jsfiddle.net/miguelerm/j54bt1yk/
const validateNit = (nitString: string) => {
    let nitRegExp = /^\d+(-?[0-9kK])?$/

    if (nitRegExp.test(nitString)) {
        // Removing '-' if is added on input
        let parsedNit: string = nitString.replace(/-/, '')
        let lastChar: number = parsedNit.length - 1
        let number: string = parsedNit.substring(0, lastChar)
        let expectedCheker: string = parsedNit.substring(lastChar, lastChar + 1).toLowerCase()

        var factor = number.length + 1
        var total = 0

        for (var i = 0; i < number.length; i++) {
            var character = number.substring(i, i + 1)
            var digit = parseInt(character, 10)

            total += (digit * factor)
            factor = factor - 1
        }

        var modulus = (11 - (total % 11)) % 11
        var computedChecker = (modulus === 10 ? "k" : modulus.toString())

        return expectedCheker === computedChecker
    } else {
        return false
    }
}

export default validateNit