import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import routeConfig from '../../config/routeConfig';
import docValImage from '../../images/documents-validation-2.png'

interface ProductValidateProps {
    handleClose: Function
}

const ProductValidate: React.FC<ProductValidateProps> = ({ handleClose }) => {

    const history = useHistory();

    return (
        <div>
            <div className="moveRight moveDown">
                <button onClick={() => handleClose()}>
                    <CloseIcon />
                </button>
            </div>

            <div>
                <h3>Validación  de firma</h3>
                <br/>
                <p>¿Cómo sabes que un documento "electrónicamente firmado" fue realmente firmado?</p>
                <br />
                <p>
                    La validación de documentos permite verificar que el documento "firmado" en realidad fue firmado por nuestros servicios.
                    Esto ofrece una segunda capa de seguridad.</p>
                
                <img src={docValImage} alt="" style={{ width: "100%" }} />

                <p>Verifica tus documentos aquí por tí mismo</p>
                <button style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                }} className="cyber-button" onClick={() => history.push(routeConfig.verifyDocument)}>Continuar</button>
            </div>
        </div>
    )
}

export default ProductValidate