import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Honeybadger, HoneybadgerErrorBoundary} from '@honeybadger-io/react';

const config = {
  apiKey: 'hbp_1PTiRdvwd8ahgstUR2Qpoyry2zW2dc0PgQ0G',
  environment: 'production'
}

const honeybadger = Honeybadger.configure(config)

ReactDOM.render(
  <HoneybadgerErrorBoundary honeybadger={honeybadger}>
    <App />
  </HoneybadgerErrorBoundary>, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
