import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import routeConfig from '../../config/routeConfig'
import doubleCheck from "../../images/icon_lock_confirmation_double_check.svg"

interface RegisterConfirmationProps {
    confirmationMessage: string
    displayButton: boolean
}

const RegisterConfirmation: React.FC<RegisterConfirmationProps> = ({
    confirmationMessage,
    displayButton
}) => {
    const history = useHistory();
    return (
        <div className="centerLoading">
            <div className="cyber-spacer small"></div>
            <img src={doubleCheck} alt="" />
            <div className="cyber-spacer medium"></div>
            <h4>{confirmationMessage}</h4>
            <div className="cyber-spacer medium"></div>
            {displayButton
                ? <Link
                    to="#"
                    className="cyber-button full"
                    onClick={() => history.push(routeConfig.dashboard)}
                > Continuar
                </Link>
                : null
            }
        </div>
    )
}

export default RegisterConfirmation
