import React, { MutableRefObject } from 'react'
import CanvasDraw from 'react-canvas-draw'
interface DrawSignatureProps {
    cleanCanvas: Function
    canvasDraw: MutableRefObject<null>
    setImage: Function
    dataURItoBlob: Function
    setBlobData: Function
    nextStep: Function
}

const DrawSignature: React.FC<DrawSignatureProps> = ({
    cleanCanvas,
    canvasDraw,
    setImage,
    dataURItoBlob,
    setBlobData,
    nextStep
}) => {

    const getCanvas = () => {
        let canvas = document.getElementsByTagName('canvas')
        let signature = canvas[1]

        let ctx = canvas[2].getContext('2d');

        // se utiliza context para crear un canvas en blanco en ctx
        ctx!.beginPath()
        ctx!.fillStyle = 'white';
        ctx!.fillRect(0, 0, 300, 150);

        // ya con el canvas en blanco, se coloc encima signature (el garabato)
        ctx!.drawImage(signature, 0, 0, 300, 150);

        let imagen = canvas[2].toDataURL()

        var dataUrl = canvas[2].toDataURL("image/jpg");
        var blob = dataURItoBlob(dataUrl);

        setImage(imagen)
        setBlobData(blob)
        nextStep()
    }

    return (
        <div>
            <br />

            <div className="centerContent">
                <div>
                    <button onClick={() => cleanCanvas()} className="cyber-button separateButton" >
                        Borrar Firma
                    </button>

                    <button className="cyber-button" onClick={() => getCanvas()}>Guardar Firma</button>

                </div>

                <br />

                <div>
                    <CanvasDraw
                        className="borderCanvas"
                        backgroundColor="white"
                        canvasWidth={300}
                        canvasHeight={150}
                        hideGrid={true}
                        brushColor="#000000"
                        brushRadius={2}
                        lazyRadius={1.5}
                        ref={canvasDraw}
                    />
                </div>

            </div>

            <br />
        </div>
    )
}

export default DrawSignature
