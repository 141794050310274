import { Honeybadger } from "@honeybadger-io/react";

function notifyHB(pageName: string, componentName: any, error: any | object) {
    let loginData = JSON.parse(localStorage.getItem("login-data")!)

    if (loginData !== null) {
        Honeybadger.setContext({
            user_id: loginData['sub'],
            user_email: loginData["email"]
        })
    }

    Honeybadger.notify(error, {
        tags: `${pageName}, ${componentName}`
    });
    
}

export default notifyHB;