import React from 'react'

interface ConfirmProcessProps {
    confirmStateNumber: number
    confirmStateName: string
    setComponentState: Function
}

const ConfirmProcess: React.FC<ConfirmProcessProps> = ({confirmStateNumber, confirmStateName, setComponentState}) => {
    return (
        <div>
            
            <p>¿Seguro que desea {confirmStateName} la firma?</p>

            <div>
                <button onClick={() => setComponentState(0)} className="cyber-button separateOptions buttonWidth" >
                    No
                </button>

                <button onClick={() => setComponentState(confirmStateNumber)} className="cyber-button buttonWidth">
                    Sí
                </button>

            </div>

        </div>
    )
}

export default ConfirmProcess
