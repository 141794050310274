import React from 'react'
import { Link } from 'react-router-dom'

// images
import authLogo from "../../images/google_authenticator_logo.png"
import appStoreBadge from "../../images/download_on_the_app_store_badge.png"
import googlePlayBadge from "../../images/get_it_on_google_play_badge.png"

interface RegisterProps {
    nextStep: Function
}

const TwoFA: React.FC<RegisterProps> = ({ nextStep }) => {
    return (
        <div>
            <img src={authLogo} alt="" />
            <div className="cyber-spacer small"></div>
            <h4>Configuración de autenticación en 2 pasos (2FA)</h4>
            <div className="cyber-spacer small"></div>
            <p>Descarga la aplicación <b>Google Authenticator o Microsoft Authenticator</b> en tu celular. El app nos sirve para verificar tu identidad al momento de firmar documentos y realizar otras acciones que requieren de seguridad adicional.</p>
            <p>Una vez la tengas instalada el app en tu celular, haz click en continuar.</p>
            <div className="cyber-2fa-badges">
                <div className="cyber-2fa-badges__ios" ><img src={appStoreBadge} height="40" alt="" /></div>
                <div ><img src={googlePlayBadge} height="40" alt="" /></div>
            </div>
            <Link to="#" className="cyber-button full" onClick={ () => nextStep() }>Continuar</Link>
        </div>
    )
}

export default TwoFA
