import { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router";
import { AuthContext } from "../components/auth-context/auth-context";
import routeConfig from "./routeConfig";

// @ts-ignore
export const ProtectedRoute = ({ component: Component, userNameC, ...rest }) => {

    const location = useLocation()

    const authContext = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={props => {
                if (authContext.isAuth || localStorage.getItem("login-test") !== null) {
                    if (localStorage.getItem("user-name") === undefined) {
                        if (location.pathname.match(routeConfig.dashboard) || location.pathname.match(routeConfig.requestSignature) || location.pathname.match(routeConfig.myProcesses) ) {
                            return <Component {...props} />
                        } else {
                            return <Redirect to={{
                                pathname: routeConfig.dashboard,
                                state: {
                                    from: props.location
                                }
                            }}
                            />
                        }
                    } else {
                        return <Component {...props} />
                    }
                } else {
                    return <Redirect to={{
                        pathname: "/",
                        state: {
                            from: props.location
                        }
                    }}
                    />
                }
            }}
        />
    )
}