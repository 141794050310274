import React from 'react'
import { Link } from 'react-router-dom'
import CheckIcon from '@material-ui/icons/Check'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'


interface RenewCProps {
    setConfirmStateName: Function
    setConfirmStateNumber: Function
    setComponentState: Function
}

const RenewComponent: React.FC<RenewCProps> = ({ setConfirmStateName, setConfirmStateNumber, setComponentState }) => {
    return (
        <div>
            <Link to="#" className="cyber-list__item success" onClick={() => {
                setConfirmStateName("renovar")
                setConfirmStateNumber(1)
                setComponentState(7)
            }}>
                <div className="cyber-list__item-icon-left">
                    <CheckIcon />
                </div>
                <div className="cyber-list__item-content">
                    <b>Renovar</b>
                    <span>Renueva esta firma.</span>
                </div>
                <ChevronRightIcon className="cyber-list__item-icon-right material-icons" />
            </Link>
        </div>
    )
}

export default RenewComponent
