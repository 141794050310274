import React from 'react'

interface CyberInputProps {
    inputLabel: string
    numValue: number
    setValue: Function
    validInput: boolean
    disabled?: boolean
}

const NumberInput: React.FC<CyberInputProps> = ({ inputLabel, numValue, setValue, validInput, disabled=false }) => {
    return (
        <div className="cyber-input">
            <label className="cyber-input__label">{inputLabel}</label>
            <input 
                type="number"
                disabled={ disabled }
                className="cyber-input__textfield" 
                style={validInput ? { borderColor: '#dbdbdf' } : { borderColor:'red' }} 
                onChange={
                event => {
                    setValue(event.target.value)
                }
            } value={numValue === 0 ? "" : numValue} />
        </div>
    )
}

export default NumberInput
