import React, { useState } from 'react';
import { CognitoToken } from '../code-exchange/CodeExchange';
import { useCookies } from 'react-cookie';

type AuthContextObj = {
    isAuth: boolean,
    toggleAuth: () => void,
    saveToken: (token: CognitoToken) => void
}

// types
type UserInfo = {
    id_token: string;
    access_token: string;
    refresh_token: string;
    expires_in: number;
    token_type: string;
}

export const AuthContext = React.createContext<AuthContextObj>({
    isAuth: false,
    toggleAuth: () => {
        // intentional empty function
    },
    saveToken: () => {
        // intentional empty function
    }
})


const AuthContextProvider: React.FC = (props) => {

    // cookies
    const [userInfoC, setUserInfoC] = useCookies(["userInfo"])
    const [, setTokenExpC] = useCookies(["tokenExpirDate"])


    const tknChecker = () => {

        if (userInfoC.userInfo === undefined){
            let sessionTkn: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}')
            const b = localStorage.getItem("token-expir-date") || '0'
            return sessionTkn.access_token && (Date.now() / 1000 < parseInt(b)) ? true : false

        } else {
            const b = localStorage.getItem("token-expir-date") || '0'
            return userInfoC.userInfo.access_token && (Date.now() / 1000 < parseInt(b)) ? true : false
        }
        
    }

    const [isAuth, setIsAuth] = useState(tknChecker());

    const toggleAuth = () => {
        isAuth ? setIsAuth(false) : setIsAuth(true)
    }

    const saveToken = (token: CognitoToken) => {

        localStorage.setItem("user-info", JSON.stringify(token))
        setUserInfoC("userInfo", token, 
            { 
                domain: process.env.REACT_APP_DOMAIN,
                path: "/" ,
                secure: true,
                sameSite: "strict"
            }
        )

        let expDate = Math.round(Date.now() / 1000) + token.expires_in

        localStorage.setItem("token-expir-date", (expDate).toString())
        setTokenExpC("tokenExpirDate", (expDate), 
            { 
                domain: process.env.REACT_APP_DOMAIN,
                path: "/" ,
                secure: true,
                sameSite: "strict",
            }
        )
        setIsAuth(true);
    }

    const contextValue: AuthContextObj = {
        isAuth,
        toggleAuth,
        saveToken
    }

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContextProvider;
