import React, { useState } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link } from 'react-router-dom';
import axios from 'axios';
import CertificateInformation from '../check-certificates/CertificateInformation';
import SmallSpinner from '../../components/spinner/SmallSpinner';
import endpointConfig from '../../config/endpointConfig';
import notifyHB from '../../config/notifyHB';

interface DocumentInformationProps {
    returnStep: Function
    documentName: string
    signatureDate: string
    signerCredentials: string
    base64Hash: string
    signedCyberSign: any
}

const DocumentInformation: React.FC<DocumentInformationProps> = ({
    returnStep,
    documentName,
    signatureDate,
    signerCredentials,
    base64Hash,
    signedCyberSign
}) => {

    type CheckResponse = {
        caSerial: string,
        certType: string,
        certificateStatus: string,
        createdAt: string,
        expirationDate: string,
        emailDeclared: string,
        serial: string
        revocationTimeStamp: string,
        suscriberName: string
    }

    const [certInformation, setCertInformation] = useState(<div></div>)
    const [displayButton, setDisplayButton] = useState(true)
    const [setLoading, setSetLoading] = useState(false)

    const returnCertificateStatus = (certStatus: string) => {
        const statuses: any = {
            "0": "unspecified",
            "1": "Key Compromise",
            "2": "cA Compromise",
            "3": "Affiliation Changed",
            "4": "Superseded",
            "5": "Cessation Of Operation",
            "6": "Suspended",
            "8": "Remove From CRL",
            "9": "privilegeWithdrawn",
            "10": "aACompromise"
        }

        //certificateHold (6)

        if (statuses.hasOwnProperty(certStatus)) {
            return statuses[certStatus];
        } else {
            return certStatus;
        }

    }

    const getCertInformation = async () => {

        setSetLoading(true)

        try {
            const certcheckResponse = await axios.post(
                endpointConfig.certcheck,
                {
                    "publicKeyHashHex": base64Hash,
                    "certificate_serial": signerCredentials
                }
            )

            let certificateInformation: CheckResponse = JSON.parse(certcheckResponse.data.certificateInformation)

            setCertInformation(
                <CertificateInformation

                    caSerial={certificateInformation["caSerial"]}
                    certType={certificateInformation["certType"]}
                    certificateStatus={returnCertificateStatus(certificateInformation["certificateStatus"])}
                    createdAt={certificateInformation["createdAt"]}
                    expirationDate={certificateInformation["expirationDate"]}
                    emailDeclared={certificateInformation["emailDeclared"]}
                    serial={certificateInformation["serial"]}
                    revocationTimeStamp={certificateInformation["revocationTimeStamp"]}
                    suscriberName={certificateInformation["suscriberName"]}

                />
            )
            setSetLoading(false)
            setDisplayButton(false)
        } catch (err) {
            alert("Hubo un error en la validación del documento")
            notifyHB("verify-document", "DocumentInformation", err)
            returnStep()
        }


    }

    return (
        <div>

            <div className="cyber-spacer medium"></div>

            <div className="cyber-document-to-sign cyber-card">
                <div className="cyber-document-to-sign__info">
                    <span>Documento:</span> <br />
                    <span className="cyber-document-to-sign__filename">{documentName}</span>
                </div>

            </div>

            <div className="cyber-spacer medium"></div>

            <div className="cyber-flex">
                <hr />
                <div className="cyber-block col6">
                    <span className="cyber-type__label"><b>Fecha de firma:</b></span>
                </div>
                <div className="cyber-block col6">
                    <span>{signatureDate}</span>
                </div>
                <hr />
                <div className="cyber-block col6">
                    <span className="cyber-type__label"><b>Número de serie del certificado firmante:</b></span>
                </div>
                <div className="cyber-block col6">
                    <span>{signerCredentials}</span>
                </div>
                <hr />
                <div className="cyber-block col6">
                    <span className="cyber-type__label"><b>Certificado registrado:</b></span>
                </div>
                <div className="cyber-block col6">
                    {signerCredentials !== "" ? signedCyberSign : <SmallSpinner/>}
                </div>
                <hr />
            </div>

            <div className="justify-center">
                <br />

                {displayButton
                    ?
                    <div>
                        <button className="cyber-button" onClick={() => getCertInformation()} >Más información</button>
                    </div>
                    : certInformation
                }
            </div>

            {setLoading
                ?
                <div className="justify-center" style={{ marginLeft: "25px" }}>
                    <SmallSpinner />
                </div>
                :
                null
            }

            <div className="cyber-spacer medium"></div>
            <div className="justify-center">
                <Link className="cyber-button link" to="#" onClick={() => returnStep()}>
                    <ArrowBackIcon className="material-icons" /> Regresar
                </Link>
            </div>

        </div>
    )
}

export default DocumentInformation
