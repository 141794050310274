import React, { useEffect, useState } from 'react'
import axios from 'axios';
import queryString from 'query-string';

// css
import "./OtpVerification.css"

// routes
import ComponentContainer from '../../components/component-container/ComponentContainer';
import DisplayQR from '../request-signature/components/DisplayQR';
import LoadingOtpComponent from '../request-signature/components/LoadingOtpComponent';
import RegisterConfirmation from '../request-signature/RegisterConfirmation';
import endpointConfig from '../../config/endpointConfig';
import notifyHB from '../../config/notifyHB';

interface OtpVerificationProps{
    refreshToken: Function
}

const OtpVerification: React.FC<OtpVerificationProps> = ({refreshToken}) => {

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    refreshToken()

    const [qrOtp, setQrOtp] = useState("");
    const [userInput, setUserInput] = useState("");
    const [confirmationMessage,] = useState("Ya tiene configurada correctamente la autenticación de 2 pasos")
    const [qrCodeCmp, setQrCodeCmp] = useState(<LoadingOtpComponent/>);

    const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');

    const [isAuth, setIsAuth] = useState(false);


    const sendOtp = async () => {

        try {
            const body = {
                totp: userInput,
                OTP: qrOtp
            }

            const headers = {
                Authorization: `Bearer ${getToken['id_token']}`
            }

            const otpVerify = await axios.post(endpointConfig.otpVerify, body, { headers })

            if (otpVerify.status === 200){
                setQrCodeCmp(<p></p>)
                setIsAuth(true)
            } else {
                alert("Hubo un error al verificar OTP")
            }

        } catch (error: any) {
            notifyHB("otp-verification", "OtpVerification", error)
        }

    }

    const getOtpResponse = async () => {
        try {
            const otpResponse = await axios({
                method: 'post',
                url: endpointConfig.otp,
                headers: {
                    Authorization: `Bearer ${getToken['id_token']}`
                }
            })

            setQrOtp(otpResponse["data"]["OTP"]);

            const code = queryString.parse(otpResponse["data"]["message"]);

            let secret: string = String(code["otpauth://totp/CyberSign:Firma?secret"]);

            if (secret == "undefined") {
                secret = String(code["otpauth://totp/Celera Test:Cyber Sign?secret"]);
                if (secret == "undefined") { throw new Error("No secret on totp code") }
            }

            setQrCodeCmp(
                <DisplayQR otpDataMessage={otpResponse["data"]["message"]} secret={secret} />
            );

        } catch (error: any) {
            if (error.response && error.response.data.error === "User already has a primary OTP") {
                setQrCodeCmp(<p>Error, contactar a soporte</p>);
                setIsAuth(true)
            } else {
                notifyHB("otp-verification", "OtpVerification", error)
            }
        }

    }

    useEffect(() => {
        getOtpResponse();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ComponentContainer>
            <h1>Verificar OTP</h1>
            <div className="cyber-spacer"></div>

            <div>

                {isAuth ? <RegisterConfirmation
                            confirmationMessage = {confirmationMessage}
                            displayButton = {false}
                            /> :
                    <div>

                        {qrCodeCmp}

                        <div className="centerLoading">

                            <div className="cyber-spacer small"></div>
                            <div className="cyber-form">
                                <div className="cyber-input icon-left">
                                    <label className="cyber-input__label">Ingresa el código OTP para verificar tu cuenta</label>
                                    <input type="text" className="center-otp-verification"  placeholder=" XXX XXX" onChange={event => setUserInput(event.target.value)} />
                                </div>
                                <button
                                    className="cyber-button"
                                    onClick={() => sendOtp()}
                                >Ingresar</button>
                            </div>

                        </div>

                    </div>
                }

            </div>
        </ComponentContainer>
    )
}

export default OtpVerification
