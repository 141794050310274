import { motion } from 'framer-motion';
import React, { useState } from 'react'
import ComponentContainer from '../../components/component-container/ComponentContainer';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import SignatureRequirements from './SignatureRequirements';
import imgSignatureLoader from "../../images/img_signature_loader.svg"
import imgDocumentToSign from "../../images/img_documents_to_sign.svg"
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SearchIcon from '@material-ui/icons/Search';


import "./Requirements.css"
import ConsultRequirements from './ConsultRequirements';
import ValidateDocumentRequirements from './ValidateDocumentRequirements';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'fixed',
            inset: 0,
            display: 'grid',
            placeItems: 'center',
            overflow: 'auto',
            padding: '24px',
            zIndex: 100
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: "8px",
            width: "400px"
        },
    }),
);

const Requirements = () => {

    const classes = useStyles();

    // determinar si el modal puede verse o no
    const [open, setOpen] = React.useState(false);

    // useState que se edita al modal que se necesita (basado en la seleccion de usuario)
    const [selectedModal, setSelectedModal] = useState(<div></div>)

    const handleClose = () => {
        setOpen(false);
    };

    // se edita selected Modal para mostrar los requisitos de la opcion elegida
    const selectModal = (requirementOption: JSX.Element) => {
        setSelectedModal(requirementOption)
        setOpen(true)
    }



    return (
        <ComponentContainer>
            <h1>Requisitos</h1>
            <div className="cyber-spacer medium"></div>
            
            <p>Los procesos de firma electrónica son rápidos, sencillos y seguros para el usuario.</p>

            <div className="cyber-grid three-columns">

                <motion.div
                    className="cyber-card bordered flex direction-column justify-between align-start"
                    whileHover={{
                        scale: 1.1
                    }}
                    onClick={() => selectModal(<SignatureRequirements handleClose={handleClose} />)}

                >
                    <h5>Productos de firma electrónica</h5>
                    <div style={{ position: "relative", top: "15px", right: "-30px" }}>
                        <div>
                            <img style={{ position: "relative", width: "200px", top: "10px", padding: "0 0 0 0" }} src={imgDocumentToSign} alt="" />
                        </div>
                        <div>
                            <img style={{ position: "relative", width: "75px", top: "-88px", right: "-80px", padding: "0 0 0 0" }} src={imgSignatureLoader} alt="" />
                        </div>
                    </div>

                </motion.div>

                <motion.div
                    className="cyber-card bordered flex direction-column justify-between align-start"
                    whileHover={{
                        scale: 1.1
                    }}
                    onClick={() => selectModal(<ValidateDocumentRequirements handleClose={handleClose}/>)}
                >
                    <h5>Validación de documentos</h5>
                    <div className="cyber-spacer medium"></div>
                    <div style={{ width: "250px", height: "200px" }}>
                        <DoneAllIcon style={{
                            position: "relative",
                            right: "-130px",
                            top: "40px",
                            transform: "scale(8)"
                        }} />
                    </div>


                </motion.div>

                <motion.div
                    className="cyber-card bordered flex direction-column justify-between align-start"
                    whileHover={{
                        scale: 1.1
                    }}
                    onClick={() => selectModal(<ConsultRequirements handleClose={handleClose}/>)}
                >
                    <h5>Consultar Certificado</h5>
                    <div className="cyber-spacer medium"></div>
                    <div style={{ width: "250px", height: "200px" }}>
                        <SearchIcon style={{
                            position: "relative",
                            right: "-130px",
                            top: "45px",
                            transform: "scale(8)"
                        }} />
                    </div>


                </motion.div>


            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        {selectedModal}
                    </div>
                </Fade>
            </Modal>

        </ComponentContainer>
    )
}

export default Requirements
