import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CyberInputComponent from './components/CyberInputComponent'

interface RegisterProps {
    nextStep: Function
    setUserEmail: Function
    userPhoneCountryCode: string
    setUserPhoneCountryCode: Function
    userPhone: string
    setUserPhone: Function
    onboardingLS: Function
}



const ContactInfo: React.FC<RegisterProps> = ({
    nextStep,
    setUserEmail,
    userPhoneCountryCode,
    setUserPhoneCountryCode,
    userPhone,
    setUserPhone,
    onboardingLS
}) => {

    // useState
    const [fieldsWarning, setFieldsWarning] = useState(<p></p>)

    useEffect(() => {
        onboardingLS()
        type LoginData = {
            sub: string;
            email_verified: string;
            email: string;
            username: string;
        }

        let loginData: LoginData = JSON.parse(localStorage.getItem("login-data") || '{}');
        setUserEmail(loginData.email)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const isCountryCode = (possibleCountryCode: string) => {
        return /^\d{1,3}$/.test(possibleCountryCode)
    }

    const isTelephoneNumber = (possibleNumber: string) => {
        return /^\(?\d{1,4}\)?\s?\d{1,4}\s?\d{1,4}$/.test(possibleNumber)
    }

    const confirmStep = () => {

        let validPhoneCountryCode = isCountryCode(userPhoneCountryCode)
        let validPhone = isTelephoneNumber(userPhone)

        if (userPhone === "" || userPhoneCountryCode === "") {
            setFieldsWarning(<p style={{ color: "red" }}>Por favor ingresar un número de teléfono</p>)
            return
        }

        if (!validPhoneCountryCode || !validPhone) {
            setFieldsWarning(<p style={{ color: "red" }}>El valor ingresado no es un número</p>)
            return
        }

        nextStep()

    }

    return (
        <div>
            <h4>Información de contacto</h4>
            <div className="cyber-spacer"></div>
            <span className="flex">
                <CyberInputComponent
                    inputLabel="Código"
                    placeholder="XXX"
                    value={userPhoneCountryCode}
                    setValue={setUserPhoneCountryCode}
                    validInput={isCountryCode(userPhoneCountryCode)}
                    maxWidth="100px"
                />
                <CyberInputComponent
                    inputLabel="Número de teléfono"
                    placeholder="XXXX XXXX"
                    value={userPhone}
                    setValue={setUserPhone}
                    validInput={isTelephoneNumber(userPhone)}
                />
            </span>
            {fieldsWarning}
            <Link to="#" className="cyber-button full" onClick={() => { confirmStep() }}>Continuar</Link>
        </div>
    )
}

export default ContactInfo
