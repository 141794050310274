const cssConfig = {
    setHeaderTranspartent: function () {
        if (document.getElementById("header") != null) {
            document.getElementById("header")!.style.position = "fixed";
            document.getElementById("header")!.style.width = "100%";
            document.getElementById("header")!.style.padding = "8px 0";
            document.getElementById("header")!.style.background = "transparent";
            document.getElementById("header")!.style.borderBottom = "1px solid transparent";
            document.getElementById("header")!.style.zIndex = "10";
            document.getElementById("header")!.style.display = "flex";
            document.getElementById("header")!.style.transition = "all .3s";
        }

    },
    handleHeaderState: function () {
        if (document.getElementById("header") != null) {
            if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
                document.getElementById("header")!.style.position = "fixed";
                document.getElementById("header")!.style.width = "100%";
                document.getElementById("header")!.style.padding = "8px 0";
                document.getElementById("header")!.style.background = "white";
                document.getElementById("header")!.style.borderBottom = "1px solid #DBDBDF";
                document.getElementById("header")!.style.zIndex = "10";
                document.getElementById("header")!.style.display = "flex";
                document.getElementById("header")!.style.transition = "all .3s";
                document.getElementById("header")!.style.position = "fixed";
    
            } else {
                document.getElementById("header")!.style.position = "fixed";
                document.getElementById("header")!.style.width = "100%";
                document.getElementById("header")!.style.padding = "8px 0";
                document.getElementById("header")!.style.background = "transparent";
                document.getElementById("header")!.style.borderBottom = "1px solid transparent";
                document.getElementById("header")!.style.zIndex = "10";
                document.getElementById("header")!.style.display = "flex";
                document.getElementById("header")!.style.transition = "all .3s";
            }
        }
    },
    handleHeaderSidenav: function () {
        if (document.getElementById("header") != null) {
            if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
                document.getElementById("header")!.style.position = "fixed";
                document.getElementById("header")!.style.padding = "8px 0";
                document.getElementById("header")!.style.background = "white";
                document.getElementById("header")!.style.borderBottom = "1px solid #DBDBDF";
                document.getElementById("header")!.style.zIndex = "10";
                document.getElementById("header")!.style.display = "flex";
                document.getElementById("header")!.style.transition = "all .3s";    
            } else {
                document.getElementById("header")!.style.position = "fixed";
                document.getElementById("header")!.style.padding = "8px 0";
                document.getElementById("header")!.style.background = "transparent";
                document.getElementById("header")!.style.borderBottom = "1px solid transparent";
                document.getElementById("header")!.style.zIndex = "10";
                document.getElementById("header")!.style.display = "flex";
                document.getElementById("header")!.style.transition = "all .3s";
            }
        }
    }

}

export default cssConfig;