import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';


interface ValidateDocumentRequirementsProps {
    handleClose: Function
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const ValidateDocumentRequirements: React.FC<ValidateDocumentRequirementsProps> = ({ handleClose }) => {
    const [expandedDF, setExpandedDF] = useState(false)

    const handleExpandClickDF = () => {
        setExpandedDF(!expandedDF);
    };

    return (
        <div >
            <div className="moveRight moveDown">
                <button onClick={() => handleClose()}>
                    <CloseIcon />
                </button>
            </div>

            <div >
                <h3>Validación de documentos</h3>
                <br />
                <Card sx={{ maxWidth: 345 }}>

                    {/* DPI VALIDO */}
                    <CardActions disableSpacing
                    >
                        <IconButton 
                            aria-label="add to favorites"
                            onClick={handleExpandClickDF}
                        >
                            Documento firmado
                        </IconButton>

                        <ExpandMore
                            expand={expandedDF}
                            onClick={handleExpandClickDF}
                            aria-expanded={expandedDF}
                            aria-label="show more"
                        >
                            <KeyboardArrowDownIcon />
                        </ExpandMore>

                    </CardActions>
                    <Collapse in={expandedDF} timeout="auto" unmountOnExit>

                        <CardContent style={{ maxHeight: 500, overflow: 'auto' }}>
                            <Typography paragraph>
                                <p>
                                    El servicio de validación de documentos permite determinar si el documento fue firmado con nuestros sistemas y provee información importante como número de serie, fecha de firma, fecha de expiración, entre otros.
                                </p> 
                                <p>
                                    Este servicio sólo acepta documentos <b>.pdf</b>
                                </p>
                            </Typography>

                        </CardContent>
                    </Collapse>


                </Card>
            </div>
        </div>
    );
}

export default ValidateDocumentRequirements