import React, { useState } from 'react'
import ComponentContainer from '../../components/component-container/ComponentContainer'
import SmallSpinner from '../../components/spinner/SmallSpinner'
import DocumentInformation from './DocumentInformation'
import VerifyDocumentUpload from './VerifyDocumentUpload'

interface VerifyDocumentProps {
    refreshToken: Function
}

const VerifyDocument: React.FC<VerifyDocumentProps> = ({refreshToken}) => {

    const [componentState, setComponentState] = useState(0)
    const [documentName, setDocumentName] = useState("")
    const [signatureDate, setSignatureDate] = useState("")
    const [signerCredentials, setSignerCredentials] = useState("")
    const [base64Hash, setBase64Hash] = useState("")
    const [signedCyberSign, setSignedCyberSign] = useState(<SmallSpinner/>)

    const nextStep = () => {
        setComponentState(componentState + 1)
    }

    const returnStep = () => {
        setComponentState(componentState - 1)
    }

    let step

    switch (componentState) {
        case 0:
            step = <VerifyDocumentUpload
                nextStep={nextStep}
                setDocumentName={setDocumentName}
                setSignatureDate={setSignatureDate}
                setSignerCredentials={setSignerCredentials}
                setBase64Hash={setBase64Hash}
                setSignedCyberSign={setSignedCyberSign}
            />
            break

        case 1:
            step = <DocumentInformation
                returnStep={returnStep}
                documentName={documentName}
                signatureDate={signatureDate}
                signerCredentials={signerCredentials}
                base64Hash={base64Hash}
                signedCyberSign={signedCyberSign}
            />
            break
    }

    return (
        <ComponentContainer>
            {step}
        </ComponentContainer>
    )
}

export default VerifyDocument
