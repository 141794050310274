import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import ComponentContainer from "../../components/component-container/ComponentContainer";
import imgSignatureLoader from "../../images/img_signature_loader.svg"
import imgDocumentToSign from "../../images/img_documents_to_sign.svg"
import "./Landing.css"
import { AuthContext } from "../../components/auth-context/auth-context";

const Landing = () => {

    const authCtx = useContext(AuthContext);

    return (
        <ComponentContainer>

            <div className="box">
                <div className="part-1">
                    <h1>Bienvenido a CyberSign</h1>
                    <br/>
                    <p>Firmar documentos nunca fue tan fácil</p>
                </div>


                <div className="part-2">
                    <div className="cyber-signature-loader">
                        <div className="cyber-signature-loader__documents">
                            <img className="landingImg" src={imgDocumentToSign} alt="" />
                        </div>
                        <div className="cyber-signature-loader__sign landingImgSignature">
                            <img className="" src={imgSignatureLoader} alt="" />
                        </div>
                    </div>
                </div>

            </div>
            {authCtx.isAuth ? null : <Link to="/ble" className="cyber-button">Login</Link>}
        </ComponentContainer>
    );
}

export default Landing;