import React from 'react'
import { Link } from 'react-router-dom'

// icons
import { RiUpload2Fill } from 'react-icons/ri'
import { MdDescription } from 'react-icons/md'
import { IoMdDownload } from 'react-icons/io'

interface StepsProps {
    componentState: number
    returnStep: Function
}

const SignDocumentSteps: React.FC<StepsProps> = ({ componentState, returnStep }) => {

    let uploadIconColor
    let uploadClassName

    let signIconColor
    let signClassName

    let downloadIconColor
    let downloadClassName

    if (componentState === 0) {
        uploadIconColor = "white"
        uploadClassName = "cyber-steps__item active"

        signIconColor = "gray"
        signClassName = "cyber-steps__item"

        downloadIconColor = "gray"
        downloadClassName = "cyber-steps__item"
    }

    if (componentState > 0 && componentState < 4) {
        uploadIconColor = "white"
        uploadClassName = "cyber-steps__item done"

        signIconColor = "white"
        signClassName = "cyber-steps__item active"

        downloadIconColor = "gray"
        downloadClassName = "cyber-steps__item"
    }

    if (componentState === 4) {
        uploadIconColor = "white"
        uploadClassName = "cyber-steps__item done"

        signIconColor = "white"
        signClassName = "cyber-steps__item done"

        downloadIconColor = "white"
        downloadClassName = "cyber-steps__item active"
    }

    return (
        <div>
            <div className="cyber-steps">

                <Link className={uploadClassName} to="#">
                    <div className="cyber-steps__item-icon">
                        <RiUpload2Fill color={uploadIconColor} size={20} />
                    </div>
                    <span className="cyber-steps__item-label">Subir</span>
                </Link>
                <Link className={signClassName} to="#">
                    <div className="cyber-steps__item-icon">
                        <MdDescription color={signIconColor} size={20} />
                    </div>
                    <span className="cyber-steps__item-label">Firmar</span>
                </Link>
                <Link className={downloadClassName} to="#">
                    <div className="cyber-steps__item-icon">
                        <IoMdDownload color={downloadIconColor} size={20} />
                    </div>
                    <span className="cyber-steps__item-label">Descargar</span>
                </Link>
            </div>
            <div className="cyber-spacer medium"></div>
        </div>
    )
}

export default SignDocumentSteps
