const routeConfig = {
    home: "/",
    dashboard: "/dashboard",
    codeExchange: "/codex",
    legal: "/legal",
    verifyDocument: "/validate-document",
    policies: "/policies-and-procedures",
    certificateAndCrl: "/certificates-crl",
    consultCertificate: "/consult-certificate",
    requestSignature: "/request-signature",
    mySignatures: "/my-signatures",
    uploadDocument: "/upload-document",
    otpVerification: "/otp-verification",
    redirectLogin: "/login-redirect",
    resetOTP: "/reset-otp",
    resetOTP2: "/reset/:resetId",
    loginCognito: "/ble",
    test: "/test",
    profile: "/profile",
    customManuscript: "/custom-manuscript",
    myProcesses: '/my-processes',
    contact: '/contact',
    products: '/products-and-services',
    requirements: '/requirements'
}

export default routeConfig;