import React from 'react'

interface ErrorSignDocumentProps {
    returnOptStep: Function
}

const ErrorSignDocument: React.FC<ErrorSignDocumentProps> = ({returnOptStep}) => {
    return (
        <div>
            <div className="dialog-header">
                <div className="dialog-title">
                    <h3>Error en la verificación</h3>
                </div>
            </div>
            <div className="dialog-body">

                <div>
                    <p>Hubo un error al firmar el documento, inténtalo de nuevo.</p>
                </div>

                <br />

                <button className="cyber-button" onClick={ () => returnOptStep()}>Regresar</button>

            </div>
        </div>
    )
}

export default ErrorSignDocument
