import axios from 'axios';
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';

import endpointConfig from '../../config/endpointConfig';

// routes
import routeConfig from '../../config/routeConfig';

function VerifyNumber(props: { resetId: string }) {

    const [resetFinished, setResetFinished] = useState(false)

    const history = useHistory();

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    const [smsResponse, setSmsResponse] = useState("")

    //onst getToken: UserInfo = userInfoC.userInfo
    const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');
    const authToken: string = getToken['id_token'];


    // reset otp
    const resetOtpCode = async () => {
        localStorage.removeItem("otpResetStep");
        const resetResponse = await axios.post(
            `${endpointConfig.otpReset}/verify/${props.resetId}`, {
            code: smsResponse
        }, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
        );

        if (resetResponse.status === 200){
            setResetFinished(true)
        } else {
            alert("Hubo un error al restablecer el otp")
        }

    }

    return (
        <div>
            <h6>Ingresa código</h6>
            <input
                className="inputLayout"
                type="text"
                placeholder="Ingresar codigo enviado a su telefono"
                onChange={event => setSmsResponse(event.target.value)}
            />
            <button className="cyber-button" onClick={resetOtpCode}>enviar</button>

            {resetFinished ? 
                <div> 
                    <p>proceso realizado</p> 
                    <button className="cyber-button" onClick={() => history.push(routeConfig.otpVerification)}>verificar</button>
                </div> 
                : null
            }

        </div>
    )
}

export default VerifyNumber
