import React from 'react'

interface DisabledSelectProps {
    selectLabel: string
    selectValue: string
}

const DisabledSelect: React.FC<DisabledSelectProps> = ({ selectLabel, selectValue }) => {
    return (
        <div className="cyber-input">
            <label className="cyber-input__label">{selectLabel}</label>
            <select className="cyber-input__textfield" id="country-of-birth" disabled>
                <option value="Guatemala">{selectValue}</option>
            </select>
        </div>
    )
}

export default DisabledSelect
