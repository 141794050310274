import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CyberInputComponent from './components/CyberInputComponent'
import NumberInput from './components/NumberInput'
import validateDocument from './components/validateDocument'
import validateNit from './components/validateNit'

interface RegisterProps {
    nextStep: Function
    documentNumber: number
    setDocumentNumber: Function
    nit: string
    setNit: Function
    onboardingLS: Function
}

const DocumentInfo: React.FC<RegisterProps> = (
    {
        nextStep,
        documentNumber,
        setDocumentNumber,
        nit,
        setNit,
        onboardingLS
    }) => {

    const [fieldsWarning, setFieldsWarning] = useState(<p></p>)
    const [validDocumentNumber, setValidDocumentNumber] = useState(true)
    const [validNit, setValidNit] = useState(true)

    useEffect(() => {
        onboardingLS()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // validar input de numero del documento con cada onChange para mostrar una advertencia previo a realizar submit
    useEffect(() => {
        if (documentNumber !== 0){
            setValidDocumentNumber(validateDocument(documentNumber))
        }
    }, [documentNumber])

    // validar input de nit con cada onChange para mostrar una advertencia previo a realizar submit
    useEffect(() => {
        if (nit !== ""){
            setValidNit(validateNit(nit))
        }
    }, [nit])

    const confirmStep = () => {
        if (documentNumber !== 0 && nit !== "") {
            if (validateDocument(documentNumber) && validateNit(nit)) {
                // Update nit removing the "-" if added
                setNit(nit.replace(/-/, ''))
                nextStep()
            } 

            if (validateDocument(documentNumber) && !validateNit(nit)) {
                setFieldsWarning(<p style={{ color: "red" }}>El nit no es válido</p>)
            }

            if (!validateDocument(documentNumber) && validateNit(nit)) {
                setFieldsWarning(<p style={{ color: "red" }}>El número de documento no es válido</p>)
            }
            if (!validateDocument(documentNumber) && !validateNit(nit)) {
                setFieldsWarning(
                    <div>
                        <p style={{ color: "red" }}>El número de documento no es válido</p>
                        <p style={{ color: "red" }}>El nit no es válido</p>
                    </div>
                )
            }
            
            
        } else {
            setFieldsWarning(<p style={{ color: "red" }}>Por favor llenar todos los campos</p>)
        }
    }

    return (
        <div>
            <h4>Documento de Identificación Personal (DPI)</h4>
            <div className="cyber-spacer"></div>
            <NumberInput 
                inputLabel={"Número de Documento de Identificación Personal (DPI)"}
                numValue={documentNumber} 
                setValue={setDocumentNumber} 
                validInput={validDocumentNumber}
                disabled
            />
            <CyberInputComponent 
                inputLabel={"NIT"} 
                placeholder="" 
                value={nit} 
                setValue={setNit} 
                validInput={validNit}
                disabled
            />
            {fieldsWarning}
            <Link to="#" className="cyber-button full" onClick={() => { confirmStep() }}>Continuar</Link>
        </div>
    )
}

export default DocumentInfo
