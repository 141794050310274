import React, { useEffect } from 'react'
import { useHistory } from 'react-router';
import routeConfig from '../../config/routeConfig';
import imgPreloader from "../../images/img_preloader.svg"


interface UploadSignatureProps {
    isLoading: boolean
    saveImg: Function

}

const UploadSignature: React.FC<UploadSignatureProps> = ({ isLoading, saveImg }) => {
    const history = useHistory();

    useEffect(() => {
        saveImg()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="centerContent">
            {
                isLoading ? <img className="cyber-preloader" src={imgPreloader} width="150" height="150" alt="" />
                    : 
                    <div>
                        <h4>Se ha configurado la firma</h4>
                        <br />
                        <button className="cyber-button" onClick={()=>history.push(routeConfig.dashboard)}>Regresar</button>
                    </div>
            }
        </div>
    )
}

export default UploadSignature
