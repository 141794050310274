import axios from 'axios';
import React, { useState } from 'react'
import endpointConfig from '../../config/endpointConfig';
import ConfirmProcess from './components/ConfirmProcess';
import OtpStep from './components/OtpStep';
import WaitingStep from './components/WaitingStep';
import RequestSent from './RequestSent';
import SignatureModal from './SignatureModal';

interface SignatureModalProps {
    modalName: string
    modalType: string
    modalSerial: string
    modalExpDate: string
    modalHasBeenRenewed: boolean
    modalCertificateStatus: string
    handleClose: Function
}

const EditSignature: React.FC<SignatureModalProps> = ({
    modalName,
    modalType,
    modalSerial,
    modalExpDate,
    modalHasBeenRenewed,
    modalCertificateStatus,
    handleClose
}) => {

    // types
    type UserInfo = {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
    }

    // switch between components (componentState & step)
    const [componentState, setComponentState] = useState(0)
    const [otpInput, setOtpInput] = useState("")
    const [targetS, setTargetStatus] = useState(0)
    const [confirmStateNumber, setConfirmStateNumber] = useState(0)
    const [confirmStateName, setConfirmStateName] = useState("")
    let step;

    // message for OtpStep component
    const [otpMessage,] = useState("Inserta el codigo de verificación que puedes encontrar en el app de autenticación.")
    const [otpMessageRevoke,] = useState("Inserta el codigo de verificación que puedes encontrar en el app de autenticación. Una vez firmes la revocación, esta ya no se podra cancelar")

    const handleForm = async (targetStatus: number | null, operation: string) => {

        setComponentState(5)

        const getToken: UserInfo = JSON.parse(localStorage.getItem("user-info") || '{}');
        const authToken: string = getToken['id_token'];

        let userData: object = {
            "serialNumber": modalSerial,
            "OTP": otpInput,
        }

        if (targetStatus !== null) {
            userData = {
                "certificate_serial": modalSerial,
                "OTP": otpInput,
                "targetStatus": targetStatus
            }
        }

        let manageResponse: any = await axios.post(
            endpointConfig.onboard,
            {
                "userData": userData,
                "operation": operation
            },
            {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            }
        ).catch(err => {
            alert('Hubo un error al enviar la solicitud')
        })

        if (manageResponse.status === 200) {
            setComponentState(6)
        }

    }

    switch (componentState) {
        case 0:
            step = <SignatureModal
                modalName={modalName}
                modalType={modalType}
                modalSerial={modalSerial}
                modalExpDate={modalExpDate}
                modalHasBeenRenewed={modalHasBeenRenewed}
                modalCertificateStatus={modalCertificateStatus}
                handleClose={handleClose}
                setComponentState={setComponentState}
                setConfirmStateNumber={setConfirmStateNumber}
                setConfirmStateName={setConfirmStateName}
            />
            break // step 0

        case 1:
            step = <OtpStep
                handleClose={handleClose}
                setOtpInput={setOtpInput}
                targetStatus={null}
                operation={"Renew"}
                handleForm={handleForm}
                setTargetStatus={setTargetStatus}
                otpMessage={otpMessage}
                process={"renovación"}
            />
            break // step 1, Renew Signature

        case 2:
            step = <OtpStep
                handleClose={handleClose}
                setOtpInput={setOtpInput}
                targetStatus={6}
                operation={"Suspend"}
                handleForm={handleForm}
                setTargetStatus={setTargetStatus}
                otpMessage={otpMessage}
                process={"suspensión"}
            />
            break // step 2, Suspend Signature

        case 3:
            step = <OtpStep
                handleClose={handleClose}
                setOtpInput={setOtpInput}
                targetStatus={targetS}
                operation={"Revocation"}
                handleForm={handleForm}
                setTargetStatus={setTargetStatus}
                otpMessage={otpMessageRevoke}
                process={"revocación"}
            />
            break // step 3, Revoke Signature

        case 4:
            step = <OtpStep
                handleClose={handleClose}
                setOtpInput={setOtpInput}
                targetStatus={8}
                operation={"Unsuspend"}
                handleForm={handleForm}
                setTargetStatus={setTargetStatus}
                otpMessage={otpMessageRevoke}
                process={"anulación de suspensión"}
            />
            break // step 4, Unsuspend signature

        case 5:
            step = <WaitingStep />
            break // step 5, Esperar a que termine de ejecutar handleForm

        case 6:
            step = <RequestSent
                handleClose={handleClose}
            />
            break // step 5, Send modal if /onboard request's status is 200

        case 7:
            step = <ConfirmProcess
                confirmStateNumber={confirmStateNumber}
                confirmStateName={confirmStateName}
                setComponentState={setComponentState}
            />


    }

    return (
        <div>
            {step}
        </div>
    )
}

export default EditSignature
